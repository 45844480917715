import ReactApexChart from "react-apexcharts";
import { LineChartContainer, LineChartSacaleContainer } from "./styled";
import { FontGreyMediumRegular } from "../../../utils/typografyApp";
import { useLineChartHook } from "./hook";
import { Loading } from "../Loading";

export const LineChartComponent = ({ height, data, isLoading }) => {

    const hook = useLineChartHook({ height, data });

    return (
        <>
            {isLoading ?
                <Loading size={24}/> :

                <LineChartContainer>
                    <LineChartSacaleContainer>
                        <FontGreyMediumRegular>x 100</FontGreyMediumRegular>
                    </LineChartSacaleContainer>
                    <ReactApexChart
                        series={[{
                            color: "#ff0000",
                            data: hook.config.dataValues,
                        }]}
                        options={hook.config.options}
                        type='line'
                        height={height}
                    />

                </LineChartContainer>
            }
        </>

    )
}