import { useEffect } from "react";
import { Main } from "../../main-styled";
import { PageContainer } from "./styled";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
import { connect } from "react-redux";
import { useHomeHook } from "./hook";

import Dashboard from "../../components/Dashboard";
import Guide from "../../components/Guide";
import Map from "../../components/Map";

const HomePage = ({setMenuType, setShowDashboard})=>{
    const hook = useHomeHook();

    useEffect(()=>{
        setMenuType("MAP-PAGE");
        setShowDashboard();
    }, []);


    return(
        <Main>
            <PageContainer>
                <div className="map">
                    <Map assets={hook.assets.assetsData} />
                </div> 
                
                <Guide/>

                {hook.assets.assetsData?.count !== 0 &&
                    <Dashboard guideMap={true}/>
                }
            </PageContainer>
        </Main>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

{/* inicio planilha de carga */}

                {/* {assets?.count === 0 &&
                    <AddSheet>
                        <AddSheetContent htmlFor="input-sheet">
                            <span>Ainda não há dados no sistema</span>
                            Carregar planilha de carga
                            <input onChange={(e)=>homeController.onChangeFile(e, setSheetFile)} id="input-sheet" type="file" hidden  accept=".xls,.xlsx"/>
                        </AddSheetContent>
                    </AddSheet> 
                }

                {sheetFile && <AddSheetModal>
                    <AddSheetModalContent>
                        <h2>Upload da planilha</h2>
                        {loading ?
                            <>
                                <Loading/>
                                <span>Carregando....</span>
                            </> :
                            <>
                                <span>Arquivo selecionado: {sheetFile?.name}</span>
                                <div>
                                    <PrimaryButton onClick={()=>homeController.upload({
                                        setLoading, sheetFile, setSheetFile, setRefresh, refresh
                                    })}>
                                        Upload
                                    </PrimaryButton>
                                    <SecondaryButton onClick={()=>{
                                        setSheetFile(null);
                                        document.getElementById("input-sheet").value = null;
                                    }}>
                                        Cancelar
                                    </SecondaryButton>
                                </div>
                            </>
                        }
                        
                    </AddSheetModalContent>
                </AddSheetModal> } */}

                {/* fim planilha de carga */}