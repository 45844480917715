
import { FontGreyDarkMedium, FontGreyDarkSemiBold } from "../../../utils/typografyApp";
import { CardContainer, GraphicContainer, FooterContainer, CardContent } from "./styled";

const CardGraphic = ({title, height, graphic, footer, sizedSpace = 1, reports=false})=>{
    return(
        <CardContainer height={height}>
            <FontGreyDarkMedium className={reports && "title-reports"}>{title}</FontGreyDarkMedium>
            <CardContent>
                <GraphicContainer>
                    {graphic}
                </GraphicContainer>
                {footer && 
                    <FooterContainer className={reports && "footer-reports"}>
                        <FontGreyDarkMedium>{footer.description}</FontGreyDarkMedium>
                        <FontGreyDarkSemiBold>{parseFloat(footer.value).toFixed(2)}</FontGreyDarkSemiBold>
                    </FooterContainer>
                }
            </CardContent>
        </CardContainer>
    );
}

export default CardGraphic;