import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { queryClient } from "../../../../utils/query-client";
import { zodResolver } from '@hookform/resolvers/zod';
import { section3DItemSchema } from "../../../../models/schemas/section3DItemSchema";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";

export function useNewSection3DItemModalHook({onClose, selectedSection3DItem, asset3DItem}){
    const assets3DItemService = useAssets3DItemService();

    const { mutate, isLoading } = useMutation((payload) => assets3DItemService.editAssets3DItem({payload, onSuccess}));

    const section3DItemForm = useForm({ 
        resolver: zodResolver(section3DItemSchema), 
        defaultValues: {...selectedSection3DItem}
    });

    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
        onClose();
    }


    function onSubmit(payload) {
        const sections = [...asset3DItem.sections];
        sections.push(payload);
        mutate({...asset3DItem, sections});
    }

    return{
        form: {
            onSubmit,
            section3DItemForm,
            isLoading
        }
    }
}