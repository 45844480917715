import * as THREE from 'three';

export function formatPrice(value) {
    const options = {
        style: 'currency',
        currency: 'BRL' 
    };

    return value?.toLocaleString('pt-BR', options);
}

export function convertPrice(value) {
    if(value){
        value = value.toString().replace(/[^\d,]/g, '');
        value = value.replace(',', '.');
    }
    return parseFloat(value);
}


export function checkCache(asset3DItem){
    const cachedItem = localStorage.getItem(`asset-${asset3DItem?.id}`);
    return cachedItem === asset3DItem?.token;
}

export function blobToArrayBuffer(blob){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error('Failed to read blob'));
        reader.readAsArrayBuffer(blob);
    });
};

export function calculateBoundingBox(objects) {
    const box = new THREE.Box3();
    objects.forEach(({ object }) => {
        if (object) {
            box.expandByObject(object);
        }
    });
    return box;
}