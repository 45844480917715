import { FontGreyDarkRegular } from "../../../utils/typografyApp";
import Utils from "../../../utils/utils";
import { Loading } from "../Loading";
import { GraphicContainer, GraphicItem, GraphicBar, GraphicMaxBar } from "./styled";

const GraphicStatus = ({data, isLoading})=>{
    return(
        <GraphicContainer>
            { !isLoading ? 
                <>
                    <GraphicItem>
                        <GraphicMaxBar className="bar-config">
                            <GraphicBar className="bar-config green" width={data?.currentActiveArea} total={data?.totalArea}/>
                        </GraphicMaxBar>
                        <FontGreyDarkRegular>{parseFloat(Utils.formatNumberWithCommas(data?.currentActiveArea || 0)).toFixed(2)}</FontGreyDarkRegular>
                    </GraphicItem>

                    <GraphicItem>
                        <GraphicMaxBar className="bar-config">
                            <GraphicBar className="bar-config yellow" width={data?.currentUpComingArea} total={data?.totalArea}/>
                        </GraphicMaxBar>
                        <FontGreyDarkRegular>{parseFloat(Utils.formatNumberWithCommas(data?.currentUpComingArea || 0)).toFixed(2)}</FontGreyDarkRegular>
                    </GraphicItem>

                    <GraphicItem>
                        <GraphicMaxBar className="bar-config">
                            <GraphicBar className="bar-config red" width={data?.currentExpiredArea} total={data?.totalArea}/>
                        </GraphicMaxBar>
                        <FontGreyDarkRegular>{parseFloat(Utils.formatNumberWithCommas(data?.currentExpiredArea || 0)).toFixed(2)}</FontGreyDarkRegular>
                    </GraphicItem>
                </> :
                <Loading size={24}/>
            }  
        </GraphicContainer>
    );
}

export default GraphicStatus;