import styled from "styled-components";

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const RowContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const SwitchContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey-light-2-color);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
`;

