import { ModalMain } from "../styled";
import upload_input_icon from "../../../../assets/icons/upload-input.svg";
import { FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import NewAsset3DController from './controller';
import { useState } from "react";
import { NewSectionForm } from "./styled";
import Select from '../../Select'
import map_icon from '../../../../assets/icons/map.svg'

const NewSectionModal=({onClose, setOpacity})=>{
    const [profilePic, setProfilePic] = useState();
    const [section, setSection] = useState({});
    const [sistemTypeOptions, setSistemTypeOptions] = useState([
        {key: 1, value: 'Tipo 1'},
        {key: 2, value: 'Tipo 2'},
    ]);

    const [corrosivityOptions, setcorrosivityOptions] = useState([
        {key: 1, value: 'C1'},
        {key: 2, value: 'C2'},
    ]);

    const [stlOptions, setStlOptions] = useState([
        {key: 1, value: '#3D1 - Estrutura da Lança'},
        {key: 2, value: '#3D2 - Estrutura do Mastro'},
    ]);
    const controller = new NewAsset3DController();
    return(
        <ModalMain>
            <div className={"menu-content"}>
                <div className="content">
                    <div className="title">
                        <FontGreyDarkMediumTitle>Cadastro de Seção</FontGreyDarkMediumTitle>
                    </div>

                    <NewSectionForm>
                        <form>
                            <label className="profilePic" htmlFor="profilePic">
                                <img src={profilePic || upload_input_icon} alt="Icone de Upload"/>
                                <input 
                                    className="hidden-content" id="profilePic" type="file" 
                                    onChange={async (e)=> setProfilePic(await controller.onChangeFile(e))}
                                />
                            </label>

                            <div className="row">
                                <input type="text" name='name' placeholder="Nome da seção"/>
                                <input className="tag" type="text" name='tag' placeholder="Tag"/>
                            </div>


                            <div className="row">
                                <input type="text" name='name' placeholder="Local de Instalação"/>
                                <input className="tag" type="text" name='tag' placeholder="Área Total"/>
                            </div>

                            <div className="row">
                                <Select
                                    type="text" 
                                    placeholder="Tipo de sistema" 
                                    name="unit" 
                                    options={sistemTypeOptions}
                                    selectedOption={section.unit}
                                    setSelectedOption={(selectedOption)=>setSection({...section, unit : selectedOption})}
                                    error={''}
                                />
                                <Select
                                    type="text" 
                                    placeholder="Cat. Corrosividade" 
                                    name="group" 
                                    options={corrosivityOptions}
                                    selectedOption={section.group}
                                    setSelectedOption={(selectedOption)=>setSection({...section, group : selectedOption})}
                                    error={''}
                                />
                            </div>

                            <div className="row">
                                <Select
                                    type="text" 
                                    placeholder="3D da Seção" 
                                    name="group" 
                                    options={stlOptions}
                                    selectedOption={section.group}
                                    setSelectedOption={(selectedOption)=>setSection({...section, group : selectedOption})}
                                    error={''}
                                />
                            </div>

                        </form>
                    </NewSectionForm>
                </div>
                
                <div className="actions-container">
                    <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
                    <PrimaryButton onClick={onClose}>Criar</PrimaryButton>
                </div>
            </div>
        </ModalMain>
    );
}

export default NewSectionModal;