import { styled } from 'styled-components';

export const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 24px;
    padding-top: 1rem;
    min-width: 21rem;
    overflow: scroll;
    z-index: ${props=>props.opacity ? 0 : 3005};
`;

export const Arrow = styled.img`
    height: 1rem;
    position: absolute;
    top: 50%;
    left: -1.1rem;
`;

export const DasboardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    .scroll{
        max-height: 95vh !important;
        overflow: scroll;
        padding-right: 8px;

    }
`;

export const EmptyContent = styled.div`
    height: 70vh;
    width: 1rem;
`;

export const Card = styled.div`
    background-color: var(--white-color) !important;
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    padding: 8px 0;
    
    ${props=>props.showdashboardcard === "true" &&
        `min-width: 16rem;`
    }
   
`;

export const GyroscopeContainer = styled.div`
    gap: 1rem;
    padding: 2rem 0;
    width: 100%;
    
    img{
        padding: 0.5rem;
        background-color: var(--white-color);
        border-radius: 2rem; 
        cursor: pointer;
    }
    
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 13.875rem;
    margin: 0.5rem 1rem ;

    .input-search{
        padding: 0.25rem 0.5rem !important;
        width: calc(100% - 1rem);
        margin: 0;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

