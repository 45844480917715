import { FontButtonGreyLightMedium } from '../../../utils/typografyApp';
import { Button } from './styled';

const AdmButton = ({children, onClick, disabled})=>{
    return(
        <Button onClick={onClick} disabled={disabled}>
            <FontButtonGreyLightMedium>{children}</FontButtonGreyLightMedium>
        </Button>
    );
}

export default AdmButton;