
import { FontButtonGreyLightMedium } from '../../../utils/typografyApp';
import { Button } from './styled';

const SecondaryButton = ({children, onClick, className})=>{
    return(
        <Button type='button' onClick={onClick} className={className}><FontButtonGreyLightMedium>{children}</FontButtonGreyLightMedium></Button>
    );
}

export default SecondaryButton;