import { styled } from 'styled-components';

export const GraphicContainer = styled.div`
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--grey-light-2-color);
    border-radius: .5rem;
    padding: 1rem;
    margin-top: -1rem;
`;

export const GraphicItem = styled.div`
    display: flex;
    height: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem;

    .bar-config{
        height: 0.5rem;
        border-radius: 0.125rem;
    }

    .left-label{
        width: 20%;
    }
`

export const GraphicMaxBar = styled.div`
    width: 80%;
    height: 32px !important;
    position: relative;
    flex-shrink: 0;
    background: var(--grey-light-2-color);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0.5rem !important;
    span{
        z-index: 3000;
        margin-right: 0.5rem;
    }
    .red{
        background-color: var(--red-color);
    }
`;

export const GraphicBar = styled.div`
    height: 24px !important;
    top: 0.25rem;
    left: 0.25rem;
    border-radius: 0.5rem !important;
    width: ${(props) => {
        const calculatedWidthPx = 100 * props.width / props.total;
        return `${calculatedWidthPx}%`;
    }};
    position: absolute;
`;