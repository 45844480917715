import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const RadarChart = ({ datasets, labels, config}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    let myRadarChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: labels,
        datasets: datasets.map((dataset, index) => ({
          label: config[index].label,
          data: dataset.data,
          backgroundColor: config[index].color,
          borderColor: config[index].borderColor,
          borderWidth: 2,
          pointBackgroundColor: config[index].borderColor,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: config[index].borderColor,
        })),
      },
      options: {
        plugins: {
          legend: {
            position : 'bottom',
          }
        },
        scale: {
          ticks: {
            beginAtZero: true,
            stepSize: 5000
          },
        },
      },
    });

    return () => {
      if (myRadarChart) {
        myRadarChart.destroy();
      }
    };
  }, [datasets, labels]);

  return <canvas ref={chartRef} />;
};

export default RadarChart;