import styled from "styled-components";

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;

    
`;

export const AssetInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    
    .img-asset, .local_3d{
        width: 100%;
        object-fit: fill;
        align-self: center;
        height: 8rem !important;
    }
`;