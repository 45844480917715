import { styled } from 'styled-components';

export const Button = styled.button`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: ${props=>props.disabled ? 'var(--grey-medium-color)' : 'var(--white-color)' }  !important;
    padding: 8px 16px !important;
    border: none;
    cursor: pointer;

    span{
        display: flex;
        gap: 8px;
        align-items: center;
        color: #878787;
    }
   
`;