import { GuideContainer } from "./styled";
import logo_primary_image from "../../../assets/logo_primary.png";
import person_icon from "../../../assets/icons/person.svg";
import pin_icon from "../../../assets/icons/pin.svg";
import { FontGreyDarkMedium, FontPrimaryMediumSubTitle, FontPrimaryMediumTitle } from "../../../utils/typografyApp";

const Guide = ({showInfo = true, page})=>{
    return (
        <GuideContainer showInfo={showInfo ? "true" : "false"} isAdm={!!page}>
            <img src={logo_primary_image} alt="Logo do TAU na cor azul"/>
            <button><img src={person_icon} alt="Icone de perfil"/></button>
            {
                showInfo &&
                <div className="location">
                    <img src={pin_icon} alt="Icone de localização"/>
                    <FontGreyDarkMedium>
                        {page ? 
                            <FontGreyDarkMedium>{page} | <FontPrimaryMediumSubTitle className="adm">ADM TAU</FontPrimaryMediumSubTitle></FontGreyDarkMedium> :
                            <FontGreyDarkMedium>Porto de VV | VLI</FontGreyDarkMedium>
                        }
                        
                    </FontGreyDarkMedium> 
                </div>
            }
        </GuideContainer>
    );
}

export default Guide;