import { z } from "zod";
import { generalMessageError } from "./config";

export const section3DItemSchema = z.object({
    id: z.union([
        z.string(),
        z.number()
    ]).optional(),
    name: z.string().min(1, { message: generalMessageError }),
    tag: z.string().min(1, { message: generalMessageError })
});