import styled from "styled-components";

export const LineChartContainer = styled.div`
    position: relative;
    border: 1px solid var(--grey-light-2-color);
    height: 140px;
    margin-bottom: 45px;
    border-radius: 8px;
`;

export const LineChartSacaleContainer = styled.div`
    position: absolute;
    top: 4px;
    left: 8px;
`;