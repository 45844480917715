import { Main } from "../../main-styled";
import { PageContainer, Content, Actions, Inputs } from "./styled";
import { FontGreyDarkFullTitle } from "../../../utils/typografyApp";
import { useLoginHook } from "./hook";
import Input from "../../components/Input";
import PrimaryButton from "../../components/PrimaryButton";
import { FormProvider } from "react-hook-form";


const LoginPage = ({isAdmin}) => {
    const hook = useLoginHook({isAdmin});
    return (
        <Main>
            <PageContainer>
                <Content>
                    <FormProvider {...hook.form.loginForm}>
                        <form onSubmit={hook.form.loginForm.handleSubmit(hook.form.onSubmit)}>
                            <FontGreyDarkFullTitle>Entrar</FontGreyDarkFullTitle>
                            <Inputs>
                                <Input type="text" name="email" placeholder="Login" />
                                <Input type="password" placeholder="Senha" name="password" />
                            </Inputs>
                            <PrimaryButton type="submit" disabled={hook.form.isLoading}>
                                {hook.form.isLoading ? 'Entando...' : 'Entrar'}
                            </PrimaryButton>
                        </form>
                    </FormProvider>
                </Content>
            </PageContainer>
        </Main>
    );
};

export default LoginPage;