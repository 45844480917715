import { styled } from 'styled-components';

export const GraphicContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem !important;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 0.125rem solid var(--grey-light-2-color);
    height: 302px;

    .blue{
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
    }

    .dashed{
        background-color: transparent;
        border: 2px dashed var(--grey-medium-color);
    }

    .red{
        background-color: var(--ri2-color);
        border: 1px solid var(--ri2-color);
       
    }

`;

export const GraphicLegend = styled.div`
    width: 100%;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

 
`;

export const LegendItem = styled.div`
    display: flex;
    gap: 0.25rem;

    div{
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.19rem;
    }
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const GraphicItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
   
`

export const GraphicMaxBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    width: 70%;
`;

export const GraphicMaxBar = styled.div`
    position: relative;
    width: 100%;
    background-color: var(--grey-light-2-color);
    border-radius: 0.25rem;
    height: 11rem !important;

    
`;
 
export const GraphicBar = styled.div`
    position: absolute;
    width: 90%;
    left: 0;
    bottom: 0;
    background-color: red;
    border-radius: 0.25rem;
    height: ${(props) => {
        const maxPx = 174;
        const calculatedHeightPx = Math.round(maxPx * props.height / props.total);
        return `${calculatedHeightPx}px`;
    }};
`;