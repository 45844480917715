import { useMutation } from "react-query";
import { queryClient } from "../../../../utils/query-client";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";

export function useDeleteSection3DItemModalHook({onClose, selectedAsset3DItem, selectedSection3DItem}){
    const asset3DItemService = useAssets3DItemService();

    const { mutate, isLoading } = useMutation((payload) => asset3DItemService.editAssets3DItem({payload, onSuccess}));


    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
        onClose();
    }


    function onSubmit() {
        const payload = {...selectedAsset3DItem};
        const sections = payload.sections.filter(section=>section.id !== selectedSection3DItem.id);
        mutate({...payload, sections});
    }

    return{
        form: {
            onSubmit,
            isLoading
        }
    }
}