import { useNavigate, useParams } from "react-router-dom";
import { assetsQueries, useAssetsService } from "../../../services/assetService";
import { useQuery } from "react-query";
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../service/api";
import { getSectionColor, RIsColor } from "../../../utils/colorsApp";
import Utils from "../../../utils/utils";
import { getFromIndexedDB } from "../../../service/indexedDB";
import { blobToArrayBuffer, calculateBoundingBox } from "../../../utils/functions";


export function useAssetHook({ menu, setMenuType, setShowDashboard }) {
    const { id } = useParams();
    const assetService = useAssetsService();
    const [objects, setObjects] = useState([]);
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const [progress, setProgress] = useState(0);
    const orbitControls = useRef();
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const camera = useRef();
    const navigate = useNavigate();

    const [sectionWindow, setSectionWindow] = useState({
        isOpen: false,
        content: null,
        position: null,
    });

    const {
        data: assetData,
        isLoading: isLoadingAsset,
        isRefetching: isRefetchingAsset,
        refetch: refetchAsset
    } = useQuery(assetsQueries.GET_BY_ID, () => assetService.getAssetsById({ id }));

    function handlePointerDown(event) {
        if (event.intersections.length > 0) {
            const intersection = event.intersections[0];
            const { point } = intersection;
            const material = new THREE.MeshStandardMaterial({ color: 'grey' });
            const radius = 0.3;
            const sphereGeometry = new THREE.SphereGeometry(radius);
            const sphereMesh = new THREE.Mesh(sphereGeometry, material);
            sphereMesh.position.copy(point);

            const edgesGeometry = new THREE.EdgesGeometry(sphereGeometry);
            setObjects((prevObjects) => [...prevObjects, sphereMesh, edgesGeometry]);
        }
    }

    function handleShowInfoWindow(e, section) {
        if (section) {
            setSectionWindow({ ...sectionWindow, isOpen: true, position: { x: e.pageX, y: e.pageY }, content: section })
        }
    }

    function handlePointerOver(index) {
        if (objects[index].section) {
            setHighlightedObject(index);
            const object = objects[index].object;
            object.originalColor = object.material.color.getHex();  // Save original color
            object.material.color.set('#78cfe3');  // Change color to blue
        }
    }

    function handlePointerOut(index) {
        setHighlightedObject(null);
        const object = objects[index].object;
        object.material.color.set(object.originalColor);  // Restore original color
    }


    function handleBackButton() {
        Utils.clear3DObjects(objects, setObjects);
        navigate('/');
    }

    useEffect(() => {
        setMenuType("ASSET-PAGE");
        setShowDashboard();
    }, []);

    useEffect(() => {
        async function loadSections() {
            if (assetData?.sections && assetData.sections.length > 0) {
                Utils.clear3DObjects(objects, setObjects);

                const tempObjects = [];
                const loader = new STLLoader();

                let totalSections = assetData.sections.length;
                let loadedSections = 0;

                const downloadPromises = assetData.sections.map(async (section) => {
                    if (section.reference3d?.section3d?.location) {
                        const cacheKey = `${section.reference3d?.section3d?.token}`;
                        try {
                            // Tentar recuperar do IndexedDB
                            const cachedDataBlob = await getFromIndexedDB(cacheKey);
                            if (cachedDataBlob) {
                                // Converter Blob para ArrayBuffer
                                const arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                                const geometry = loader.parse(arrayBuffer);
                                const material = new THREE.MeshStandardMaterial({ color: menu.showDegradations ? "#cccccc" : getSectionColor(section.areaStatus) });
                                const stlMesh = new THREE.Mesh(geometry, material);

                                stlMesh.castShadow = true;
                                stlMesh.receiveShadow = true;

                                loadedSections++;
                                setProgress((loadedSections / totalSections) * 100);

                                tempObjects.push({ section, object: stlMesh });

                                const box = new THREE.Box3().setFromObject(stlMesh);
                                const center = new THREE.Vector3();
                                box.getCenter(center);

                                console.log('center',center)

                                const size = new THREE.Vector3();
                                box.getSize(size);
                                const maxDim = Math.max(size.x, size.y, size.z);

                                const scaleFactor = (1 / maxDim);

                                // Adicionar esferas para anomalias
                                if (menu.showDegradations && section.anomalies) {
                                    section.anomalies.forEach(anomaly => {
                                        let { position } = anomaly;
                                        if (position.includes('\"x\":')) {
                                            position = JSON.parse(position);
                                            const sphereRadius = 800; // Raio da esfera
                                            const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
                                            const sphereMaterial = new THREE.MeshBasicMaterial({ color: RIsColor[anomaly.degradationDegree.name], transparent: true, opacity: 0.5 });
                                            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

                                            sphere.position.set(center.x + (position.x * 9000), center.y + (position.y * 9000), center.z + (position.z * 9000));
                                            
                                            tempObjects.push({ anomaly, object: sphere });
                                        }
                                    });
                                }

                                return { section, object: stlMesh };
                            } else {
                                return { section, object: undefined };
                            }
                        } catch (error) {
                            console.error(`Failed to load STL file for section ${section.token}:`, error);
                            return { section, object: undefined };
                        }
                    } else {
                        return { section, object: undefined };
                    }
                });

                try {
                    const results = await Promise.all(downloadPromises);
                    tempObjects.push(...results);
                    setObjects([...tempObjects]);
                } catch (error) {
                    console.error('Error loading sections:', error);
                }

                setRefresh(false);
            }
        }

        loadSections();

    }, [assetData, menu.showDegradations]);

    useEffect(() => {
        if (!refresh && objects.length > 0 && !!orbitControls.current && camera.current) {
            // Calcular a caixa delimitadora do conjunto de objetos
            const boundingBox = calculateBoundingBox(objects);
            const center = new THREE.Vector3();
            boundingBox.getCenter(center);

            const size = new THREE.Vector3();
            boundingBox.getSize(size);
            const maxDim = Math.max(size.x, size.y, size.z);
            const scaleFactor = 1 / maxDim;

            // Escalar todos os objetos
            objects.forEach(({ object }) => {
                if (object) {
                    object.scale.set(scaleFactor, scaleFactor, scaleFactor);
                    object.position.sub(center).multiplyScalar(scaleFactor);
                }
            });

            // Atualizar a posição da câmera e os controles
            orbitControls.current.target.set(0, 0, 0);
            camera.current.position.set(-0.5, 0.1, 0.8);
            orbitControls.current.update();
        }
    }, [refresh, objects]);

    return {
        general: {
            handleBackButton,
            id,
            handleShowInfoWindow,
            setSectionWindow,
            sectionWindow,
            setDownloadProgress,
            downloadProgress,
            setIsDownloading,
            isDownloading

        },
        asset: {
            assetData,
            isLoading: isLoadingAsset || isRefetchingAsset || refresh,
            refetchAsset
        },
        config3d: {
            isLoading: refresh,
            orbitControls,
            objects,
            camera,
            highlightedObject,
            handlePointerDown,
            handlePointerOver,
            handlePointerOut,
            progress
        }
    };
}