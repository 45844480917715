import { styled } from 'styled-components';

export const GraphicContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    min-height: 57px;
`;

export const GraphicItem = styled.div`
    display: flex;
    height: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;

    .bar-config{
        height: 0.5rem;
        border-radius: 2rem;
    }
`

export const GraphicMaxBar = styled.div`
    width: 45%;
    position: relative;
    flex-shrink: 0;
    background: var(--grey-light-2-color);

    .green{
        background-color: var(--green-color);
    }

    .yellow{
        background-color: var(--yellow-color);
    }

    .red{
        background-color: var(--red-color);
    }
`;

export const GraphicBar = styled.div`
    width: ${(props) => {
        const maxPx = 100;
        const calculatedWidthPx = maxPx * props.width / props.total;
        return `${calculatedWidthPx}%`;
    }};
    position: absolute;
`;