import { styled } from 'styled-components';

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color) !important;
    padding: 0.5rem 0.675rem !important;
    border-radius: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;

    img{
        width: 1rem !important;
        height: 1rem !important;
    }

`;