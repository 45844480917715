import styled from "styled-components";

export const SwitchButtonContainer = styled.div`
    position: relative;
    width: 3.25rem;
    height: 2rem;
    background-color: ${props=>props.active ? "var(--primary-color)" : "var(--grey-medium-color)"} !important;
    border-radius: 2rem !important;
    cursor: pointer;

    &::after{
        content: "";
        position: absolute;
        right: ${props=>props.active ? "0.25rem" : "auto"};
        left: ${props=>props.active ? "auto" : "0.25rem"};
        top: 0.25rem;
        width: 1.5rem;
        height: 1.5rem;
        background-color: var(--white-color);
        border-radius: 1rem;
    }
`