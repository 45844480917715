import styled from "styled-components";

export const HomePageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    background: #FFF;
    .rotate{
        transform: rotate(180deg);
    }
`;

export const HomePageHeader = styled.div`
    display: flex;
    width: 100%;
    margin-top: 6px;
    align-items: center;
    justify-content: center;
`;

export const TabSelect = styled.div`
    display: inline-flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-light-2-color);
    border-radius: 8px;
    gap: 2px;
`;

export const TabItem = styled.div`
    display: flex;
    width: 154px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${props=>props.active === "true" ? "var(--primary-color)" : "var(--grey-light-2-color)" };
    color: ${props=>props.active === "true" ? "var(--white-color)" : "var(--grey-medium-color)" } !important;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover{
        background-color: var(--primary-color);
        color: var(--white-color) !important;
    }
`;


export const ExitContainer = styled.div`
    position: absolute;
    top: 22px;
    right: 32px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;