import { styled } from 'styled-components';

export const GuideContainer = styled.div`
    display: flex;
    align-items: center;
    gap:1rem;
    position: absolute;
    top: 1rem;
    left: 2rem;

    background-color: ${props=>props.showInfo === "true" && !props.isAdm ? "var(--grey-light-color)" : "var(--white-color)"} !important;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;

    button{
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;

    }

    .location{
        display: flex;
        gap: 0.5rem;
    }

`;