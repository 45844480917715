import { dotSpinner } from 'ldrs';
import { LoadingContainer } from './styled';

export const Loading = ({size})=>{

    dotSpinner.register()

    return(
        <LoadingContainer>
            <l-dot-spinner
                size={size || 40}
                speed="0.9" 
                color="#0C8CE9" 
            >

            </l-dot-spinner>
        </LoadingContainer>
    );
}