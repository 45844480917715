import { Button } from './styled';
import back_icon from "../../../assets/icons/back.svg";

const BackButton = ({onClick})=>{
    return(
        <Button onClick={onClick}>
            <img src={back_icon} alt="Icone de seta para voltar"/> 
        </Button>
    );
}

export default BackButton;