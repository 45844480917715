import styled from "styled-components";

export const Tab3DContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 94px;

    .table{
        max-height: calc(100vh - 200px) !important;
        overflow-y: scroll !important;
        width: calc(100% + 20px);
    }

    .stl{
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        color: var(--primary-color);
    }


    .arrow{
        width: 16px;
        cursor: pointer;
    }

    
    .tag{
        display: flex;
        gap:8px;

    }
    table{
        width: 1080px;
        border-collapse: collapse;

        th{
            border-bottom: 1px solid #A8A8A8;
            height: 40px;
            text-align: start;
        }
        tbody{
            font-weight: 500;
        }

        .check{
            text-align: start;
        }

        .progress{
            height: 24px;
            padding: 0% 4px;
            background-color: #878787;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            border-radius: 8px;
        }

        td{
            height: 32px;
            margin-bottom: 16px;
        }

        .actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;

        img{
            width: 24px;
            height: 24px;
        }
    }
        

    }
`;

export const Tab3DFilters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    gap: 24px;

    
    .section-actions{
        display: flex;
        gap: 24px;
    }

    .search{
        width: 190px;
        height: 24.222px;
        background-color: #E6E4E0;
        padding: 4px 8px;
    }
`;

export const Tab3DSections = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 8px 0;


    .sections{
        display: flex;
        width: 100%;
        gap: 16px;
        flex-wrap: wrap;
    }


`;

export const Tab3DSectionItem = styled.div`
    display: flex;
    gap: 4px;
    
    width: 100%;

    .name{
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #F2F2F2;
        
    width: 100%;

        color: var(--Base-Grey-dark, #292929);
    }

    

    
    img{
        cursor: pointer;
    }

    div{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;