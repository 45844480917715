import { ModalMain } from "../styled";
import { FontGreyDarkFullBold, FontGreyDarkFullRegular, FontGreyDarkFullTitle, FontGreyDarkMediumTitle, FontGreyDarkSemiMedium, FontGreyMediumRegular } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { DeleteAsset3DForm } from "./styled";
import { useDeleteAsset3DItemModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const DeleteAsset3DModal = ({ onClose, selectedAsset3DItem }) => {

    const hook = useDeleteAsset3DItemModalHook({ onClose, selectedAsset3DItem });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <DeleteAsset3DForm>
                    
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Deletar 3D do Ativo</FontGreyDarkMediumTitle>
                                </div>
                                <FontGreyDarkFullRegular>Tem certeza que deseja excluir o modelo 3D do Ativo <FontGreyDarkFullBold> {selectedAsset3DItem?.name} - {selectedAsset3DItem?.tag}</FontGreyDarkFullBold>?</FontGreyDarkFullRegular>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.onSubmit}> {hook.form.isLoading ? 'Excluindo...' : 'Excluir'} </PrimaryButton>
                            </div>
                        </div>
            
                </DeleteAsset3DForm>
            </FormProvider>
        </ModalMain>
    );
}

export default DeleteAsset3DModal;