
import BackButton from "../../components/BackButton";
import Dashboard from "../../components/Dashboard";
import { Main } from "../../main-styled";
import { ButtonContainer, PageContainer, SVGContainer, SectionContainer } from "./styled";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
import PrimaryButton from "../../components/PrimaryButton";
import plus_icon from "../../../assets/icons/plus.svg";
import NewRegisterModal from "../../components/Modals/NewRegisterModal";
import { useSectionHook } from "./hook";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Canvas } from "react-three-fiber";
import { Loading } from "../../components/Loading";
import CustomInfoWindow from "../../components/CustomInfoWindow";
import AnomalieInfoWindow from '../../components/AnomalieInfoWindow';
import { FontGreyDarkRegular } from "../../../utils/typografyApp";

const SectionPage = ({ setMenuType, setShowDashboard }) => {
    const hook = useSectionHook();

    useEffect(() => {
        setMenuType("SECTION-PAGE");
        setShowDashboard();
    }, []);

    return (
        <Main>
            <PageContainer>
                {hook.general.showRegisterModal && <NewRegisterModal
                    setOpacity={hook.general.setOpacity}
                    onClose={() => hook.general.setShowRegisterModal(false)}
                    section={hook.section.sectionData}
                />}

                <SectionContainer>
                    <SVGContainer>

                        <div className="_3d">
                            {hook.section.isLoading && (
                                <div className="progress-bar">
                                    <Loading />
                                    <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                                </div>
                            )}
                            <Canvas>
                                <ambientLight intensity={0.5} />
                                <directionalLight
                                    position={[20, 10, 10]}
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-far={50}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                                <OrbitControls ref={hook.config3d.orbitControls} />
                                {hook.config3d.objects?.map((object, index) => (
                                    object.object ?
                                        <primitive
                                            key={index}
                                            object={object.object}
                                            onClick={(e) => {
                                                if (object.anomaly) {
                                                    hook.general.setAnomalieWindow({
                                                        isOpen: true,
                                                        position: { x: e.pageX, y: e.pageY },
                                                        content: object.anomaly
                                                    });
                                                }
                                            }}

                                        /> :
                                        <></>
                                ))}
                            </Canvas>
                        </div>


                        {hook.general.anomalieWindow.isOpen &&
                            <CustomInfoWindow
                                position={hook.general.anomalieWindow.position}
                                onClose={() => hook.general.setAnomalieWindow({ ...hook.general.anomalieWindow, isOpen: false })}
                                height={420}
                                width={397}
                            >
                                <AnomalieInfoWindow anomaly={hook.general.anomalieWindow.content} />
                            </CustomInfoWindow>
                        }
                    </SVGContainer>
                </SectionContainer>


                <BackButton onClick={() => hook.general.navigate(`/asset/${hook.section.sectionData.fkAssetId}`)} />
                <Dashboard section={hook.section.sectionData} opacity={hook.general.opacity} />
                <ButtonContainer>
                    <PrimaryButton className="newLoud" onClick={() => hook.general.setShowRegisterModal(true)}><img src={plus_icon} alt="Icone de adicionar" />  Novo Laudo</PrimaryButton>
                </ButtonContainer>
            </PageContainer>
        </Main >
    );
}



export default connect(mapStateToProps, mapDispatchToProps)(SectionPage);