import styled from "styled-components";

export const GraphicContainer = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border: 1px solid var(--grey-light-2-color);
    padding: 0.5rem 1rem;
    background: var(--white-color);
    border-radius: 0.5rem;
    align-items: center;
    @media screen and (max-width: 1600px){
        height: 5rem;
    }
   
`;

export const GraphicContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    width: 50%;
    
`;

export const Graphic = styled.div`
    img{
        object-fit: cover;
        width: 100%;
        height: 2rem;
    }
`;

export const Text = styled.div`
    display: flex;
    align-items: ${props=>props.direction === "row" ? "center" : "flex-start"};
    flex-direction: ${props=>props.direction};
    gap: 0.5rem;
    span:first-child{
       color: ${props=>props.color} !important;
    } 


`;