import { styled } from 'styled-components';


export const AddSheetContent = styled.label`
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: var(--white-color);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  span{
    font-size: 12px;
  }
`;

export const AddSheetModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AddSheetModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  border-radius: 8px;
  align-items: center;
  padding: 16px 24px;
  min-width: 300px;

  div{
    display: flex;
    gap: 24px;

    button{
      height: 40px !important;
      margin-top: 20px;
      width: 120px;
    }
  }

`;

export const AddSheet = styled.div`
  position: absolute;
  left: 30px;
  bottom: 30px;
`;

export const PageContainer = styled.div`
  ._3d{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2999;
    height: 100vh;
    width: 100vw;
    overflow: scroll;
  }

  .map{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
`;