import styled from "styled-components";

export const GraphicContainer = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--grey-light-2-color);
    padding: 0.5rem 1rem;
    background: var(--white-color);
    border-radius: 0.5rem;
    @media screen and (max-width: 1600px){
        height: 5rem;
    }
   
`;

export const GraphicContent = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    
`;

export const Graphic = styled.div`
    img{
        width: 100%;
    }
`;

export const Text = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    span{
       color: ${props=>props.color} !important;
    } 


`;