import { useMutation } from "react-query";
import { useState } from "react";
import { queryClient } from "../../../../utils/query-client";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";
import { useUtilsService } from "../../../../services/utilService";

export function useBulkSection3DModalHook({ onClose }) {
    const utilsService = useUtilsService();
    const [uploadProgress, setUploadProgress] = useState(0);

    const { mutate, isLoading } = useMutation((file) =>
        utilsService.uploadZip({ file, onUploadProgress: handleUploadProgress, onSuccess })
    );

    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
        onClose();
    }

    function handleUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
    }

    function handleUpload(e) {
        if (e.target.files[0]) {
            mutate(e.target.files[0]);
        }
    }

    return {
        form: {
            handleUpload,
            isLoading,
            uploadProgress
        }
    }
}
