import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const executionsQueries = {
    EDIT: "EXECUTIONS_EDIT",
    CREATE: "EXECUTIONS_CREATE",
    DELETE: "EXECUTIONS_DELETE",
    GET_ALL: "EXECUTIONS_GET_ALL",
    GET_BY_ID: "EXECUTIONS_GET_BY_ID",
}


export function useExecutionsService() {

    const handleRequest = useHandleRequest();

    async function createExecutions({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/sections-executions/`, 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editExecutions({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/sections-executions/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteExecutions({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/sections-executions/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllExecutions({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/sections-executions/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getExecutionsById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/sections-executions/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        createExecutions,
        editExecutions,
        deleteExecutions,
        getAllExecutions,
        getExecutionsById,
    }
}
