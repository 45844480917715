export class LocalAdmToken {
    
    static storeToken(access) {
        localStorage.setItem('tauAdmAccess', access);
    }

    static clearStoredToken() {
        localStorage.removeItem('tauAdmAccess');
    }

    static getStoredToken() {
        return localStorage.getItem('tauAdmAccess') ?? '';
    }

}