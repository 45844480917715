import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const unitsQueries = {
    EDIT: "UNIT_EDIT",
    CREATE: "UNIT_CREATE",
    DELETE: "UNIT_DELETE",
    GET_ALL: "UNIT_GET_ALL",
    GET_ALL_OPTIONS: "UNIT_GET_ALL_OPTIONS",
    GET_BY_ID: "UNIT_GET_BY_ID",
}


export function useUnitsService() {

    const handleRequest = useHandleRequest();

    async function creatUnits({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/units/${payload.id}`, 'POST');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editUnits({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/units/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deletUnits({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/units/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllUnits({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/units/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAllUnitsOptions({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/units/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data.rows.map(data=>({key: data.id, value: data.name}));
        }
    }

    async function getUnitsById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/units/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        creatUnits,
        editUnits,
        deletUnits,
        getAllUnits,
        getUnitsById,
        getAllUnitsOptions
    }
}
