import styled from "styled-components";

export const ModalMain = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--grey-dark-2-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 3001;

    /* Adicione ao arquivo de estilo existente */

.progress-bar {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.progress-text {
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
}


    .local_3d{
        width: 100%;
        height: 480px;
        overflow-y: hidden;
    }

    ._3d{
        position: fixed;
        background-color: #cecece !important;
        top: 0;
        left: 0;
        z-index: 2999;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    

    .menu-content{
        display: flex;
        flex-direction: column;
        gap: 16px;
        /* width: 397px; */

    }

    .actions-container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin-top: 0.25rem;
    };

    .content-3d{
        min-width: 900px !important;
        min-height: 480px;
    }

    .content{
        display: flex;
        flex-direction: column !important;
        background-color: var(--white-color);
        padding: 1rem;
        border-radius: 0.5rem;
        gap: 1rem;
        max-width: 29rem;
        min-width: 22rem;
        
        .images{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
            margin-left: 0.5rem;
            max-height: 60vh;
            overflow-y: scroll;
            img{
                background-color: var(--grey-light-2-color);
                border: 1px solid var(--grey-light-2-color);
                border-radius: 8px;
                width: 96px;
                height: 96px;
                object-fit: cover;
        }
        }
    }

    .underline{
        text-decoration-line: underline;
        text-align: center;

        span{ 
            width: 100%;
        }
    }

    .select-area{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: -1;
    }

    .add_image_container{
        cursor: pointer;
    }

    #addImage{
        display: none;
    }

    .float-top{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 0;
        width: 100%;
        top: 3rem;
        z-index: 3002;
    }

    .hidden-content{
        display: none;
        transition: 0.5s;
    }

    .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }


`;