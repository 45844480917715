import { useState } from "react";
import { useFormContext } from "react-hook-form";

export function useSelectHook({name}){
    const {watch, register, setValue, formState} = useFormContext();
    const watchedValue = watch(name);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const error = formState.errors[name]?.message;

    function toggleOption(option){
        setSelectedOption(option);
        setShowOptions(false);
        setValue(name, option.key);
    }

    return {
        general: {
            showOptions,
            toggleOption,
            setShowOptions
        },
        form: {
            register,
            watchedValue,
            selectedOption,
            error
        }
        
    }
}