import { styled } from 'styled-components';

export const CardContainer = styled.div`
 
    .card-item{
        display: flex;
        padding: 0.5rem 0;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
    }
`;

export const TitleContainer = styled.div`
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid var(--grey-light-2-color);
    text-align: center;
`;

export const ValueContainer = styled.div`
    border-radius: 0 0 0.5rem 0.5rem;
    background: var(--grey-light-2-color);
`;
