export function useLineChartHook({height, data}){
    const dataValues = data?.map(item => parseFloat(item.totalArea[0] / 100).toFixed(2)) || [];
    const maxValue = Math.max(...dataValues);

    const options = {
        chart: {
            type: 'line',
            height: height,
            zoom: {
                enabled: false,
            },
            events: {},
            toolbar: {
                show: false,
            },
        },
        tooltip: {
           enabled: false
        },
        stroke: {
            curve: 'straight',
            lineCap: 'round',
            width: 1,
        },
        xaxis: {
            categories: data?.map(item=>item.labelYear) || [],
            offsetY: -1,
            axisBorder: {
                show: false,
            },
            tooltip: {
                enabled: false,
            }
        },
        yaxis: {
            max: maxValue*1.1,
            min: maxValue*-0.2,
            labels: {
                show: false,
            }
        },
        grid: {
            show: false
        },
        markers: {
            size: 5,
            colors: ['red'],
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                colors: ['#ff0000'],
                fontSize: '12px',
                fontWeight: 'bold',
            },
            background: {
                enabled: true,
                foreColor: '#ffffff',
                borderRadius: 4,
                padding: 8,
                borderWidth: 0,

                dropShadow: {
                    enabled: false,
                }

                
            },
            offsetY: -7, 
        },

    };

    return {
        config: {
            options,
            dataValues
        }
    }
}