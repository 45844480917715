import { useEffect, useState } from "react";
import { FontGreyDarkRegular, FontSmallGreyDarkBold } from "../../../utils/typografyApp";
import Utils from "../../../utils/utils";
import { GraphicContainer, GraphicItem, GraphicBar, GraphicMaxBar, Row, GraphicMaxBarContainer, GraphicLegend, LegendItem } from "./styled";

const GraphicAcum = ({data, legend})=>{
    const [minAndMaxValue, setMinAndMaxValue] = useState();
    const classNames = ["blue", "red", "dashed"];
    
    function minAndMaxValues(data){
        let maxValue = data?.[0].value;
        let minValue = data?.[0].value;

        for (let i = 1; i < data?.length; i++) {
            const currentValue = data[i].value;

            if (currentValue > maxValue) {
                maxValue = currentValue;
            }

            if (currentValue < minValue) {
                minValue = currentValue;
            }
        }

        return {max: maxValue, min: minValue};
    }

    useEffect(()=>{
        setMinAndMaxValue(minAndMaxValues(data));
    }, []);


    return(
        <GraphicContainer>
            <Row>
                {
                    data.map((item, index)=>(
                        <GraphicItem>
                            <GraphicMaxBarContainer>
                                <FontGreyDarkRegular>{parseFloat(item?.value/1000).toFixed(2)}</FontGreyDarkRegular>
                                <GraphicMaxBar className="bar-config">
                                    <GraphicBar className={`bar-config ${classNames[index]}`} height={item?.value} total={minAndMaxValue?.max}/>
                                </GraphicMaxBar>
                            </GraphicMaxBarContainer>
                            <FontSmallGreyDarkBold>{item.year}</FontSmallGreyDarkBold>
                        </GraphicItem>
                    ))
                }
            </Row>

            <GraphicLegend>
                {
                    legend?.map(legendItem=>(
                        <LegendItem color={legendItem.color}>
                            <div className={legendItem.color}></div>
                            <FontGreyDarkRegular>{legendItem.label}</FontGreyDarkRegular>
                        </LegendItem>
                    ))
                }
            </GraphicLegend>

        </GraphicContainer>
    );
}

export default GraphicAcum;