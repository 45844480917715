export function setShowLabels(showLabels){
    return {
      type : "SET_SHOW_LABELS",
      showLabels
    }
}

export function setZoom(zoom){
    return {
      type : "SET_ZOOM",
      zoom
    }
}