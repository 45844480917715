import { useEffect, useState } from 'react';
import Guide from '../../Guide';
import { AnalyzeAnticorrosiveContainer, Column, ActionsContainer, GraphicContainer, JoinedPlanContainer, OpexContainer, OtherInfo, PaintCostsContainer, PaintingPlaneContainer, ReportsContainer, Row, SCurveContainer, TabItem, TabSelect, TotalAreaContainer } from './styled';
import { FontGreyDarkMedium, FontGreyDarkMediumTitle, FontGreyDarkRegular, FontGreyDarkSemiMedium, FontGreyMediumSemiMedium, FontPrimaryMediumTitle, FontPrimaryMegaTitle, FontPrimarySuperTitle } from '../../../../utils/typografyApp';
import CardGraphic from '../../CardGraphic';
import GraphicExpiration from '../../GraphicExpiration';
import GraphicBars from '../../GraphicBars';
import GraphicAcum from '../../GraphicAcum';
import GraphicRadar from '../../GraphicRadar';
import plus_icon from '../../../../assets/icons/plus.svg';
import Map from "../../Map";
import GraphicQuantitative from '../../GraphicQuantitative';
import GraphicStatusColumn from '../../GraphicStatusColumn';
import GraphicBarsClass from '../../GraphicBarsClass';
import GraphicQuantitativeSmall from '../../GraphicQuantitativeSmall';
import api from '../../../../service/api';
import card_1_icon from '../../../../assets/icons/card-1.svg';
import card_2_icon from '../../../../assets/icons/card-2.svg';
import card_3_icon from '../../../../assets/icons/card-3.svg';
import card_4_icon from '../../../../assets/icons/card-4.svg';
import AssetInformation from '../../AssetInformation';
import Utils from '../../../../utils/utils';
import GraphicStatus from '../../GraphicStatus';
import GraphicDegradation from '../../GraphicDegradation';
import SmallButton from '../../SmallButton';
import SecondaryButton from '../../SecondaryButton';
import { Loading } from '../../Loading';
import { CardReportsController } from './controller';
import CardReportProdutivity from '../CardReportsProdutivity';
import CardReportFinacial from '../CardReportsFinancial';
import CardReportAssets from '../CardReportsAssets';

const CardReports = () => {
    const tabItems = ['Produtividade', 'Ativos', 'Financeiro'];
    const [selectedTab, setSelectedTab] = useState('Produtividade');
    const [loading, setLoading] = useState([]);
    const [assets, setAssets] = useState([]);
    const [conveyors, setConveyors] = useState([]);
    const [anualPaintingPlan, setAnualPaintingPlan] = useState([]);
    const [sCurvePlan, setSCurvePlan] = useState([]);
    const [adherenceAnualPlan, setAdherenceAnualPlan] = useState([]);

    const [dueDatesData, setDueDatesData] = useState();

    const controller = new CardReportsController();

    useEffect(() => {
        if (selectedTab === 'Ativos') {
            controller.getAllAssets(setLoading, setAssets);
            controller.getAllConveyors(setLoading, setConveyors);

        }
        controller.getDueDates(setLoading, setDueDatesData);
        controller.getAnualPaintingPlan(setLoading, setAnualPaintingPlan);
        controller.getSCurvePlan(setLoading, setSCurvePlan);
        controller.getAdherenceAnualPlan(setLoading, setAdherenceAnualPlan);

    }, [selectedTab]);

    return (
        <ReportsContainer id="reports">
            <Guide showInfo={false} />
            <TabSelect>
                {tabItems.map(item => (
                    <TabItem onClick={() => setSelectedTab(item)} active={item === selectedTab ? "true" : "false"}>
                        {item}
                    </TabItem>
                ))}
            </TabSelect>

            {
                selectedTab === tabItems[0] && <CardReportProdutivity />
                // <>
                //     <GraphicContainer>
                //         <FontGreyDarkMediumTitle>Analise longo prazo tratamento Anticorrosivo (m²)</FontGreyDarkMediumTitle>
                //         <AnalyzeAnticorrosiveContainer>
                //             <Row>
                //                 <Column className='long'>
                //                     <CardGraphic
                //                         height={200}
                //                         graphic={
                //                             dueDatesData?.lineChart ?
                //                             <GraphicExpiration
                //                                 grid={true}
                //                                 label={'analyzeAntirrosive'}
                //                                 legend={[
                //                                     {color: "#0C8CE9", label: "Priorização Técnica"},
                //                                     {color: "#FF4D00", label: "Execução de TAC"},
                //                                     {color: "#FF0000", label: "Passivo de Pintura m²"},
                //                                 ]}

                //                                 config={[
                //                                     {color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "execucaoTAC1"},
                //                                     {color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento1"},
                //                                 ]}

                //                                 months={true}

                //                                 data={ dueDatesData.lineChart }
                //                             /> :
                //                             <Loading size={30}/>
                //                         }
                //                     />

                //                     <CardGraphic
                //                         height={100}
                //                         graphic={
                //                             dueDatesData?.barYears ?
                //                             <GraphicBars
                //                                 config={[
                //                                     {color: "#0C8CE9", label: ""},
                //                                     {color: "#FF4D00", label: ""}
                //                                 ]}

                //                                 data={dueDatesData.barYears}
                //                             /> :
                //                             <Loading size={30}/>
                //                         }
                //                     />
                //                 </Column>
                //                 <Column className='small'>
                //                     <CardGraphic
                //                         height={340}
                //                         graphic={
                //                             dueDatesData?.barChart ?
                //                             <GraphicAcum
                //                                 legend={[
                //                                     {color: "blue", label: "Acum. Programado"},
                //                                     {color: "red", label: "Acum. Realizado"},
                //                                     {color: "dashed", label: "Orçamento"},
                //                                 ]}

                //                                 data={[
                //                                     {label: "Acum. Programado", value: dueDatesData.barChart.accumulatedSheduled},
                //                                     {label: "Acum. Realizado", value: dueDatesData.barChart.accumulatedRealized},
                //                                     {label: "Orçamento", value: dueDatesData.barChart.budget}
                //                                 ]}
                //                             />:
                //                             <Loading/>
                //                         }
                //                     />

                //                 </Column>
                //             </Row>
                //         </AnalyzeAnticorrosiveContainer>

                //         <PaintingPlaneContainer>
                //             <FontGreyDarkMediumTitle>Planejamento de pintura anual (m²)</FontGreyDarkMediumTitle>
                //             <Row>
                //                 <Column className='long'>
                //                     <CardGraphic
                //                         height={320}
                //                         graphic={
                //                             anualPaintingPlan?.lineChart ?
                //                             <GraphicExpiration
                //                                 months={true}
                //                                 grid={true}
                //                                 label={'paintingPlane'}
                //                                 rows={8}
                //                                 legend={[
                //                                     {color: "#0C8CE9", label: "Priorização Técnica"},
                //                                     {color: "#FF4D00", label: "Execução de TAC"},
                //                                 ]}

                //                                 config={[
                //                                     {color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "execucaoTAC"},
                //                                     {color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento"},
                //                                     // {color: "#0C8CE9", width: 1, dialog: true, lineStyle: "solid", label: "previsao", position: "bottom"}
                //                                 ]}

                //                                 data={anualPaintingPlan.lineChart}
                //                             /> : 
                //                             <Loading/>
                //                         }
                //                     />
                //                 </Column>
                //                 <Column className='small'>
                //                     <CardGraphic
                //                         height={320}
                //                         graphic={
                //                             anualPaintingPlan?.barChart ?
                //                             <GraphicAcum
                //                                 legend={[
                //                                     {color: "blue", label: "Acum. Programado"},
                //                                     {color: "red", label: "Acum. Realizado"},
                //                                     {color: "dashed", label: "Orçamento"},
                //                                 ]}

                //                                 data={[
                //                                     {label: "Acum. Programado", value: anualPaintingPlan.barChart.accumulatedSheduled},
                //                                     {label: "Acum. Realizado", value: anualPaintingPlan.barChart.accumulatedRealized},
                //                                     {label: "Orçamento", value: anualPaintingPlan.barChart.budget}
                //                                 ]}
                //                             /> :
                //                             <Loading/>

                //                         }
                //                     />
                //                 </Column>
                //             </Row>
                //         </PaintingPlaneContainer>

                //         <Row>
                //             <SCurveContainer>
                //                 <FontGreyDarkMediumTitle>Curva S de Avanço</FontGreyDarkMediumTitle>
                //                 <Row>
                //                     <Column className='full'>
                //                         <CardGraphic
                //                             height={330}
                //                             graphic={
                //                                 sCurvePlan?.lineChart ?
                //                                 <GraphicExpiration
                //                                     months={true}
                //                                     grid={true}
                //                                     label={'sCurve'}
                //                                     rows={8}
                //                                     legend={[
                //                                         {color: "#0C8CE9", label: "Planejado"},
                //                                         {color: "#FF4D00", label: "Executado"},
                //                                     ]}

                //                                     config={[
                //                                         {color: "#0C8CE9", width: 2, dialog: true, lineStyle: "dashed", label: "planejado"},
                //                                         {color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "ralizado", position: "bottom"},

                //                                     ]}

                //                                     data={sCurvePlan.lineChart}
                //                                 />:
                //                                 <Loading/>
                //                             }
                //                         />
                //                     </Column>
                //                 </Row>
                //             </SCurveContainer>

                //             <JoinedPlanContainer>
                //                 <FontGreyDarkMediumTitle>Aderencia do Plano Anual dos Ativos (m²)</FontGreyDarkMediumTitle>
                //                 <Row>
                //                     <Column className='full'>
                //                         <CardGraphic
                //                             height={205}
                //                             graphic={
                //                                 adherenceAnualPlan?.lineChart ?
                //                                 <GraphicExpiration
                //                                     grid={true}
                //                                     months={true}
                //                                     label={'joinedPlan'}
                //                                     rows={4}
                //                                     legend={[
                //                                         {color: "#FF4D00", label: "Ad - Real / Plan"},
                //                                     ]}

                //                                     config={[
                //                                         {color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "Ad - Real / Plan"},
                //                                     ]}

                //                                     data={adherenceAnualPlan.lineChart}
                //                                 /> : 
                //                                 <Loading/>
                //                             }
                //                         />
                //                     </Column>
                //                 </Row>

                //                 <Row>
                //                     <Column className='full'>
                //                     <CardGraphic
                //                         height={120}
                //                         graphic={
                //                             adherenceAnualPlan?.barYears ?
                //                             <GraphicBars
                //                                 config={[
                //                                     {color: "#0C8CE9", label: ""},
                //                                     {color: "#FF4D00", label: ""}
                //                                 ]}

                //                                 legend={[
                //                                     {color: "#0C8CE9", label: "Planejado"},
                //                                     {color: "#FF4D00", label: "Executado"},
                //                                 ]}

                //                                 data={adherenceAnualPlan?.barYears}
                //                             /> : 
                //                             <Loading/>
                //                         }
                //                     />
                //                     </Column>
                //                 </Row>
                //             </JoinedPlanContainer>
                //         </Row>
                //     </GraphicContainer>

                // </>
            }

            {
                selectedTab === tabItems[1] && <CardReportAssets />
                // <>
                //     <GraphicContainer>
                //         <Row>
                //             <Column className='small-3'>
                //                 <TotalAreaContainer>
                //                     <FontGreyDarkMedium>Área Total da Planta</FontGreyDarkMedium>
                //                     <FontPrimaryMegaTitle>1,32 Milhoes de m²</FontPrimaryMegaTitle>
                //                     <div className='map-container'>
                //                         <Map zoom={8} />
                //                     </div>
                //                     <div className='count-info'>
                //                         <FontGreyDarkSemiMedium>Total de ativos</FontGreyDarkSemiMedium>
                //                         <FontPrimaryMegaTitle>120</FontPrimaryMegaTitle>
                //                     </div>

                //                 </TotalAreaContainer>
                //             </Column>
                //             <Column className='small-3'>
                //                 <GraphicQuantitative
                //                     title={{
                //                         value: "70",
                //                         description: "Ativos com área vencida",
                //                         color: "#ff0000"
                //                     }}

                //                     subtitle={{
                //                         value: "120.000",
                //                         description: "Área (m²)",
                //                         color: "#878787"
                //                     }}
                //                 />

                //                 <GraphicStatusColumn
                //                     title={"Status dos Ativos (m²)"}
                //                     config={[
                //                         { color: "#48DF45" },
                //                         { color: "#FFAB01" },
                //                         { color: "#FF0000" }
                //                     ]}
                //                     data={{
                //                         total: 3200000,

                //                         values: [
                //                             1120000,
                //                             1040000,
                //                             1040000,
                //                         ]
                //                     }}
                //                     legend={{
                //                         label: "Área total",
                //                         value: "3.200.000"
                //                     }}
                //                 />
                //                 <OtherInfo>
                //                     <FontGreyDarkRegular>Passivo</FontGreyDarkRegular>
                //                     <div>
                //                         <FontPrimarySuperTitle>350.000</FontPrimarySuperTitle>
                //                         <FontGreyDarkRegular>m²</FontGreyDarkRegular>
                //                     </div>
                //                 </OtherInfo>
                //             </Column>
                //             <Column className='small-3'>
                //                 <GraphicQuantitative
                //                     title={{
                //                         value: "50",
                //                         description: "Ativos com degradação",
                //                         color: "#770101"
                //                     }}

                //                     subtitle={{
                //                         value: "230.000",
                //                         description: "Área (m²)",
                //                         color: "#878787"
                //                     }}
                //                 />

                //                 <GraphicStatusColumn
                //                     title={"Degradação de RI's (m²)"}
                //                     config={[
                //                         { color: "#FF7A00" },
                //                         { color: "#FF4D00" },
                //                         { color: "#FF0000" },
                //                         { color: "#BF0000" },
                //                         { color: "#770101" },
                //                     ]}
                //                     data={{
                //                         total: 3200000,

                //                         values: [
                //                             1120000,
                //                             1040000,
                //                             1040000,
                //                             1040000,
                //                             1040000,
                //                         ]
                //                     }}
                //                     legend={{
                //                         label: "Áreas em RI",
                //                         value: "3.200.000"
                //                     }}
                //                 />
                //                 <OtherInfo>
                //                     <FontGreyDarkRegular>Parque instalado</FontGreyDarkRegular>
                //                     <div>
                //                         <FontPrimarySuperTitle>46%</FontPrimarySuperTitle>
                //                     </div>
                //                 </OtherInfo>
                //             </Column>

                //         </Row>
                //         <GraphicContainer>
                //             <Row>

                //                 <Column className='medium mt'>
                //                     <Row>
                //                         <GraphicQuantitativeSmall
                //                             title={{
                //                                 description: "Taxa de renovação - Teórico",
                //                                 color: "#292929"
                //                             }}

                //                             icon={card_4_icon}

                //                             subtitle={{
                //                                 value: "30.000",
                //                                 description: "m²/ano",
                //                                 color: "#0C8CE9"
                //                             }}
                //                         />
                //                         <GraphicQuantitativeSmall
                //                             title={{
                //                                 description: "Taxa de renovação - Real",
                //                                 color: "#292929"
                //                             }}

                //                             icon={card_3_icon}

                //                             subtitle={{
                //                                 value: "23.000",
                //                                 description: "m²/ano",
                //                                 color: "#0C8CE9"
                //                             }}
                //                         />
                //                     </Row>

                //                     <Row>
                //                         <GraphicQuantitativeSmall
                //                             title={{
                //                                 description: "Vida útil média da pintura",
                //                                 color: "#292929"
                //                             }}

                //                             icon={card_1_icon}

                //                             subtitle={{
                //                                 value: "7,5",
                //                                 description: "anos",
                //                                 color: "#0C8CE9"
                //                             }}
                //                         />
                //                         <GraphicQuantitativeSmall
                //                             title={{
                //                                 description: "Corrosividade",
                //                                 color: "#292929"
                //                             }}

                //                             icon={card_2_icon}

                //                             subtitle={{
                //                                 value: "C5",
                //                                 description: "",
                //                                 color: "#0C8CE9"
                //                             }}
                //                         />
                //                     </Row>
                //                 </Column>

                //                 <Column className='medium ml'>
                //                     <CardGraphic
                //                         graphic={<GraphicExpiration
                //                             grid={true}
                //                             label={'passive'}
                //                             rows={5}
                //                             isArea={true}
                //                             legend={[
                //                                 { color: "#FF4D00", label: "Passivo (m²)" },
                //                             ]}

                //                             config={[
                //                                 { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "Ad - Real / Plan" },
                //                             ]}

                //                             data={[
                //                                 { label: "2015", values: [230000] },
                //                                 { label: "2016", values: [225000] },
                //                                 { label: "2017", values: [300000] },
                //                                 { label: "2018", values: [150000] },
                //                                 { label: "2019", values: [100000] },
                //                                 { label: "2020", values: [90000] },
                //                                 { label: "2021", values: [150000] },
                //                                 { label: "2022", values: [150000] },
                //                             ]}
                //                         />}
                //                     />
                //                 </Column>
                //             </Row>
                //         </GraphicContainer>
                //         <GraphicContainer>
                //             <Row>

                //                 <Column className='medium'>
                //                     <FontGreyDarkMediumTitle>Vencimento de pintura</FontGreyDarkMediumTitle>
                //                     <CardGraphic
                //                         graphic={<GraphicBarsClass
                //                             data={[
                //                                 { label: "Parainer I", value: 2300, total: 3200 },
                //                                 { label: "Parainer II", value: 2800, total: 3200 },
                //                                 { label: "Parainer III", value: 2300, total: 3200 },
                //                                 { label: "Parainer IV", value: 1300, total: 3200 },
                //                             ]}
                //                         />}
                //                     />
                //                 </Column>

                //                 <Column className='medium'>
                //                     <FontGreyDarkMediumTitle>Classe de degradação</FontGreyDarkMediumTitle>
                //                     <CardGraphic
                //                         graphic={<GraphicBarsClass
                //                             data={[
                //                                 { label: "Parainer I", value: 2300, total: 3200 },
                //                                 { label: "Parainer II", value: 3000, total: 3200 },
                //                                 { label: "Parainer III", value: 2300, total: 3200 },
                //                                 { label: "Parainer IV", value: 2000, total: 3200 },
                //                             ]}
                //                         />}
                //                     />
                //                 </Column>
                //             </Row>
                //         </GraphicContainer>
                //         <GraphicContainer>
                //             <Row className='border'>
                //                 <Column className="medium">
                //                     <Column>
                //                         <FontGreyDarkMediumTitle>Modos de falha</FontGreyDarkMediumTitle>
                //                         <div className='graphicRadarContainer'>
                //                             <CardGraphic
                //                                 graphic={<GraphicRadar
                //                                     config={[
                //                                         { label: 'Falha geral', color: 'RGBA(168, 168, 168, 0.2)', borderColor: '#A8A8A8' },
                //                                         { label: 'Seleção', color: 'RGBA(255, 0, 0, 0.4)', borderColor: '#FF0000' },
                //                                     ]}
                //                                     datasets={[
                //                                         { data: [5000, 12000, 19000, 13000, 6000, 3000] },
                //                                         { data: [500, 6000, 15000, 1200, 400, 50] },
                //                                     ]}

                //                                     labels={['Enrugamento', 'Descascamento', 'Enferrujamento', 'Calcinação', 'Craqueamento', 'Empolamento']}
                //                                 />}
                //                             />
                //                         </div>
                //                     </Column>
                //                 </Column>

                //                 <Column className="medium">
                //                     <div className='graphicRadarMapContainer'>
                //                         <Map selectedMarker={assets && assets[0]} assets={assets} conveyors={conveyors} />
                //                     </div>
                //                 </Column>
                //             </Row>
                //         </GraphicContainer>
                //         <Row>
                //             <Column className="full">
                //                 <CardGraphic
                //                     graphic={<GraphicExpiration
                //                         grid={true}
                //                         label={'anualDue'}
                //                         rows={4}
                //                         isArea={true}
                //                         legend={[
                //                             { color: "#FFAB01", label: "Vencimento" },
                //                             { color: "#FF0000", label: "Passivo + Vencimento" },
                //                         ]}

                //                         config={[
                //                             { color: "#FFAB01", width: 1, dialog: true, lineStyle: "solid", label: "anualDue", measure: "m²" },
                //                             { color: "#FF0000", width: 1, dialog: true, lineStyle: "solid", label: "anualDuePassive", measure: "m²" },
                //                         ]}

                //                         data={[
                //                             { label: "2012", values: [30000, 10000] },
                //                             { label: "2013", values: [41000, 11000] },
                //                             { label: "2014", values: [67000, 7000] },
                //                             { label: "2015", values: [82000, 12000] },
                //                             { label: "2016", values: [97000, 27000] },
                //                             { label: "2017", values: [96000, 16000] },
                //                             { label: "2018", values: [85000, 25000] },
                //                             { label: "2019", values: [77000, 17000] },
                //                             { label: "2020", values: [82000, 22000] },
                //                             { label: "2021", values: [70000, 20000] },
                //                             { label: "2022", values: [74000, 14000] },
                //                             { label: "2023", values: [91000, 31000] },
                //                         ]}
                //                     />}
                //                 />
                //             </Column>
                //         </Row>

                //     </GraphicContainer>
                //     <GraphicContainer>
                //         {
                //             assets?.rows?.map(asset => (
                //                 <Row className="full border">
                //                     <div className='width-20'>
                //                         <AssetInformation asset={asset} reports={true} />
                //                         <ActionsContainer>
                //                             <SmallButton>
                //                                 <img src={plus_icon} alt="Icone de adicionar" />
                //                             </SmallButton>
                //                             <SecondaryButton className='secondary-button' onClick={() => { }}>Detalhes do Ativo</SecondaryButton>
                //                         </ActionsContainer>
                //                     </div>

                //                     <div className='width-20'>
                //                         <CardGraphic
                //                             reports={true}
                //                             title={`Status do Ativo (m²)`}
                //                             footer={{ description: "Área Total", value: Utils.formatNumberWithCommas(1500000) }}
                //                             graphic={<GraphicStatus data={{
                //                                 total: 1500000,
                //                                 active: 800000,
                //                                 upcoming: 500000,
                //                                 expired: 200000
                //                             }} />}
                //                         />
                //                     </div>
                //                     <div className='width-20'>
                //                         <CardGraphic
                //                             title={"Degradação de RI's (m²)"}
                //                             footer={{ description: "Áreas em RI", value: Utils.formatNumberWithCommas(200000) }}
                //                             graphic={
                //                                 <GraphicDegradation data={{
                //                                     total: 200000,
                //                                     ris: [
                //                                         { type: "Ri1", value: 20000 },
                //                                         { type: "Ri2", value: 40000 },
                //                                         { type: "Ri3", value: 50000 },
                //                                         { type: "Ri4", value: 60000 },
                //                                         { type: "Ri5", value: 10000 },
                //                                     ]
                //                                 }} />
                //                             }
                //                         />
                //                     </div>
                //                     <div className='width-40'>
                //                         <CardGraphic
                //                             title={"Projeção de Vencimento (m²)"}
                //                             graphic={<GraphicExpiration
                //                                 config={[
                //                                     { color: "#FF0000", width: 1, dialog: true, lineStyle: "solid", label: `reports${asset.id}` },
                //                                 ]}
                //                                 data={[
                //                                     { label: "2024", values: [200000] },
                //                                     { label: "2025", values: [300000] },
                //                                     { label: "2026", values: [150000] },
                //                                     { label: "2027", values: [120000] },
                //                                 ]}
                //                             />}
                //                         />
                //                     </div>
                //                 </Row>
                //             ))
                //         }

                //     </GraphicContainer>
                // </>
            }

            {
                selectedTab === tabItems[2] && <CardReportFinacial />
                // <>
                //     <GraphicContainer>
                //         <FontGreyDarkMediumTitle>Opex - Planejamento Anual de Gastos</FontGreyDarkMediumTitle>
                //         <OpexContainer>
                //             <Row>
                //                 <Column className='long'>
                //                     <CardGraphic
                //                         graphic={<GraphicExpiration
                //                             grid={true}
                //                             rows={8}
                //                             label={'opex'}
                //                             legend={[
                //                                 { color: "#0C8CE9", label: "Priorização Técnica" },
                //                                 { color: "#FF4D00", label: "Execução" },
                //                                 { color: "#FF0000", label: "Orçamento" },
                //                             ]}

                //                             config={[
                //                                 { color: "#0C8CE9", width: 1, dialog: true, lineStyle: "solid", label: "priorizacao1" },
                //                                 { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "execucaoTAC1", position: "bottom" },
                //                                 { color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento1" },
                //                             ]}

                //                             data={[
                //                                 { label: "jan", values: [1800, 1700, 2600] },
                //                                 { label: "fev", values: [1300, 800, 2600] },
                //                                 { label: "mar", values: [2300, 1700, 2600] },
                //                                 { label: "abr", values: [1750, 1500, 2600] },
                //                                 { label: "mai", values: [3240, 2400, 2600] },
                //                                 { label: "jun", values: [4160, 3360, 2600] },
                //                                 { label: "jul", values: [7800, 5000, 2600] },
                //                                 { label: "ago", values: [5400, 3200, 2600] },
                //                                 { label: "set", values: [5000, 4000, 2600] },
                //                                 { label: "out", values: [1600, 800, 2600] },
                //                                 { label: "nov", values: [2120, 1500, 2600] },
                //                                 { label: "dez", values: [3100, 2300, 2600] },
                //                             ]}
                //                         />}
                //                     />
                //                 </Column>
                //                 <Column className='small'>
                //                     <CardGraphic
                //                         graphic={<GraphicAcum
                //                             legend={[
                //                                 { color: "blue", label: "Acum. Programado" },
                //                                 { color: "red", label: "Acum. Realizado" },
                //                                 { color: "dashed", label: "Orçamento" },
                //                             ]}

                //                             data={[
                //                                 { value: 110000 },
                //                                 { value: 96000 },
                //                                 { value: 120000 }
                //                             ]}
                //                         />}
                //                     />

                //                 </Column>
                //             </Row>
                //         </OpexContainer>
                //     </GraphicContainer>

                //     <GraphicContainer>
                //         <FontGreyDarkMediumTitle>Gastos em pintura (R$ milhões)</FontGreyDarkMediumTitle>
                //         <PaintCostsContainer>
                //             <Row>
                //                 <Column className='full'>
                //                     <CardGraphic
                //                         graphic={
                //                             <GraphicBars
                //                                 scale={1000000}
                //                                 spaceWidth={4}

                //                                 config={[
                //                                     { color: "#0C8CE9", label: "" },
                //                                     { color: "#FF4D00", label: "" }
                //                                 ]}

                //                                 legend={[
                //                                     { color: "#0C8CE9", label: "Planejado" },
                //                                     { color: "#FF4D00", label: "Executado" },
                //                                 ]}

                //                                 data={[
                //                                     { label: 2012, values: [8000000, 11000000] },
                //                                     { label: 2013, values: [12000000, 15000000] },
                //                                     { label: 2014, values: [15000000, 18000000] },
                //                                     { label: 2015, values: [18000000, 21000000] },
                //                                     { label: 2016, values: [23000000, 26000000] },
                //                                     { label: 2017, values: [25000000, 28000000] },
                //                                     { label: 2018, values: [28000000, 31000000] },
                //                                     { label: 2019, values: [31000000, 34000000] },
                //                                     { label: 2020, values: [34000000, 37000000] },
                //                                     { label: 2021, values: [34000000, 38000000] },
                //                                     { label: 2022, values: [38000000, 40000000] },
                //                                 ]}
                //                             />}
                //                     />
                //                 </Column>
                //             </Row>
                //         </PaintCostsContainer>
                //     </GraphicContainer>

                //     <GraphicContainer>
                //         <FontGreyDarkMediumTitle>Eficiência de execução - R$ X m²</FontGreyDarkMediumTitle>
                //         <PaintCostsContainer>
                //             <Row>
                //                 <Column className='full'>
                //                     <CardGraphic
                //                         graphic={
                //                             <GraphicExpiration
                //                                 grid={true}
                //                                 rows={5}
                //                                 label={'executionEfficiency'}
                //                                 legend={[
                //                                     { color: "#FF4D00", label: "Real" },
                //                                     { color: "#878787", label: "Orçamento", },
                //                                 ]}

                //                                 config={[
                //                                     { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "real" },
                //                                     { color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento2" },
                //                                 ]}

                //                                 data={[
                //                                     { label: "jan", values: [225000, 245000] },
                //                                     { label: "fev", values: [250000, 245000] },
                //                                     { label: "mar", values: [225000, 245000] },
                //                                     { label: "abr", values: [260000, 245000] },
                //                                     { label: "mai", values: [225000, 245000] },
                //                                     { label: "jun", values: [260000, 245000] },
                //                                     { label: "jul", values: [225000, 245000] },
                //                                     { label: "ago", values: [250000, 245000] },
                //                                     { label: "set", values: [260000, 245000] },
                //                                     { label: "out", values: [225000, 245000] },
                //                                     { label: "nov", values: [250000, 245000] },
                //                                     { label: "dez", values: [260000, 245000] },
                //                                 ]}
                //                             />
                //                         }
                //                     />
                //                 </Column>
                //             </Row>
                //         </PaintCostsContainer>
                //     </GraphicContainer>

                //     <GraphicContainer>
                //         <FontGreyDarkMediumTitle>Produtividade efeito (m²/H. dia)</FontGreyDarkMediumTitle>
                //         <PaintCostsContainer>
                //             <Row>
                //                 <Column className='full'>
                //                     <CardGraphic
                //                         graphic={
                //                             <GraphicBars
                //                                 spaceWidth={4}

                //                                 config={[
                //                                     { color: "#0C8CE9", label: "" },
                //                                     { color: "#FF4D00", label: "" }
                //                                 ]}

                //                                 legend={[
                //                                     { color: "#0C8CE9", label: "Planejado" },
                //                                     { color: "#FF4D00", label: "Executado" },
                //                                 ]}

                //                                 data={[
                //                                     { label: 2012, values: [14000, 9500] },
                //                                     { label: 2013, values: [13000, 10000] },
                //                                     { label: 2014, values: [14000, 11000] },
                //                                     { label: 2015, values: [15000, 12000] },
                //                                     { label: 2016, values: [10000, 12000] },
                //                                     { label: 2017, values: [12000, 11000] },
                //                                     { label: 2018, values: [12000, 10000] },
                //                                     { label: 2019, values: [12000, 9000] },
                //                                     { label: 2020, values: [10000, 9000] },
                //                                     { label: 2021, values: [12000, 8500] },
                //                                     { label: 2022, values: [12000, 8500] },
                //                                 ]}
                //                             />}
                //                     />
                //                 </Column>
                //             </Row>
                //         </PaintCostsContainer>
                //     </GraphicContainer>
                //     <GraphicContainer>
                //         <Row>
                //             <JoinedPlanContainer>
                //                 <FontGreyDarkMediumTitle>Eficiência de execução - R$ X m²</FontGreyDarkMediumTitle>
                //                 <Row>
                //                     <Column className='full'>
                //                         <CardGraphic
                //                             graphic={<GraphicExpiration
                //                                 grid={true}
                //                                 label={'exectionEficiency'}
                //                                 rows={4}
                //                                 legend={[
                //                                     { color: "#FF4D00", label: "Realizado" },
                //                                 ]}

                //                                 config={[
                //                                     { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "Realizado" },
                //                                 ]}

                //                                 data={[
                //                                     { label: 2012, values: [90000] },
                //                                     { label: 2013, values: [87000] },
                //                                     { label: 2014, values: [84000] },
                //                                     { label: 2015, values: [81000] },
                //                                     { label: 2016, values: [78000] },
                //                                     { label: 2017, values: [75000] },
                //                                     { label: 2018, values: [120000] },
                //                                     { label: 2019, values: [139000] },
                //                                     { label: 2020, values: [145000] },
                //                                     { label: 2021, values: [180000] },
                //                                     { label: 2022, values: [190000] },
                //                                     { label: 2023, values: [203000] },
                //                                 ]}
                //                             />}
                //                         />
                //                     </Column>
                //                 </Row>

                //                 <Row>
                //                     <Column className='full'>
                //                         <CardGraphic
                //                             graphic={<GraphicBars

                //                                 config={[
                //                                     { color: "#FF4D00", label: "Realizado" },
                //                                 ]}

                //                                 legend={[
                //                                     { color: "#FF4D00", label: "Executado" },
                //                                 ]}

                //                                 data={[
                //                                     { label: 2012, values: [90000] },
                //                                     { label: 2013, values: [87000] },
                //                                     { label: 2014, values: [84000] },
                //                                     { label: 2015, values: [81000] },
                //                                     { label: 2016, values: [78000] },
                //                                     { label: 2017, values: [75000] },
                //                                     { label: 2018, values: [120000] },
                //                                     { label: 2019, values: [139000] },
                //                                     { label: 2020, values: [145000] },
                //                                     { label: 2021, values: [180000] },
                //                                     { label: 2022, values: [190000] },
                //                                 ]}
                //                             />}
                //                         />
                //                     </Column>
                //                 </Row>
                //             </JoinedPlanContainer>

                //             <SCurveContainer>
                //                 <FontGreyDarkMediumTitle>Produtividade Efetivo - m² X Hora por dia</FontGreyDarkMediumTitle>
                //                 <Row>
                //                     <Column className='full'>
                //                         <CardGraphic
                //                             graphic={<GraphicExpiration
                //                                 grid={true}
                //                                 label={'sCurve'}
                //                                 rows={8}
                //                                 legend={[
                //                                     { color: "#FF4D00", label: "Série 1" },
                //                                     { color: "#0C8CE9", label: "Série 2" },

                //                                 ]}

                //                                 config={[
                //                                     { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "serie1" },
                //                                     { color: "#0C8CE9", width: 2, dialog: false, lineStyle: "solid", label: "serie2" },
                //                                 ]}

                //                                 data={[
                //                                     { label: "jan", values: [10800, 8000] },
                //                                     { label: "fev", values: [8600, 8000] },
                //                                     { label: "mar", values: [11700, 8000] },
                //                                     { label: "abr", values: [12140, 8000] },
                //                                     { label: "mai", values: [11060, 8000] },
                //                                     { label: "jun", values: [11000, 8000] },
                //                                     { label: "jul", values: [8000, 8000] },
                //                                     { label: "ago", values: [7500, 8000] },
                //                                     { label: "set", values: [6000, 8000] },
                //                                     { label: "out", values: [8000, 8000] },
                //                                     { label: "nov", values: [10100, 8000] },
                //                                     { label: "dez", values: [4710, 8000] },
                //                                 ]}
                //                             />}
                //                         />
                //                     </Column>
                //                 </Row>
                //             </SCurveContainer>
                //         </Row>
                //     </GraphicContainer>
                // </>
            }


        </ReportsContainer>

    )
}

export default CardReports;