import { useQuery } from 'react-query';
import { metricsQueries, useMetricsService } from '../../../../services/metricsService';

export function useCardReportAssetsHook() {
    const assetService = useMetricsService();

    
    const {
        data: singleMetricsData,
        isLoading: isLoadingSingleMetrics,
        isRefetching: isRefetchingSingleMetrics,
        refetch: refetchSingleMetrics
    } = useQuery(metricsQueries.GET_SINGLE_BY_CLIENT, () => assetService.getSingleByClient({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: generalMetricsData,
        isLoading: isLoadingMetrics,
        isRefetching: isRefetchingMetrics,
        refetch: refetchGeneralMetrics
    } = useQuery(metricsQueries.GET_ASSETS_GENERAL_BY_CLIENT, () => assetService.getAssetMetricsGeneralById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: renovationMetricsData,
        isLoading: isLoadingRenovationMetrics,
        isRefetching: isRefetchingRenovationMetrics,
        refetch: refetchRenovationMetrics
    } = useQuery(metricsQueries.GET_ASSETS_RENOVATION_BY_CLIENT, () => assetService.getAssetsMetricsRenovationById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: dueDegradationsMetricsData,
        isLoading: isLoadingDueDegradationsMetrics,
        isRefetching: isRefetchingDueDegradationsMetrics,
        refetch: refetchDueDegradationsMetrics
    } = useQuery(metricsQueries.GET_ASSETS_DUE_DEGRADATION_BY_CLIENT, () => assetService.getAssetsMetricsDueDegradationById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));


    const {
        data: defectMetricsData,
        isLoading: isLoadingDefectMetrics,
        isRefetching: isRefetchingDefectMetrics,
        refetch: refetchDefectMetrics
    } = useQuery(metricsQueries.GET_ASSETS_DEFECT_TYPES_BY_CLIENT, () => assetService.getAssetsMetricsDefectTypesById({
        // TODO Colocar dinamico
        fkClientId: 1
    }));


    const {
        data: maturityMetricsData,
        isLoading: isLoadingMaturityMetrics,
        isRefetching: isRefetchingmatirityMetrics,
        refetch: refetchMaturityMetrics
    } = useQuery(metricsQueries.GET_ASSETS_MATURITY_BY_CLIENT, () => assetService.getAssetsMetricsMaturityById({
        // TODO Colocar dinamico
        fkClientId: 1

    }));




    return {

        single: {
            metricsData: singleMetricsData,
            isLoading: isLoadingSingleMetrics || isRefetchingSingleMetrics,
            refetchSingleMetrics
        },

        assets: {
            metricsData: generalMetricsData,
            isLoading: isLoadingMetrics || isRefetchingMetrics,
            refetchGeneralMetrics
        },
        renovations: {
            metricsData: renovationMetricsData,
            isLoading: isLoadingRenovationMetrics || isRefetchingRenovationMetrics,
            refetchRenovationMetrics
        },
        dueDegradations: {
            metricsData: dueDegradationsMetricsData,
            isLoading: isLoadingDueDegradationsMetrics || isRefetchingDueDegradationsMetrics,
            refetchDueDegradationsMetrics
        },
        maturity: {
            metricsData: maturityMetricsData,
            isLoading: isLoadingMaturityMetrics || isRefetchingmatirityMetrics,
            refetchMaturityMetrics
        },
        
        defectTypes: {
            metricsData: defectMetricsData,
            isLoading: isLoadingDefectMetrics || isRefetchingDefectMetrics,
            refetchDefectMetrics
        },

    }
}

// async upload({setLoading, sheetFile, setSheetFile, setRefresh, refresh}){
//     // setLoading(true);
//     // const formData = new FormData();
//     // formData.append('file', sheetFile);

//     // handleRequest({
//     //     apiEndpoint : "/utils/upload-sheets",
//     //     data: formData,
//     //     method: 'POST'
//     // }).then(response=>{
//     //     const {status, data} = response;
//     //     if(status === responseStatus.SUCCESS){
//     //         alert("Sucesso!");
//     //         document.getElementById("input-sheet").value = null;
//     //         setSheetFile(null);
//     //         setRefresh(!refresh);
//     //     }else{
//     //         alert("Erro ao subir a planilha");
//     //     }
//     // }).finally(()=>{
//     //     setLoading(false);
//     // })
// }

// onChangeFile(e, setSheetFile){
//     const file = e.target.files[0];
//     if (file) {
//         const fileName = file.name?.toLowerCase();
//         if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
//             setSheetFile(file);
//         } else {
//             alert('Formato de arquivo incompatível');
//             document.getElementById('input-sheet').value = null;
//         }
//     }
// }