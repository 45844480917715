import { Provider } from "react-redux";
import RoutesApp from "./Routes";
import store from './store';
import { QueryClientProvider } from "react-query";
import { queryClient } from "./utils/query-client";

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RoutesApp/>
      </QueryClientProvider>
    </Provider>
    
  );
}

export default App;
