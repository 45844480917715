import { z } from "zod";
import { fkSchema, generalMessageError } from "./config";

export const priorizationSchema = z.object({
    internalId: z.string().min(1, { message: generalMessageError }),
    date: z.string().min(1, { message: generalMessageError }),
    area: z.string().min(1, { message: generalMessageError }),
    activityDurationDaysEffective: z.string().min(1, { message: generalMessageError }),
    totalBilling: z.string().min(1, { message: generalMessageError }),
    totalArea: z.string().min(1, { message: generalMessageError }),
    isPrioritized: z.boolean().default(false),
    fkSectionsId: fkSchema,
});