import { useMutation } from "react-query";
import { useState } from "react";
import { queryClient } from "../../../../utils/query-client";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";
import { useUtilsService } from "../../../../services/utilService";
import { assetsQueries } from "../../../../services/assetService";

export function useBulkAssetModalHook({ onClose }) {
    const utilsService = useUtilsService();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [planilha, setPlanilha] = useState();
    const [zip, setZip] = useState();

    const { mutate, isLoading } = useMutation(() =>
        utilsService.uploadSheets({ planilha, zip, onUploadProgress: handleUploadProgress, onSuccess })
    );

    function onSuccess() {
        queryClient.refetchQueries(assetsQueries.GET_ALL);
        onClose();
    }

    function handleUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
    }

    function handleUpload(e) {
        mutate();
    }

    return {
        form: {
            handleUpload,
            isLoading,
            uploadProgress,
            setZip,
            setPlanilha
        }
    }
}
