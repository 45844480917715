import styled from "styled-components";

export const InfoWindowPage = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 3020;
`;

export const InfoWindowContainer = styled.div`
    cursor: default;
    position: absolute;
    z-index: 3001;
    width: ${props => props.width+120}px;
    display: flex;
    justify-content: center;

    .scroll{
        max-height: calc(100vh - 32px);
        overflow: scroll;
        padding-right: 8px;
    }

    top: ${props => {
        const screenHeight = window.innerHeight - 32;
        const infoWindowHeight = props.height; 
        let topPosition = props.position.y - 16;
        if (topPosition + infoWindowHeight > screenHeight) {
            topPosition = screenHeight - infoWindowHeight;
            if(topPosition < 0){
                topPosition = 12
            }
        }

        return topPosition + "px";
    }};

    left: ${props => {
        const screenWidth = window.innerWidth - 20;
        let leftPosition = props.position.x - 23;
        const menuWidth = 400;

        if (leftPosition + props.width > screenWidth - menuWidth) {
            leftPosition = leftPosition - props.width - 93  ;
        }

        return leftPosition + "px";
    }};
`;

export const InfoWindowCard = styled.div`
    background-color: var(--white-color);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    box-shadow: var(--box-shadow);
`;

export const InfoWindowArrow = styled.div`
    position: absolute;
    top: ${props => props.position.y}px;
    
     ${props => {
        const screenWidth = window.innerWidth - 30;
        let leftPosition = props.position.x;
        const menuWidth = 352;
        let border_right = "22px solid var(--white-color)";
        let border_left = 0;

        if (leftPosition + props.width > screenWidth - menuWidth) {
            leftPosition = leftPosition - 40;
            border_right = 0;
            border_left = "22px solid var(--white-color)";
        }

        return `
            left: ${leftPosition}px;
            border-left: ${border_left};
            border-right: ${border_right};
             
        `;
    }};

    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent; 
    transform: translateY(-50%); 
    z-index: 3000;

`;
