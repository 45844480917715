import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { AdmHomePage } from "./ui/modules/Adm/HomePage";
import { LocalClientToken } from "./utils/local-client-token";
import { LocalAdmToken } from "./utils/local-adm-token";

import LoginPage from "./ui/modules/LoginPage";
import HomePage from "./ui/modules/HomePage";
import AssetPage from "./ui/modules/AssetsPage";
import SectionPage from "./ui/modules/SectionPage";

const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const ProtectedAdmRoute = ({ admToken, children }) => {
  if (!admToken) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const RoutesApp = () => {
  const token = LocalClientToken.getStoredToken();
  const admToken = LocalAdmToken.getStoredToken();

  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/login" element={<LoginPage isAdmin={false}/>} />
          <Route path="/" element={<ProtectedRoute token={token}><HomePage /></ProtectedRoute>}/>
          <Route path="/asset/:id" element={<ProtectedRoute token={token}><AssetPage /></ProtectedRoute>}/>
          <Route path="/section/:id" element={<ProtectedRoute token={token}><SectionPage /> </ProtectedRoute>}/>
        </Routes>
      </BrowserRouter>

      <BrowserRouter basename="/adm">
        <Routes>
          <Route path="/login" element={<LoginPage isAdmin={true}/>} />
          <Route path="/" element={<ProtectedAdmRoute admToken={admToken}><AdmHomePage/></ProtectedAdmRoute>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesApp;
