import { bindActionCreators } from "redux";
import * as MapActions from "../store/actions/map";
import * as MenuActions from "../store/actions/menu";

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    Object.assign(
      {},
      MapActions,
      MenuActions
    ),
    dispatch
  );

export const mapStateToProps = state => ({ 
  map : state.map,
  menu: state.menu
});