import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const schemaQueries = {
    EDIT: "SCHEMA_EDIT",
    CREATE: "SCHEMA_CREATE",
    DELETE: "SCHEMA_DELETE",
    GET_ALL: "SCHEMA_GET_ALL",
    GET_ALL_OPTIONS: "SCHEMA_GET_ALL_OPTIONS",
    GET_BY_ID: "SCHEMA_GET_BY_ID",
}


export function useSchemaService() {

    const handleRequest = useHandleRequest();

    async function createSchema({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/schemas/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editSchema({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/schemas/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteSchema({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/schemas/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllSchema({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/schemas/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAllSchemaOptions({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/schemas/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data.rows.map(data=>({key: data.id, value: data.name}));
        }
    }

    async function getSchemaById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/schemas/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        createSchema,
        editSchema,
        deleteSchema,
        getAllSchema,
        getSchemaById,
        getAllSchemaOptions
    }
}
