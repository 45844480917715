import { z } from "zod";
import { fkSchema, generalMessageError, mediasSchema } from "./config";

export const executionSchema = z.object({
    internalId: z.string().min(1, { message: generalMessageError }),
    date: z.string().min(1, { message: generalMessageError }),
    lifespan: z.string().min(1, { message: generalMessageError }),
    area: z.string().min(1, { message: generalMessageError }),
    prematureFailure: z.boolean().default(false),
    position: z.string().min(1, { message: generalMessageError }),
    totalBilling: z.string().min(1, {message: generalMessageError}),
    activityDurationDaysEffective: z.string().min(1, {message: generalMessageError}),
    fkPaintingSchemaId: fkSchema,
    fkSectionsId: fkSchema,
    medias: mediasSchema
});