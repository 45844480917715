import styled from "styled-components";


export const AnomalieInfoWindowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    div{
        border-radius: 8px;
        border: 1px solid var(--grey-light-2-color);
        background: var(--white-color);
    }
`;

export const HorizontalItem = styled.div`
    display: flex;
    padding: 0.75rem 2rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

   

`;

export const VerticalItem = styled.div`
    display: flex;
    padding: 0.75rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;

    span{
        border: none;
    }
`;

export const ImagesContainer = styled.section`
   display: flex;
   gap: 8px;
   
   img{
    position: relative;
    width: 96px !important;
    height: 96px !important;
    background-color: #cecece;
    border-radius: 8px;
    border: 1px solid #cecece;
   }
`;

export const GridContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

export const TopContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
    align-self: stretch;
    align-items: start;
`;
