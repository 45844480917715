import { ModalMain } from "../styled";
import { FontGreyDarkMedium, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import { NewAsset3DForm } from "./styled";
import { useBulkAssetModalHook } from "./hook";
import { FormProvider } from "react-hook-form";
import PrimaryButton from "../../PrimaryButton";

const BulkAssetModal = ({ onClose }) => {
    const hook = useBulkAssetModalHook({ onClose });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <NewAsset3DForm>
                    <label htmlFor="zip-file">
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Cadastro de ativos em Massa</FontGreyDarkMediumTitle>
                                </div>
                                {hook.form.isLoading ? (
                                    <FontGreyDarkMedium>
                                        <span className="span">Carregando: {hook.form.uploadProgress}%</span>
                                    </FontGreyDarkMedium>
                                ) : (
                                    <FontGreyDarkMedium>
                                        <div className="form">
                                            <div className="form-group">
                                                <label htmlFor="planilha-input">Planilha de carga</label>
                                                <input id="planilha-input" type="file" placeholder="Planilha de Carga" accept=".xls, .xlsx" onChange={(e)=>hook.form.setPlanilha(e.target.files[0])}/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="zip-input">Arquivo zip contendo as imagens</label>
                                                <input id="zip-input" type="file" placeholder="Arquivo zip contendo as imagens" accept=".zip" onChange={(e)=>hook.form.setZip(e.target.files[0])}/>
                                            </div>
                                        </div>
                                    </FontGreyDarkMedium>
                                )}

                            </div>

                            <div className="actions-container">                                
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.handleUpload}>Upload</PrimaryButton>
                            </div>
                        </div>
                    </label>
                </NewAsset3DForm>
            </FormProvider>
        </ModalMain>
    );
}

export default BulkAssetModal;
