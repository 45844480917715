import { styled } from 'styled-components';

export const GraphicContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 140px;
`;

export const GraphicLegend = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    top: ${props=>props.rows *35}px;
    height: 20px !important;
`;

export const LegendItem = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    div{
        width: 0.5rem !important;
        height: 0.5rem !important;
        background-color: ${props=>props.color};
        border-radius: 0.125rem;
    }
`;

export const GraphicPanel = styled.div`
    position: ${props=>props.legend ? 'absolute' : 'relative'};
    display: grid;
    grid-template-rows: repeat(${props => props.rows}, 1fr);
    grid-template-columns: repeat(${props => props.cols+1}, 1fr);
    width: 100%;
    height: ${props=>props.legend ? `${props.rows*2.12}rem` : `100%`};
    border: 0.0125rem solid var(--grey-light-2-color);
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: ${props=>props.legend ? '30px' : 0};

    div{
        height: 2rem;
    }
`;

export const SacaleContainer = styled.div`
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
`;

export const GraphicContent = styled.div`
    position: absolute;
    display: flex;
    height: ${props=>(props.rows-2)*2.1}rem;
    width: calc( 100% - 100% / ${props=>props.cols+1});
    top: 2.2rem;
`;


export const GraphicFooter = styled.div`
    margin-top: 10px !important;
    display: flex;
    width: calc( 100% - 100% / ${props=>props.cols + 1}) !important;
`;

export const FooterLegendContainer = styled.div`
    margin-top: ${props=>props.legend ? `${props.rows*32.16 + 50}px` : 0};
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100%/${props=>props.cols});
`;

export const GraphicDialog = styled.div`
    position: relative;
    margin-bottom: ${props=>props.position === "top" ? 0.8 : -1.5}rem;
    background-color: ${props=>props.color};
    padding: 0.125rem 0.25rem;
    border-radius: 0.125rem;
    background-repeat: no-repeat;
    font-size: 0.625rem;
    color: var(--grey-light-color);
    z-index: 2;
    &::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        border-top: ${props=>props.position === "top" && `0.4rem solid ${props.color}`};
        border-bottom: ${props=>props.position === "bottom" && `0.4rem solid ${props.color}`};;
        bottom: ${props=>props.position === "top" ? -0.3 : 0.9}rem;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const ItemBackGraphic = styled.div`
    border: ${props=>props.grid === "true" && '0.0125rem solid var(--grey-light-2-color)'};
`;

export const GraphicItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: calc(100%/${props=>props.cols});
    
    padding-bottom: ${(props) => {
        const maxRem = (props.rows - 2) * 2;
        const range = props.maxvalue - props.minvalue;
        const adjustedValue = props.maxvalue - props.value;
        const calculatedMarginRem = (adjustedValue / range) * maxRem;
        return `${maxRem - calculatedMarginRem}rem`;
    }};

    .line-to{
        z-index: -1 !important;
    }

    .area{
        position: absolute;
        bottom: 0;
        margin-left: 100%;
        clip-path: polygon(
            0 0, 
            100% 0, 
            100% ${props=>{
                const maxRem = (props.rows - 2) * 2;
                const range = props.maxvalue - props.minvalue;
                const adjustedValue = props.maxvalue - props.nextvalue;
                const calculatedMarginRem = (adjustedValue / range) * maxRem;
                return `${maxRem - calculatedMarginRem}rem`;
            }},
            0 ${props=>{
                const maxRem = (props.rows - 2) * 2;
                const range = props.maxvalue - props.minvalue;
                const adjustedValue = props.maxvalue - props.value;
                const calculatedMarginRem = (adjustedValue / range) * maxRem;
                return `${maxRem - calculatedMarginRem}rem`;
            }});
        background-color: ${props=>props.color}; 
        opacity: 0.3;
        height: 100%;
        width: 100%;
        transform: scaleY(-1);
    }
`

export const GraphicPin = styled.div`
    position: absolute;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.25rem;
    background-color: ${props=>props.color};
    z-index: 2;
`;





