import { useDispatch } from "react-redux";
import api from "./api";
import { LocalClientToken } from "../utils/local-client-token";

export const responseStatus = {
    SUCCESS: 'SUCCESS',
    NOT_FOUND: 'NOT_FOUND',
    ERROR: 'ERROR'
};


export function useHandleRequest() {
    const dispatch = useDispatch();

    async function handleRequest(apiEndpoint, method, data, errorMessage, onUploadProgress, timeout) {
        try {
            let response;
            let config = {};

            if (data instanceof FormData) {
                config = {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress,
                    timeout: timeout || 5000
                };
            }

            switch (method.toUpperCase()) {
                case 'GET':
                    response = await api.get(apiEndpoint);
                    break;
                case 'POST':
                    response = await api.post(apiEndpoint, data, config);
                    break;
                case 'PUT':
                    response = await api.put(apiEndpoint, data, config);
                    break;
                case 'DELETE':
                    response = await api.delete(apiEndpoint);
                    break;
                default:
                    throw new Error('Invalid HTTP method');
            }

            if (response.status >= 200 && response.status < 300) {
                return {
                    status: responseStatus.SUCCESS,
                    data: response.data,
                };
            } else {
                throw response;
            }

        } catch (err) {
            const error = err?.response?.data;
            if(error?.error === "Token inválido ou expirado!"){
                LocalClientToken.clearStoredToken();
                window.location.reload();
            }
            let messageError = error?.error === 'Error' ? error.message : 'Ocorreu um erro inesperado';
            // dispatch(setToast({ message: messageError, type: 'ERROR' }));

            return {
                status: err.response.status === 404 ? responseStatus.NOT_FOUND : responseStatus.ERROR,
                data: errorMessage ? errorMessage : err,
            };
        }
    }

    return {
        handleRequest,
        responseStatus
    };
}
