const INITIAL_STATE = {
    showLabels : false,
    zoom: 16,
    minZoom: 17
};

export default function map(state = INITIAL_STATE, action){
    if(action.type === "SET_SHOW_LABELS")
        return {...state, showLabels : action.showLabels};

    if(action.type === "SET_ZOOM")
        return {...state, zoom : action.zoom};
    return state;
}