import { FontGreyDarkMedium, FontGreyDarkRegular } from "../../../utils/typografyApp";
import { GraphicBar, GraphicMaxBar, GraphicStatusColumnContiner, GraphicContent, GraphicItem, Legend} from "./styled";

const GraphicStatusColumn = ({title, data, config, legend})=>{
    return(
        <GraphicStatusColumnContiner>
            <FontGreyDarkMedium>{title}</FontGreyDarkMedium>

            <GraphicContent>
                {
                    data.values.map((item, index)=>(
                        <GraphicItem key={`graphic-item-${index}`}>
                            <FontGreyDarkRegular>{parseFloat(item/1000).toFixed(2)}mi</FontGreyDarkRegular>
                            <GraphicMaxBar className="bar-config">
                                <GraphicBar color={config[index].color} className={`bar-config`} height={item} total={data?.total}/>
                            </GraphicMaxBar>
                        </GraphicItem> 
                    ))
                }
            </GraphicContent>
            <Legend>
                <FontGreyDarkRegular>{legend.label}</FontGreyDarkRegular>
                <FontGreyDarkMedium>{parseFloat(legend.value).toFixed(2)}</FontGreyDarkMedium>
            </Legend>
        </GraphicStatusColumnContiner>
    );
}

export default GraphicStatusColumn;