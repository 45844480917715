import { FontButtonGreyLightMedium } from '../../../utils/typografyApp';
import { Button } from './styled';

const PrimaryButton = ({children, onClick, disabled, type})=>{
    return(
        <Button 
            onClick={onClick} 
            disabled={disabled}
            type={type}
        >   
            <FontButtonGreyLightMedium>{children}</FontButtonGreyLightMedium>
        </Button>
    );
}

export default PrimaryButton;