import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
`;

export const NewUserForm = styled.div`
    .form-input{
        width: 320px;
        display: flex;
        flex-direction: column;
        gap: 22px;
    }
    form{
        display: flex;
        flex-direction: column;
        gap: 22px;

        .profilePic{
            cursor : pointer;
            img{
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 9px;
            }   
        }
    }
`;