import styled from 'styled-components';

export const MenuItemContainer = styled.div`
    display: flex;
    width: ${props => props.active === "true" ? '3.5rem' : '3rem'};
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    border-radius: 0.5rem 0 0 0.5rem;
    background: ${props => props.active === "true" ? 'var(--white-color)' : 'var(--grey-dark-2-color)'};

    img{
        ${props=> props.active !== "true" ?
            `
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
                width: 40%;
            ` :
            `width: 45%`
        }
    }

    &:hover{
        background-color: var(--white-color);
    }



`;