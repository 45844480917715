import styled from "styled-components";

export const NotificationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    height: 19rem;
    margin-right: ${props=>props.showDashboard === "true" && "7rem"};
    margin-top: 6.5rem;
    padding: 0.5rem;
    border-radius: 1rem;    
    gap: 0.25rem;
    width: 25rem;
    overflow-y: scroll;

`;

export const ArrowCard = styled.div`
    position: absolute;
    top: 11.3rem;
    left: 26rem;
    width: 0;
    height: 0;
    border-top: 20px solid transparent; 
    border-bottom: 20px solid transparent; 
    border-left: 15px solid var(--white-color); 
    transform: translateY(-50%); 
    z-index: 300;
`;

export const MessageCard = styled.div`
    display: flex;
    padding: 0.5rem 1rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--grey-light-color);
    
`;

export const NotitificationTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 0.5rem;
`;

export const NotificationTimeContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0.5rem;

`;

export const NotificationMessageContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 0.5rem;
`;