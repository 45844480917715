import { MenuItemContainer } from "./styled";

const MenuItem = ({title, icon, activateMenuItem, onClick})=>{
    return(
        <MenuItemContainer active={activateMenuItem ? "true" : "false"} onClick={onClick}>
            <img src={icon} alt={`Icone de ${title}`}/>
        </MenuItemContainer>
    );
}

export default MenuItem;