import { FontGreyDarkMediumTitle } from '../../../../utils/typografyApp';
import CardGraphic from '../../CardGraphic';
import GraphicAcum from '../../GraphicAcum';
import GraphicBars from '../../GraphicBars';
import GraphicExpiration from '../../GraphicExpiration';
import { Loading } from '../../Loading';
import { AnalyzeAnticorrosiveContainer, Column, GraphicContainer, JoinedPlanContainer, PaintingPlaneContainer, Row, SCurveContainer } from './styled';

import { useCardReportProdutivityHook } from './hook';


const CardReportProdutivity = () => {


    const hook = useCardReportProdutivityHook();



    return (
        <>
            <GraphicContainer>
                <FontGreyDarkMediumTitle>Analise longo prazo tratamento Anticorrosivo (m²)</FontGreyDarkMediumTitle>
                <AnalyzeAnticorrosiveContainer>
                    <Row>
                        <Column className='long'>
                            <CardGraphic
                                height={200}
                                graphic={
                                    // DueDates linechart

                                    !hook.produtivity.isLoading && hook.produtivity.metricsData.longTermAnalysisOfAntiCorrosionTreatment?.lineChart ?
                                        <GraphicExpiration
                                            grid={true}
                                            label={'analyzeAntirrosive'}
                                            legend={[
                                                { color: "#0C8CE9", label: "Priorização Técnica" },
                                                { color: "#FF4D00", label: "Execução de TAC" },
                                                { color: "#FF0000", label: "Passivo de Pintura m²" },
                                            ]}

                                            config={[
                                                { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "execucaoTAC1" },
                                                { color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento1" },
                                            ]}

                                            months={true}

                                        data={hook.produtivity.metricsData.longTermAnalysisOfAntiCorrosionTreatment?.lineChart}
                                        /> :
                                        <Loading size={30} />
                                }
                            />

                            <CardGraphic
                                height={100}
                                graphic={
                                    // DueDates BarYers
                                    !hook.produtivity.isLoading && hook.produtivity.metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barYears ?
                                        <GraphicBars
                                            config={[
                                                { color: "#0C8CE9", label: "" },
                                                { color: "#FF4D00", label: "" }
                                            ]}

                                        data={hook.produtivity.metricsData?.longTermAnalysisOfAntiCorrosionTreatment?.barYears}
                                        /> :
                                        <Loading size={30} />
                                }
                            />
                        </Column>
                        <Column className='small'>
                            <CardGraphic
                                height={340}
                                graphic={
                                    // DueDatesBarChart
                                    !hook.produtivity.isLoading && hook.produtivity.metricsData.longTermAnalysisOfAntiCorrosionTreatment?.barChart ?
                                        <GraphicAcum
                                            legend={[
                                                { color: "blue", label: "Acum. Programado" },
                                                { color: "red", label: "Acum. Realizado" },
                                                { color: "dashed", label: "Orçamento" },
                                            ]}

                                            data={[
                                                { label: "Acum. Programado", value: hook.produtivity.metricsData.longTermAnalysisOfAntiCorrosionTreatment?.barChart?.accumulatedSheduled ?? 0 },
                                                { label: "Acum. Realizado", value: hook.produtivity.metricsData.longTermAnalysisOfAntiCorrosionTreatment?.barChart?.accumulatedRealized ?? 0},
                                                { label: "Orçamento", value: hook.produtivity.metricsData.longTermAnalysisOfAntiCorrosionTreatment?.barChart?.budget ?? 0 }
                                            ]}
                                        /> :
                                        <Loading />
                                }
                            />

                        </Column>
                    </Row>
                </AnalyzeAnticorrosiveContainer>

                <PaintingPlaneContainer>
                    <FontGreyDarkMediumTitle>Planejamento de pintura anual (m²)</FontGreyDarkMediumTitle>
                    <Row>
                        <Column className='long'>
                            <CardGraphic
                                height={320}
                                graphic={

                                    // PlanoDePinturaAnual lineChart

                                    !hook.produtivity.isLoading && hook.produtivity.metricsData?.annualPaintingPlanMetrics?.lineChart ?
                                        <GraphicExpiration
                                            months={true}
                                            grid={true}
                                            label={'paintingPlane'}
                                            rows={8}
                                            legend={[
                                                { color: "#0C8CE9", label: "Priorização Técnica" },
                                                { color: "#FF4D00", label: "Execução de TAC" },
                                            ]}

                                            config={[
                                                { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "execucaoTAC" },
                                                { color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento" },
                                                // {color: "#0C8CE9", width: 1, dialog: true, lineStyle: "solid", label: "previsao", position: "bottom"}
                                            ]}
                                            data={
                                                hook.produtivity.metricsData?.annualPaintingPlanMetrics?.lineChart
                                            }
                                        // data={[]
                                        //     // anualPaintingPlan.lineChart
                                        // }
                                        /> :
                                        <Loading />
                                }
                            />
                        </Column>
                        <Column className='small'>
                            <CardGraphic
                                height={320}
                                graphic={
                                    !hook.produtivity.isLoading && hook.produtivity.metricsData.annualPaintingPlanMetrics?.barChart
                                        // anualPaintingPlan?.barChart 
                                        ?
                                        <GraphicAcum
                                            legend={[
                                                { color: "blue", label: "Acum. Programado" },
                                                { color: "red", label: "Acum. Realizado" },
                                                { color: "dashed", label: "Orçamento" },
                                            ]}

                                            data={[
                                                {
                                                    label: "Acum. Programado", value: hook.produtivity.metricsData.annualPaintingPlanMetrics?.barChart.accumulatedSheduled ?? 0
                                                },
                                                {
                                                    label: "Acum. Realizado", value: hook.produtivity.metricsData.annualPaintingPlanMetrics?.barChart.accumulatedRealized ?? 0
                                                },
                                                {
                                                    label: "Orçamento", value: hook.produtivity.metricsData.annualPaintingPlanMetrics?.barChart.budget ?? 0
                                                }
                                            ]}
                                        /> :
                                        <Loading />

                                }
                            />
                        </Column>
                    </Row>
                </PaintingPlaneContainer>

                <Row>
                    <SCurveContainer>
                        <FontGreyDarkMediumTitle>Curva S de Avanço</FontGreyDarkMediumTitle>
                        <Row>
                            <Column className='full'>
                                <CardGraphic
                                    height={330}
                                    graphic={
                                        !hook.produtivity.isLoading && hook.produtivity.metricsData.sCurveMetrics?.lineChart ?
                                            // sCurvePlan?.lineChart ?
                                            <GraphicExpiration
                                                months={true}
                                                grid={true}
                                                label={'sCurve'}
                                                rows={8}
                                                legend={[
                                                    { color: "#0C8CE9", label: "Planejado" },
                                                    { color: "#FF4D00", label: "Executado" },
                                                ]}

                                                config={[
                                                    { color: "#0C8CE9", width: 2, dialog: true, lineStyle: "dashed", label: "planejado" },
                                                    { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "ralizado", position: "bottom" },

                                                ]}
                                                data={
                                                    hook.produtivity.metricsData.sCurveMetrics?.lineChart
                                                }
                                            // data={[]
                                            //     // sCurvePlan.lineChart
                                            // }
                                            /> :
                                            <Loading />
                                    }
                                />
                            </Column>
                        </Row>
                    </SCurveContainer>

                    <JoinedPlanContainer>
                        <FontGreyDarkMediumTitle>Aderencia do Plano Anual dos Ativos (m²)</FontGreyDarkMediumTitle>
                        <Row>
                            <Column className='full'>
                                <CardGraphic
                                    height={205}
                                    graphic={
                                        !hook.produtivity.isLoading && hook.produtivity.metricsData.adherenceToTheAnnualPaintingPlanMetrics?.lineChart ?
                                            // adherenceAnualPlan?.lineChart ?
                                            <GraphicExpiration
                                                grid={true}
                                                months={true}
                                                label={'joinedPlan'}
                                                rows={4}
                                                legend={[
                                                    { color: "#FF4D00", label: "Ad - Real / Plan" },
                                                ]}

                                                config={[
                                                    { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "Ad - Real / Plan" },
                                                ]}

                                                data={
                                                    hook.produtivity.metricsData.adherenceToTheAnnualPaintingPlanMetrics?.lineChart
                                                }
                                            /> 
                                            :
                                            <Loading />
                                    }
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column className='full'>
                                <CardGraphic
                                    height={120}
                                    graphic={
                                        !hook.produtivity.isLoading && hook.produtivity.metricsData.adherenceToTheAnnualPaintingPlanMetrics?.barYears ?
                                            // adherenceAnualPlan?.barYears ?
                                            <GraphicBars
                                                config={[
                                                    { color: "#0C8CE9", label: "" },
                                                    { color: "#FF4D00", label: "" }
                                                ]}

                                                legend={[
                                                    { color: "#0C8CE9", label: "Planejado" },
                                                    { color: "#FF4D00", label: "Executado" },
                                                ]}

                                                data={
                                                    hook.produtivity.metricsData.adherenceToTheAnnualPaintingPlanMetrics?.barYears
                                                    // adherenceAnualPlan?.barYears
                                                }
                                            /> :
                                            <Loading />
                                    }
                                />
                            </Column>
                        </Row>
                    </JoinedPlanContainer>
                </Row>
            </GraphicContainer>

        </>
    );
}

export default CardReportProdutivity;
