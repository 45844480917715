import { FontGreyDarkFullRegular, FontGreyDarkMedium, FontGreyDarkRegular, FontGreyDarkSemiBold, FontPoppinsGreyDarkFullRegular, FontPoppinsGreyDarkSmallRegular } from "../../../../utils/typografyApp";
import { ArrowCard, MessageCard, NotificationMessageContainer, NotificationTimeContainer, CalendarContainer, NotitificationTitle, CalendarTitle, CalendarCardContent } from "./styled";
import pin_icon from "../../../../assets/icons/pin.svg";
import arrow_back_icon from "../../../../assets/icons/arrow_back.svg";
import { useCardCalendarHook } from "./hook";
import { StatusTagIcon } from "../../../../utils/iconsApp";
import { Loading } from "../../Loading";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../../utils/storeUtils";

const CardCalendar = ({ showDashboard, setShowConnections, assetId }) => {

    const hook = useCardCalendarHook({ assetId });

    return (
        <>
            <ArrowCard />
            <CalendarContainer showDashboard={showDashboard ? "true" : "false"}>

                <CalendarTitle>
                    <img  src={arrow_back_icon} alt="Icone de voltar" onClick={setShowConnections} />
                    <div className="titleContainer">
                        <FontGreyDarkMedium>Calendário</FontGreyDarkMedium>
                        <FontGreyDarkRegular>
                            <select id="year-select" name="year" defaultValue={hook.form.selectedYear} onChange={(e) => hook.form.setSelectedYear(e.target.value)}>
                                {hook.form.years.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </FontGreyDarkRegular>
                    </div>

                </CalendarTitle>

                <CalendarCardContent>
                    {hook.metrics.isLoading ?
                        <div className="loading"><Loading /></div> :
                        <table>
                            <thead>
                                <th></th>
                                <th>Área total</th>
                                <th>Passivo</th>
                                <th>Priorizado</th>
                                <th>Realizado</th>
                            </thead>
                            <tbody>
                                {hook.metrics.metricsData.map(calendar => (
                                    <tr>
                                        <td>
                                            <div className="title-status">
                                                <img src={StatusTagIcon['ACTIVE']} alt="Tag do status do Ativo" />
                                                <span className="title">{calendar.label}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="item">{parseFloat(calendar.totalArea).toFixed(2)}m²</span>
                                        </td>
                                        <td>
                                            <span className="item">{parseFloat(calendar.passive).toFixed(2)}m²</span>
                                        </td>
                                        <td>
                                            <span className="item">{parseFloat(calendar.prioritized).toFixed(2)}m²</span>
                                        </td>
                                        <td>
                                            <span className="item">{parseFloat(calendar.realized).toFixed(2)}m²</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    }
                </CalendarCardContent>

            </CalendarContainer>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CardCalendar);