import { styled } from 'styled-components';

export const Button = styled.button`
    position: absolute;
    top: 1rem;
    left: 2rem;
    display: flex;
    width: 3.5rem;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.675rem;
    flex-shrink: 0;
    cursor: pointer;
    z-index: 9999;

    border-radius: 0.5rem;
    background-color: var(--white-color) !important;

    box-shadow: var(--box-shadow);


`;