export class LocalClientToken {
    
    static storeToken(access) {
        localStorage.setItem('tauClientAccess', access);
    }

    static clearStoredToken() {
        localStorage.removeItem('tauClientAccess');
    }

    static getStoredToken() {
        return localStorage.getItem('tauClientAccess') ?? '';
    }

}