import { useMutation } from "react-query";
import { usersQueries, useUsersService } from '../../../../services/userService';
import { useForm } from "react-hook-form";
import { userSchema } from "../../../../models/schemas/userSchema";
import { queryClient } from "../../../../utils/query-client";
import { zodResolver } from '@hookform/resolvers/zod';

import base64 from 'base64-encode-file';
import { useState } from "react";

export function useNewUserModalHook({onClose, selectedUser}){
    const userService = useUsersService();

    const [profilePic, setProfilePic] = useState();

    const { mutate, isLoading } = useMutation((payload) => 
        selectedUser ?
        userService.editUsers({payload, onSuccess}) :
        userService.createUsers({ payload, onSuccess })
    );

    const userForm = useForm({ 
        resolver: zodResolver(selectedUser ? userSchema.omit({password: true}) : userSchema), 
        defaultValues: {...selectedUser, password : ''}
    });

    function onSuccess() {
        queryClient.refetchQueries(usersQueries.GET_ALL);
        onClose();
    }

    async function onChangeFile(e){
        const file = e.target.files[0];
        if(file){
            return await base64(file);
        }
    }

    function onSubmit(payload) {
        //TODO: lembrar de trocar o id do client
        mutate({...payload, client: {id : 1}});
    }

    return{
        form: {
            onSubmit,
            onChangeFile,
            userForm,
            profilePic,
            setProfilePic,
            isLoading
        }
    }
}