import styled from "styled-components";

export const GuideMapContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 2rem 0;

    .transparent{
        background-color: var(--grey-disabled-color) !important;
    }
`;

export const GuideMapButton = styled.button`
    display: flex;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    border-radius: 8rem;
    background-color: var(--white-color) !important;
`;

export const ZoomContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
`;