import { useQuery } from 'react-query';
import { useAssetsService, assetsQueries } from '../../../services/assetService';

export function useHomeHook(){
    const assetService = useAssetsService();

    const {
        data : assetsData,
        isLoading : isLoadingAssets,
        isRefetching : isRefetchingAssets,
        refetch : refetchAssets
    } = useQuery(assetsQueries.GET_ALL, ()=>assetService.getAllAssets({search: '', page: 1}));

    return{
        assets: {
            assetsData,
            isLoading: isLoadingAssets || isRefetchingAssets,
            refetchAssets
        }
    }
}

// async upload({setLoading, sheetFile, setSheetFile, setRefresh, refresh}){
//     // setLoading(true);
//     // const formData = new FormData();
//     // formData.append('file', sheetFile);

//     // handleRequest({
//     //     apiEndpoint : "/utils/upload-sheets",
//     //     data: formData,
//     //     method: 'POST'
//     // }).then(response=>{
//     //     const {status, data} = response;
//     //     if(status === responseStatus.SUCCESS){
//     //         alert("Sucesso!");
//     //         document.getElementById("input-sheet").value = null;
//     //         setSheetFile(null);
//     //         setRefresh(!refresh);
//     //     }else{
//     //         alert("Erro ao subir a planilha");
//     //     }
//     // }).finally(()=>{
//     //     setLoading(false);
//     // })
// }

// onChangeFile(e, setSheetFile){
//     const file = e.target.files[0];
//     if (file) {
//         const fileName = file.name?.toLowerCase();
//         if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
//             setSheetFile(file);
//         } else {
//             alert('Formato de arquivo incompatível');
//             document.getElementById('input-sheet').value = null;
//         }
//     }
// }