import styled from "styled-components";

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    height: 19rem;
    margin-right: ${props => props.showDashboard === "true" && "6rem"};
    margin-top: 6.5rem;
    padding: 0.5rem;
    border-radius: 1rem;    
    gap: 0.25rem;
    width: 700px;
    overflow-y: scroll;

`;

export const CalendarTitle = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 8px;

    img{
        cursor: pointer;
    }

    .titleContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        width: 100%;

        select{
            padding: 4px 8px;
            border-radius: 8px;
            background: var(--Base-Grey-light, #E6E4E0);
            border: none;

        }
    }
`;

export const ArrowCard = styled.div`
    position: absolute;
    top: 11.3rem;
    left: 715px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent; 
    border-bottom: 20px solid transparent; 
    border-left: 15px solid var(--white-color); 
    transform: translateY(-50%); 
    z-index: 300;
`;

export const CalendarCardContent = styled.div`
    width: 100%;
    max-height: 250px;
    overflow: scroll;
    padding-left: 8px;

    .loading{
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
   
    
    table{
        width: 100%;

        .title{
            font-weight: bold;
        }

        .title-status{
            display: flex;
            align-items: center;
            gap:12px;
        }

        .item{
            display: flex;
            padding: 8px 12px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 2px;
            border-radius: 8px;
            border: 1px solid var(--Base-Grey-light, #E6E4E0);
            background: var(--Base-Background, #FFF);
        }
        
        th{
            color: var(--Base-Grey-medium-Hover, #878787) !important;
            text-align: start;
            font-family: Ubuntu;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
        }

        tr{
            color: var(--Base-Grey-dark, #292929);
            font-family: Ubuntu;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }
`;