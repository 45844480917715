import { responseStatus, useHandleRequest } from "../service/api-services";
import {getDefaultQuery } from "../utils/query-client";

export const assetsQueries = {
    EDIT: "ASSETS_EDIT",
    CREATE: "ASSETS_CREATE",
    DELETE: "ASSETS_DELETE",
    GET_ALL: "ASSETS_GET_ALL",
    GET_BY_ID: "ASSETS_GET_BY_ID",
}


export function useAssetsService() {

    const handleRequest = useHandleRequest();

    async function createAssets({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/assets`, 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editAssets({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/assets/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteAssets({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/assets/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllAssets({ search, page }){
        const filters = [{search}];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/assets/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAssetsById({ id }){
        const { status, data } = await handleRequest.handleRequest(`/assets/${id}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    return {
        createAssets,
        editAssets,
        deleteAssets,
        getAllAssets,
        getAssetsById,
    }
}
