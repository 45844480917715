import { z } from "zod";
import { generalMessageError } from "./config";

export const userSchema = z.object({
    id: z.union([
        z.string(),
        z.number()
    ]).optional(),
    name: z.string().min(1, { message: generalMessageError }),
    cpf: z.string().min(11, { message: generalMessageError }),
    email: z.string().email({ message: 'Digite um email válido' }).min(1, { message: generalMessageError }),
    password: z.string().min(1, { message: generalMessageError }).min(6, { message: 'A senha deve conter pelo menos 6 caracteres' }),
});