import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export function useInputHook({ name, setSearch }) {
    const formContext = useFormContext();
    const error = formContext?.formState.errors[name]?.message;
    const [value, setValue] = useState('');

    const safeRegister = (name) => {
        return formContext?.register ? formContext?.register(name) : { name };
    };

    function handleChangeValue(e){
        setValue(e.target.value);
    }

    useEffect(() => {
        if (setSearch) {
            const handler = setTimeout(() => {
                setSearch(value);
            }, 1000); 

            return () => {
                clearTimeout(handler);
            };
        }
    }, [value, setSearch]);

    return {
        form: {
            safeRegister,
            handleChangeValue,
            error
        },
    };
}
