import { styled } from 'styled-components';
import base_map_image from "../../../assets/base_map.png";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${base_map_image});
  background-size: cover;
  background-position: center;
`;

export const Content = styled.div`
  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    height: 15rem;
    gap: 1rem;
  }
 
`;

export const Inputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

`;
