import { FontInputGreyDarkRegular } from "../../../utils/typografyApp";
import { useInputHook } from "./hook";
import { InputContainer } from "./styled";

const Input = ({type, placeholder, name, icon, className, setSearch, disabled})=>{
    const hook = useInputHook({name, setSearch});
    return(
        <InputContainer error={hook.form.error} className={className}>
            { icon && <img src={icon} alt="Icone prefixo no input"/> }
            <FontInputGreyDarkRegular className="input">
                {type === 'date' && <span>{placeholder}</span>}
                <input disabled={disabled} type={type} placeholder={placeholder} {...hook.form.safeRegister(name)} onChange={hook.form.handleChangeValue}/>
            </FontInputGreyDarkRegular>
        </InputContainer>
    );
}

export default Input;