import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
`;

export const NewAsset3DForm = styled.div`
    .span{
        width: 100%;
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
   
`;