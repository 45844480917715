import { ModalMain } from "../styled";
import { FontGreyDarkMedium, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { NewAsset3DForm } from "./styled";
import { useBulkSection3DModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const BulkSection3DModal = ({ onClose }) => {
    const hook = useBulkSection3DModalHook({ onClose });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <NewAsset3DForm>
                    <label htmlFor="zip-file">
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Cadastro de 3D em Massa</FontGreyDarkMediumTitle>
                                </div>
                                {hook.form.isLoading ? (
                                    <FontGreyDarkMedium>
                                        <span className="span">Carregando: {hook.form.uploadProgress}%</span>
                                    </FontGreyDarkMedium>
                                ) : (
                                    <FontGreyDarkMedium>
                                        <span className="span">Selecione um arquivo zip contendo os STL</span>
                                    </FontGreyDarkMedium>
                                )}
                                <input id="zip-file" type="file" hidden accept=".zip" onChange={hook.form.handleUpload} />
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                            </div>
                        </div>
                    </label>
                </NewAsset3DForm>
            </FormProvider>
        </ModalMain>
    );
}

export default BulkSection3DModal;
