import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const metricsQueries = {
    GET_ALL: "METRICS_GET_ALL",
    GET_BY_ID: "METRICS_GET_BY_ID",
    GET_BY_SECTION_ID: "METRICS_GET_BY_SECTION_ID",
    GET_BY_CLIENT: "METRICS_GET_BY_CLIENT",
    CALENDAR: "METRICS_CALENDAR",
    GET_SINGLE_BY_CLIENT: "METRICS_GET_SINGLE_BY_CLIENT",
    GET_PRODUTIVITY_BY_CLIENT: "METRICS_GET_PRODUTIVITY_BY_CLIENT",
    GET_FINANCIAL_BY_CLIENT: "METRICS_GET_FINANCIAL_BY_CLIENT",
    GET_ASSETS_GENERAL_BY_CLIENT: "METRICS_GET_ASSETS_GENERAL_BY_CLIENT",
    GET_ASSETS_RENOVATION_BY_CLIENT: "METRICS_GET_ASSETS_RENOVATION_BY_CLIENT",
    GET_ASSETS_DEFECT_TYPES_BY_CLIENT: "METRICS_GET_ASSETS_DEFECT_TYPES_BY_CLIENT",
    GET_ASSETS_MATURITY_BY_CLIENT: "METRICS_GET_ASSETS_MATURITY_BY_CLIENT",
    GET_ASSETS_DUE_DEGRADATION_BY_CLIENT: "METRICS_GET_ASSETS_DUE_DEGRADATION_BY_CLIENT"
}


export function useMetricsService() {

    const handleRequest = useHandleRequest();


    async function getAllMetrics({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/metrics/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAllByClient({ clientId }) {
        if (clientId) {
            const { status, data } = await handleRequest.handleRequest(`/metrics/single/by/client/${clientId}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    async function getMetricsById({ id, fkSectionId }) {
        if (id) {
            const filters = [{ fkSectionId }];
            const query = getDefaultQuery({ filters });
            const { status, data } = await handleRequest.handleRequest(`/metrics/single/by/asset/${id}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    async function getCalendar({ fkAssetId, year }) {
        const filters = [{ year }];
        const query = getDefaultQuery({ filters });
        const { status, data } = await handleRequest.handleRequest(`/metrics/calendar/by/asset/${fkAssetId}${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }


    // Metricas de dos ativos (Gerais)
    async function getAssetMetricsGeneralById({ fkClientId }){
        if(fkClientId){
            const filters = [];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/assets/general/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }


    // Metricas de dos ativos (Renovações)
    async function getAssetsMetricsRenovationById({ fkClientId }){
        if(fkClientId){
            const filters = [];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/assets/renovations/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }
    
    // Metricas de dos ativos (Tipos de defeito)
    async function getAssetsMetricsDefectTypesById({ fkClientId }){
        if(fkClientId){
            const filters = [];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/assets/defect-types/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }




    // Metricas de dos ativos (Maturidade)
    async function getAssetsMetricsMaturityById({ fkClientId, order = 'ASC' }){
        if(fkClientId){
            const filters = [order];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/assets/maturity/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }
    
    // Metricas de dos ativos (Degradação e Venciemnto)
    async function getAssetsMetricsDueDegradationById({ fkClientId }){
        if(fkClientId){
            const filters = [];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/assets/due-degradation/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }






    // Metricas de produtividade,
    async function getProductivityMetricsById({ fkClientId, fkSectionId, fkAssetId, fkUnitId }){
        if(fkClientId){
            const filters = [{fkSectionId, fkAssetId, fkUnitId}];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/produtivity/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }



    // Metricas de financiamento,
    async function getFinancialMetricsById({ fkClientId, fkSectionId, fkAssetId, fkUnitId }){
        if(fkClientId){
            const filters = [{fkSectionId, fkAssetId, fkUnitId}];
            const query = getDefaultQuery({filters});
            const { status, data } = await handleRequest.handleRequest(`/metrics/financial/by/client/${fkClientId}/${query}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    async function getSingleByClient({ fkClientId }){
        if(fkClientId){
            const { status, data } = await handleRequest.handleRequest(`/metrics/single/by/client/${fkClientId}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }




    return {
        getAllMetrics,
        getMetricsById,
        getAllByClient,
        getCalendar,
        getSingleByClient,
        getProductivityMetricsById,
        getFinancialMetricsById,
        getAssetMetricsGeneralById,
        getAssetsMetricsRenovationById,
        getAssetsMetricsDefectTypesById,
        getAssetsMetricsMaturityById,
        getAssetsMetricsDueDegradationById
    }
}
