import { useQuery } from 'react-query';
import { metricsQueries, useMetricsService } from '../../../../services/metricsService';

export function useCardReportFinancialHook(){
    const assetService = useMetricsService();

    const {
        data : metricsData,
        isLoading : isLoadingMetrics,
        isRefetching : isRefetchingMetrics,
        refetch : refetchAssets
    } = useQuery(metricsQueries.GET_FINANCIAL_BY_CLIENT, ()=>assetService.getFinancialMetricsById({
       
       
        // TODO Colocar dinamico
        fkClientId: 1
   
   
    }));

    return{
        financial: {
            metricsData,
            isLoading: isLoadingMetrics || isRefetchingMetrics,
            refetchAssets
        }
    }
}

// async upload({setLoading, sheetFile, setSheetFile, setRefresh, refresh}){
//     // setLoading(true);
//     // const formData = new FormData();
//     // formData.append('file', sheetFile);

//     // handleRequest({
//     //     apiEndpoint : "/utils/upload-sheets",
//     //     data: formData,
//     //     method: 'POST'
//     // }).then(response=>{
//     //     const {status, data} = response;
//     //     if(status === responseStatus.SUCCESS){
//     //         alert("Sucesso!");
//     //         document.getElementById("input-sheet").value = null;
//     //         setSheetFile(null);
//     //         setRefresh(!refresh);
//     //     }else{
//     //         alert("Erro ao subir a planilha");
//     //     }
//     // }).finally(()=>{
//     //     setLoading(false);
//     // })
// }

// onChangeFile(e, setSheetFile){
//     const file = e.target.files[0];
//     if (file) {
//         const fileName = file.name?.toLowerCase();
//         if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
//             setSheetFile(file);
//         } else {
//             alert('Formato de arquivo incompatível');
//             document.getElementById('input-sheet').value = null;
//         }
//     }
// }