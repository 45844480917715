import styled from "styled-components";

export const GraphicStatusColumnContiner = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-light-2-color);
    border-radius: .5rem;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
`

export const GraphicContent = styled.div`
    display: flex;
    gap: 1rem;
    
`;

export const GraphicItem = styled.div`
    display: flex;
    width: 2rem;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;

    .bar-config{
        width: 1rem;
        border-radius: 0.125rem;
    }
`

export const GraphicMaxBar = styled.div`
    position: relative;
    width: 2rem !important;
    height: 7rem !important;
    flex-shrink: 0;
    border-radius: 0.3rem !important;
    background: var(--grey-light-2-color);    
`;

export const GraphicBar = styled.div`
    width: 100% !important;
    height: ${(props) => {
        const maxPx = 112;
        const calculatedHeightPx = maxPx * props.height / props.total;
        return `${calculatedHeightPx}px`;
    }};
    position: absolute;
    bottom: 0;
    background-color: ${props=>props.color};
    border-radius: 0.3rem !important;
`;

export const Legend = styled.div`
    background-color: var(--grey-light-2-color);
    width: 100%;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 24px;
    align-items: center;

`;
