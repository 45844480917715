import { useMutation } from "react-query";
import { queryClient } from "../../../../utils/query-client";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../services/asset3DItemService";

export function useDeleteAsset3DItemModalHook({onClose, selectedAsset3DItem}){
    const asset3DItemService = useAssets3DItemService();

    const { mutate, isLoading } = useMutation((payload) => asset3DItemService.deleteAssets3DItem({id : selectedAsset3DItem.id, onSuccess}));

    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
        onClose();
    }

    function onSubmit() {
        mutate();
    }

    return{
        form: {
            onSubmit,
            isLoading
        }
    }
}