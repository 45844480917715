import { GuideMapButton, GuideMapContainer, ZoomContainer } from "./styled";
import eye_icon from "../../../assets/icons/eye.svg";
import eye_close_icon from "../../../assets/icons/eye_close.svg";
import zoom_in_icon from "../../../assets/icons/zoom_in.svg";
import zoom_out_icon from "../../../assets/icons/zoom_out.svg";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
const GuideMap = ({map, setShowLabels, setZoom})=>{
    return(
        <GuideMapContainer>

            <GuideMapButton onClick={()=>setShowLabels(!map.showLabels)}>
                <img src={map.showLabels ? eye_close_icon : eye_icon} alt="Icone de olho"/>
            </GuideMapButton>

            <ZoomContainer>
                <GuideMapButton title="Zoom in" onClick={()=>setZoom(map.zoom+1)}>
                    <img src={zoom_in_icon} alt="Icone Zoom In"/>
                </GuideMapButton>

                <GuideMapButton className={map.zoom <= map.minZoom && "transparent"}  title="Zoom out" onClick={()=>setZoom(map.zoom-1)} disabled={map.zoom <= map.minZoom}>
                    <img src={zoom_out_icon} alt="Icone Zoom out"/>
                </GuideMapButton>
            </ZoomContainer>

        </GuideMapContainer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideMap);