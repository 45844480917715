import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { MapConfig } from "../../../utils/mapConfig";

export function useMapHook({selectedMarker}){
    const [googleMap, setGoogleMap] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: -20.288550, lng: -40.240104 });

    const [infoWindow, setInfoWindow] = useState({
        isOpen: false,
        content: null,
        position: null,
    });

    const { isLoaded } = useJsApiLoader({
        id: MapConfig.mapId,
        googleMapsApiKey: MapConfig.googleMapsApiKey,
    });

    useEffect(()=>{
        if(googleMap){
            setTimeout(()=>{
                if(selectedMarker?.latStart){
                    googleMap?.setZoom(3);
                }
            }, 300)
        }
    }, [googleMap, selectedMarker])

    function fromLatLngToPixel(){
        if(selectedMarker && googleMap && isLoaded){
            var latLng = {lat: selectedMarker?.latStart, lng : selectedMarker?.lngStart};
            var projection = googleMap?.getProjection();
            var bounds = googleMap?.getBounds();
            var topRight = projection?.fromLatLngToPoint(bounds?.getNorthEast());
            var bottomLeft = projection?.fromLatLngToPoint(bounds?.getSouthWest());
            var scale = Math.pow(2, googleMap?.getZoom());
            var worldPoint = projection?.fromLatLngToPoint(latLng);
            return { x: Math.floor((worldPoint?.x - bottomLeft?.x) * scale), y: Math.floor((worldPoint?.y - topRight?.y) * scale)};
        }
    }

    const highlightOverlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        zIndex: 2,
        backgroundImage: `radial-gradient(circle closest-side at ${fromLatLngToPixel()?.x + 24}px ${fromLatLngToPixel()?.y-7}px, transparent 0%, transparent ${40}px, rgba(0, 0, 0, 0.5) ${40}px)`,
        maskSize: 'cover',
    };

    return{
        map: {
            isLoaded,
            mapCenter,
            googleMap,
            setGoogleMap,
            setInfoWindow,
            infoWindow,
            highlightOverlayStyle
        }
    }

}