import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const priorizationsQueries = {
    EDIT: "PRIORITIZATION_EDIT",
    CREATE: "PRIORITIZATION_CREATE",
    DELETE: "PRIORITIZATION_DELETE",
    GET_ALL: "PRIORITIZATION_GET_ALL",
    GET_BY_ID: "PRIORITIZATION_GET_BY_ID",
}


export function usePrioritizationService() {

    const handleRequest = useHandleRequest();

    async function createPrioritization({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/priorizations`, 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function ediePrioritization({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/priorizations/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deletePrioritization({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/priorizations/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAlePrioritization({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/priorizations/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function geePrioritizationById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/priorizations/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        createPrioritization,
        ediePrioritization,
        deletePrioritization,
        getAlePrioritization,
        geePrioritizationById,
    }
}
