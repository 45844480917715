import { FontGreyDarkFullRegular, FontGreyDarkMediumTitle, FontGreyDarkBold, FontRedBold } from "../../../utils/typografyApp";
import { TopContainer, AnomalieInfoWindowContainer, VerticalItem, HorizontalItem, GridContainer, ImagesContainer } from "./styled";
import { useNavigate } from "react-router-dom";
import SwitchButton from "../SwitchButton";
import  { formatDate } from 'date-fns';
import { baseUrl } from "../../../service/api";

const AnomalieInfoWindow = ({anomaly})=>{
    const navigate = useNavigate();
    return (
        <AnomalieInfoWindowContainer>
            <TopContainer>
                <FontGreyDarkMediumTitle>Registro de Anomalias</FontGreyDarkMediumTitle>                    
            </TopContainer>

            <HorizontalItem>
                <FontGreyDarkFullRegular>Área Degradada</FontGreyDarkFullRegular>
                <FontGreyDarkMediumTitle>{anomaly.area}m²</FontGreyDarkMediumTitle>
            </HorizontalItem> 

            <GridContainer>
                <VerticalItem>
                    <FontGreyDarkFullRegular>ID</FontGreyDarkFullRegular>
                    <FontGreyDarkBold>#{anomaly.internalId}</FontGreyDarkBold>
                </VerticalItem> 
                <VerticalItem>
                    <FontGreyDarkFullRegular>Data</FontGreyDarkFullRegular>
                    <FontGreyDarkBold>{formatDate(anomaly.date, 'dd/MM/yyyy')}</FontGreyDarkBold>
                </VerticalItem> 
                <VerticalItem>
                    <FontGreyDarkBold>{anomaly.defectType.name}</FontGreyDarkBold>
                </VerticalItem> 
                <VerticalItem>
                    <FontRedBold>{anomaly.degradationDegree.name}</FontRedBold>
                </VerticalItem> 
            </GridContainer> 

            <HorizontalItem>
                <FontGreyDarkFullRegular>Falha Prematura</FontGreyDarkFullRegular>
                <SwitchButton active={anomaly.prematureFailure}/>
            </HorizontalItem>

            <ImagesContainer>
                {anomaly.medias?.map(media=>(
                    <img src={`${baseUrl}/${media.path}`} alt="Imagem de uma anomalia cadastrada"/>
                ))}
            </ImagesContainer>
        </AnomalieInfoWindowContainer>
    );                              
}

export default AnomalieInfoWindow;