import { useState } from "react";
import Guide from "../../../components/Guide";
import { ExitContainer, HomePageContainer, HomePageHeader, TabItem, TabSelect } from "./styled";
import { FontGreyDarkRegular, FontGreyMediumRegular, FontInputGreyDarkRegular } from "../../../../utils/typografyApp";

import exit_icon from '../../../../assets/icons/exit.svg';
import { TabUser } from "./TabUsers";
import { Tab3D } from "./Tab3D";
import { TabAssets } from "./TabAssets";

export const AdmHomePage = () => {
    const tabItems = ['Usuários', 'Ativos', '3D'];
    
    const [selectedTab, setSelectedTab] = useState('Usuários');
    
    return (
        <HomePageContainer>
            <HomePageHeader>
                <Guide page='Usuários' />

                <TabSelect>
                    {tabItems.map(item => (
                        <FontGreyMediumRegular>
                            <TabItem onClick={() => setSelectedTab(item)} active={item === selectedTab ? "true" : "false"}>
                                {item}
                            </TabItem>
                        </FontGreyMediumRegular>
                    ))}
                </TabSelect>

                <ExitContainer>
                    <img src={exit_icon} alt="Icone de sair"/>
                    <FontInputGreyDarkRegular>Sair</FontInputGreyDarkRegular>
                </ExitContainer>
            </HomePageHeader>

            {selectedTab === tabItems[0] && <TabUser/> }
            {selectedTab === tabItems[1] && <TabAssets/> }
            {selectedTab === tabItems[2] && <Tab3D/> }            

        </HomePageContainer>
    );
}