const INITIAL_STATE = {
    showDashboard: true,
    showReports: false,
    showNotifications: false,
    showConnections: false,
    showDegradations: false,
    type : "MAP-PAGE" 
};

export default function menu(state = INITIAL_STATE, action){
    if(action.type === "SET_SHOW_DASHBOARD")
        return {...state, showDashboard : true, showReports: false, showNotifications: false, showConnections: false, showDegradations: false};

    if(action.type === "SET_SHOW_REPORTS")
        return {...state, showDashboard: false, showReports: true, showNotifications: false, showConnections: false, showDegradations: false };

    if(action.type === "SET_SHOW_NOTIFICATIONS")
        return {...state, showDashboard: false, showReports: false, showNotifications: true, showConnections: false, showDegradations: false  
    //  showNotifications: !state.showNotifications
     }; 

    if(action.type === "SET_SHOW_DEGRADATIONS")
        return {...state, showDashboard: false, showReports: false, showNotifications: false, showConnections: false, showDegradations: true };

    if(action.type === "SET_SHOW_CONNECTIONS")
        return {...state, showConnections: !state.showConnections }; 

    if(action.type === "SET_TYPE")
        return {...state, type: action.menuType }; 

    return state;
}
