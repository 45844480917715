import { FontGreyDarkRegular, FontGreyDarkSemiBold } from "../../../../utils/typografyApp";
import { CardContainer, ValueContainer, TitleContainer } from "./styled";

const CardInformation = ({title, value, icon})=>{
    return (
        <CardContainer>
            <TitleContainer className="card-item">
                <FontGreyDarkRegular>{title}</FontGreyDarkRegular>
            </TitleContainer>
            <ValueContainer className="card-item">
                <img src={icon} alt="Icone para ilustrar o conteúdo"/>
                <FontGreyDarkSemiBold>{value}</FontGreyDarkSemiBold>
            </ValueContainer>
        </CardContainer>
    );
}

export default CardInformation;