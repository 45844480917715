import { FontGreyDarkSemiMedium } from "../../../utils/typografyApp";
import Utils from "../../../utils/utils";
import { GraphicContainer, GraphicItem, GraphicBar, GraphicMaxBar } from "./styled";

const GraphicBarsClass = ({data})=>{
    return(
        <GraphicContainer>
            {data.map(item=>(
                <GraphicItem>
                    <FontGreyDarkSemiMedium className="left-label">{item.label}</FontGreyDarkSemiMedium>
                    <GraphicMaxBar className="bar-config">
                        <GraphicBar className="bar-config red" width={item.value} total={item.total}/>
                        <FontGreyDarkSemiMedium>{`${parseFloat(Utils.formatNumberWithCommas(item.value || "")).toFixed(2)} m²`}</FontGreyDarkSemiMedium>
                    </GraphicMaxBar>
                </GraphicItem>            
            ))}
            
        </GraphicContainer>
    );
}

export default GraphicBarsClass;