import { Main } from "../../main-styled";
import { AssetContainer, PageContainer, SVGContainer } from "./styled";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../utils/storeUtils";
import BackButton from "../../components/BackButton";
import Dashboard from "../../components/Dashboard";
import { useAssetHook } from "./hook";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Loading } from "../../components/Loading";
import CustomInfoWindow from "../../components/CustomInfoWindow";
import SectionInfoWindow from '../../components/SectionInfoWindow';
import { FontGreyDarkRegular } from "../../../utils/typografyApp";
import { downloadAndExtractZip } from "../../../service/indexedDB";
import download_icon from '../../../assets/icons/download.svg';
import { checkCache } from "../../../utils/functions";

const AssetPage = ({ menu, setMenuType, setShowDashboard }) => {
    const hook = useAssetHook({ menu, setMenuType, setShowDashboard });

    return (
        <Main>
            <PageContainer>
                <AssetContainer>
                    <SVGContainer>
                        {hook.asset?.assetData?.reference3d?.asset3d?.id === hook.general.isDownloading ? (
                            <div className="download progress">
                                <img src={download_icon} alt="Icone de Download" />
                                <FontGreyDarkRegular>
                                    <div className="download-text">
                                        Baixando: {hook.general.downloadProgress}%
                                    </div>
                                </FontGreyDarkRegular>
                            </div>
                        ) : checkCache(hook.asset?.assetData?.reference3d?.asset3d) || hook.asset.isLoading ? (
                            <div className="_3d">
                                {hook.asset.isLoading && (
                                    <div className="progress-bar">
                                        <Loading />
                                        <FontGreyDarkRegular><div className="progress-text"> Carregando: {Math.round(hook.config3d.progress)}%</div></FontGreyDarkRegular>
                                    </div>
                                )}
                                <Canvas>
                                    <ambientLight intensity={0.5} />
                                    <directionalLight
                                        position={[20, 10, 10]}
                                        castShadow
                                        shadow-mapSize-width={1024}
                                        shadow-mapSize-height={1024}
                                        shadow-camera-far={50}
                                        shadow-camera-left={-10}
                                        shadow-camera-right={10}
                                        shadow-camera-top={10}
                                        shadow-camera-bottom={-10}
                                    />
                                    <PerspectiveCamera ref={hook.config3d.camera} makeDefault position={[-2, 1, 1]} />
                                    <OrbitControls ref={hook.config3d.orbitControls} />
                                    {hook.config3d.objects?.map((object, index) => (
                                        object.object ?
                                            <primitive
                                                className="click"
                                                key={index}
                                                object={object.object}
                                                onPointerOver={() => hook.config3d.handlePointerOver(index)}
                                                onPointerOut={() => hook.config3d.handlePointerOut(index)}
                                                onClick={(e) => hook.general.handleShowInfoWindow(e, object.section)}
                                            /> :
                                            <></>
                                    ))}
                                </Canvas>
                            </div>
                        ) : (
                            <div className="download" onClick={(e) => downloadAndExtractZip(e, hook.asset.assetData?.reference3d?.asset3d, hook.general.setDownloadProgress, hook.general.setIsDownloading, hook.asset.refetchAsset)}>
                                <img src={download_icon} alt="Icone de Download" />
                                <FontGreyDarkRegular><div className="download-text">Baixar modelo 3D</div></FontGreyDarkRegular>
                            </div>
                        )}



                        {hook.general.sectionWindow.isOpen &&
                            <CustomInfoWindow
                                onClose={() => hook.general.setSectionWindow({ ...hook.general.sectionWindow, isOpen: false })}
                                position={hook.general.sectionWindow.position}
                                height={370}
                                width={222}
                            >
                                <SectionInfoWindow section={hook.general.sectionWindow.content} />
                            </CustomInfoWindow>
                        }
                    </SVGContainer>
                </AssetContainer>

                <BackButton onClick={hook.general.handleBackButton} />
                <Dashboard asset={hook.asset.assetData} />
            </PageContainer>
        </Main>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetPage);