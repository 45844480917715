import { styled } from 'styled-components';

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props=>props.disabled ? 'var(--grey-medium-color)' : 'var(--primary-color)' }  !important;
    padding: 0.5rem 1rem !important;
    border-radius: 0.5rem;
    margin: 0.5rem 0.25rem;
    width: 9rem;
    height: 2.2rem;
    border: none;
    outline: none;
    cursor: pointer;

    span{
        display: flex;
        align-items: center;
        justify-content: center;
        
        img{
            margin-right: 0.5rem;
        }
    }

    
   
`;