class Utils{
    static formatNumberWithCommas(number) {
        const parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join('.');
    }

    static rotatePoint(angleDegrees) {
      const angleRadians = (angleDegrees * Math.PI) / 180;

      const newX =
        26 +
        (26 - 26) * Math.cos(angleRadians) -
        (52 - 26) * Math.sin(angleRadians);
    
      const newY =
        26 +
        (26 - 26) * Math.sin(angleRadians) +
        (52 - 26) * Math.cos(angleRadians);
      return { x: newX, y: newY };
    }

    static minAndMaxValueOfList(data){ 
      let min = data[0][0];
      let max = data[0][0];
      
      for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].length; j++) {
          if (data[i][j] < min) {
              min = data[i][j];
          }
          if (data[i][j] > max) {
              max = data[i][j];
          }
          }
      }
      
      return { min, max };
  }

  static clear3DObjects(objects, setObjects){
    objects?.forEach(object => {
        if (object?.object?.geometry) object?.object?.geometry?.dispose();
        if (object?.object?.material) object?.object?.material?.dispose();
        if (object?.object?.parent) object?.object?.parent?.remove(object.object);
    });
    setObjects([]);
}
}

export default Utils;

