import styled from "styled-components";


export const SectionInfoWindowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    align-items: start;

    .img-asset{
        position: relative;
        height: 7rem;
        width: 14rem;
        object-fit: cover;
        margin: 0;
    }

    .local_3d{
        height: 7rem;
        width: 14rem;
    }

`;

export const ActionsContainer = styled.div`
    position: absolute;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 14rem;
`;