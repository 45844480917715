import { ModalMain } from "../styled";
import plus_black_icon from "../../../../assets/icons/plus_black.svg"
import anomalie_icon from "../../../../assets/icons/anomalie.svg"
import execution_icon from "../../../../assets/icons/execution.svg"
import priorization_icon from "../../../../assets/icons/priorization.png"
import {ButtonRegister, ButtonsContainer} from "./styled";
import { FontGreyDarkBold, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import { useState } from "react";
import AnomalieModal from "../AnomalieModal";
import ExecutionModal from "../ExecutionModal";
import PriorizationModal from "../PriorizationModal";

const NewRegisterModal=({onClose, setOpacity, section})=>{
    const [showMenu, setShowMenu] = useState(true);
    const [showAnomalieModal, setShowAnomalieModal] = useState(false);
    const [showExecutionModal, setShowExecutionModal] = useState(false);
    const [showPriorizationModal, setShowPriorizationModal] = useState(false);

    return(
        <ModalMain>
            { showAnomalieModal && <AnomalieModal section={section} setOpacity={setOpacity} onClose={()=>{
                setShowAnomalieModal(false);
                setShowMenu(true);
            }}/>}

            { showExecutionModal && <ExecutionModal section={section} setOpacity={setOpacity} onClose={()=>{
                setShowExecutionModal(false);
                setShowMenu(true);
            }}/>}

            { showPriorizationModal && <PriorizationModal section={section} setOpacity={setOpacity} onClose={()=>{
                setShowPriorizationModal(false);
                setShowMenu(true);
            }}/>}

            <div className={!showMenu ? "hidden-content" : "menu-content"}>
                <div className="content">
                    <div className="title">
                        <FontGreyDarkMediumTitle>Novo Registro</FontGreyDarkMediumTitle>
                        <img src={plus_black_icon} alt="Icone de adicionar"/>
                    </div>
                    <ButtonsContainer>
                        <ButtonRegister onClick={()=>{
                            setShowAnomalieModal(true);
                            setShowMenu(false);
                        }}>
                            <img src={anomalie_icon} alt="Icone de Anomalia"/>
                            <FontGreyDarkBold>Anomalia</FontGreyDarkBold>
                        </ButtonRegister>

                        <ButtonRegister onClick={()=>{
                            setShowExecutionModal(true);
                            setShowMenu(false);
                        }}>
                            <img src={execution_icon} alt="Icone de Execução"/>
                            <FontGreyDarkBold>Execução</FontGreyDarkBold>
                        </ButtonRegister>

                        <ButtonRegister
                            onClick={()=>{
                                setShowPriorizationModal(true);
                                setShowMenu(false);
                            }}
                        >
                            <img src={priorization_icon} alt="Icone de Priorização"/>
                            <FontGreyDarkBold>Priorização</FontGreyDarkBold>
                        </ButtonRegister>
                    </ButtonsContainer>
                </div>
                <div className="actions-container">
                    <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
                </div>
            </div>
        </ModalMain>
    );
}

export default NewRegisterModal;