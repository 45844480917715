import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
`;

export const DeleteSection3DForm = styled.div`

`;