import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

export const FETCH_LIMIT_TABLE = 10;

export function getOffset(page) {
    return (page - 1) * FETCH_LIMIT_TABLE;
}

export function getDefaultQuery({page, filters}) {
    let query = '';
    console.log('Filters',filters)
    const formattedFilters = filters?.map(filter => {
        const key = Object.keys(filter)[0];
        const value = filter[key];
        return value && `${key}=${value}`;
    }).filter(Boolean);

    query += formattedFilters?.join('&');

    if (page) {
        const offset = filters ? filters.length === 0 ? '' : '&' : '?';
        query += `${offset}offset=${getOffset(page)}&limit=${FETCH_LIMIT_TABLE}`;
    }

    if (query) {
        query = `?${query}`;
    }

    return query;
}