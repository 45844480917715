import { useQuery } from "react-query";
import { usersQueries, useUsersService } from "../../../../../services/userService"
import { useEffect, useState } from "react";

export function useTabUsersHook(){
    const userService = useUsersService();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [search, setSearch] = useState('');

    const {
        data: userData,
        isLoading: isLoadingUser,
        isRefetching: isRefetchingUser,
        refetch: refetchUser
        //TODO: trocar a paginacao
    } = useQuery(usersQueries.GET_ALL, () => userService.getAllUsers({page: 1, name: search}));

    function handleSelectUser(e, user){
        e.stopPropagation();
        setSelectedUser(user);
        setShowNewUserModal(true);
    }

    function handleCloseModal(){
        setSelectedUser(null);
        setShowNewUserModal(false);
    }

    useEffect(()=>{
        refetchUser();
    }, [search]);

    return{
        users: {
            userData,
            isLoading: isLoadingUser || isRefetchingUser,
            refetchUser,
            selectedUser,
            handleSelectUser,
            
        },
        general: {
            showNewUserModal, 
            setShowNewUserModal,
            colSpan: 5,
            search,
            setSearch,
            handleCloseModal
        }
    }
}