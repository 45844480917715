import { FontGreyDarkMediumTitle } from '../../../../utils/typografyApp';
import CardGraphic from '../../CardGraphic';
import GraphicAcum from '../../GraphicAcum';
import GraphicBars from '../../GraphicBars';
import GraphicExpiration from '../../GraphicExpiration';
import { Loading } from '../../Loading';
import { Column, GraphicContainer, JoinedPlanContainer, OpexContainer, PaintCostsContainer, Row, SCurveContainer } from './styled';

import { useCardReportFinancialHook } from './hook';


const CardReportFinacial = () => {


    const hook = useCardReportFinancialHook();



    return (
        <>
            <GraphicContainer>
                <FontGreyDarkMediumTitle>Opex - Planejamento Anual de Gastos</FontGreyDarkMediumTitle>
                <OpexContainer>
                    <Row>
                        <Column className='long'>

                            <CardGraphic
                                graphic={

                                    !hook.financial.isLoading && hook.financial.metricsData.annualBillingPlanMetrics?.lineChart ?

                                        <GraphicExpiration
                                            grid={true}
                                            rows={8}
                                            months={true}
                                            label={'opex'}
                                            legend={[
                                                { color: "#0C8CE9", label: "Priorização Técnica" },
                                                { color: "#FF4D00", label: "Execução" },
                                                { color: "#FF0000", label: "Orçamento" },
                                            ]}

                                            config={[
                                                { color: "#0C8CE9", width: 1, dialog: true, lineStyle: "solid", label: "priorizacao1" },
                                                { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "execucaoTAC1", position: "bottom" },
                                                { color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento1" },
                                            ]}

                                            data={hook.financial.metricsData.annualBillingPlanMetrics?.lineChart}

                                        // data={[
                                        //     { label: "jan", values: [1800, 1700, 2600] },
                                        //     { label: "fev", values: [1300, 800, 2600] },
                                        //     { label: "mar", values: [2300, 1700, 2600] },
                                        //     { label: "abr", values: [1750, 1500, 2600] },
                                        //     { label: "mai", values: [3240, 2400, 2600] },
                                        //     { label: "jun", values: [4160, 3360, 2600] },
                                        //     { label: "jul", values: [7800, 5000, 2600] },
                                        //     { label: "ago", values: [5400, 3200, 2600] },
                                        //     { label: "set", values: [5000, 4000, 2600] },
                                        //     { label: "out", values: [1600, 800, 2600] },
                                        //     { label: "nov", values: [2120, 1500, 2600] },
                                        //     { label: "dez", values: [3100, 2300, 2600] },
                                        // ]}
                                        /> :
                                        <Loading size={30} />
                                }
                            />
                        </Column>
                        <Column className='small'>
                            <CardGraphic
                                graphic={

                                    !hook.financial.isLoading && hook.financial.metricsData.annualBillingPlanMetrics?.barChart ?

                                        <GraphicAcum
                                            legend={[
                                                { color: "blue", label: "Acum. Programado" },
                                                { color: "red", label: "Acum. Realizado" },
                                                { color: "dashed", label: "Orçamento" },
                                            ]}

                                            data={[
                                                { value: hook.financial.metricsData.annualBillingPlanMetrics?.barChart.accumulatedPrioritized ?? 0 },
                                                { value: hook.financial.metricsData.annualBillingPlanMetrics?.barChart.accumulatedExecuted ?? 0 },
                                                { value: hook.financial.metricsData.annualBillingPlanMetrics?.barChart.budget ?? 0 }
                                            ]}
                                        // data={[
                                        //     { value: 110000 },
                                        //     { value: 96000 },
                                        //     { value: 120000 }
                                        // ]}
                                        /> :
                                        <Loading size={30} />}
                            />

                        </Column>
                    </Row>
                </OpexContainer>
            </GraphicContainer>

            <GraphicContainer>
                <FontGreyDarkMediumTitle>Gastos em pintura (R$ milhões)</FontGreyDarkMediumTitle>
                <PaintCostsContainer>
                    <Row>
                        <Column className='full'>
                            <CardGraphic
                                graphic={

                                    !hook.financial.isLoading && hook.financial.metricsData.spendOnPaintingPlanMetrics?.barYears ?


                                        <GraphicBars
                                            scale={1000000}
                                            spaceWidth={4}

                                            config={[
                                                { color: "#0C8CE9", label: "" },
                                                { color: "#FF4D00", label: "" }
                                            ]}

                                            legend={[
                                                { color: "#0C8CE9", label: "Planejado" },
                                                { color: "#FF4D00", label: "Executado" },
                                            ]}

                                            data={hook.financial.metricsData.spendOnPaintingPlanMetrics?.barYears}
                                        // data={[
                                        //     { label: 2012, values: [8000000, 11000000] },
                                        //     { label: 2013, values: [12000000, 15000000] },
                                        //     { label: 2014, values: [15000000, 18000000] },
                                        //     { label: 2015, values: [18000000, 21000000] },
                                        //     { label: 2016, values: [23000000, 26000000] },
                                        //     { label: 2017, values: [25000000, 28000000] },
                                        //     { label: 2018, values: [28000000, 31000000] },
                                        //     { label: 2019, values: [31000000, 34000000] },
                                        //     { label: 2020, values: [34000000, 37000000] },
                                        //     { label: 2021, values: [34000000, 38000000] },
                                        //     { label: 2022, values: [38000000, 40000000] },
                                        // ]}
                                        /> :
                                        <Loading size={30} />}
                            />
                        </Column>
                    </Row>
                </PaintCostsContainer>
            </GraphicContainer>

            <GraphicContainer>
                <FontGreyDarkMediumTitle>Eficiência de execução - R$ X m²</FontGreyDarkMediumTitle>
                <PaintCostsContainer>
                    <Row>
                        <Column className='full'>
                            <CardGraphic
                                graphic={

                                    !hook.financial.isLoading && hook.financial.metricsData.executionEfficiencyAndEffectiveProductivityMetrics?.lineChart ?


                                        <GraphicExpiration
                                            grid={true}
                                            rows={5}
                                            months={true}
                                            label={'executionEfficiency'}
                                            legend={[
                                                { color: "#FF4D00", label: "Real" },
                                                { color: "#878787", label: "Orçamento", },
                                            ]}

                                            config={[
                                                { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "real" },
                                                { color: "#878787", width: 2, dialog: false, lineStyle: "dashed", label: "orcamento2" },
                                            ]}

                                            data={hook.financial.metricsData.executionEfficiencyAndEffectiveProductivityMetrics?.lineChart}
                                        // data={[
                                        //     { label: "jan", values: [225000, 245000] },
                                        //     { label: "fev", values: [250000, 245000] },
                                        //     { label: "mar", values: [225000, 245000] },
                                        //     { label: "abr", values: [260000, 245000] },
                                        //     { label: "mai", values: [225000, 245000] },
                                        //     { label: "jun", values: [260000, 245000] },
                                        //     { label: "jul", values: [225000, 245000] },
                                        //     { label: "ago", values: [250000, 245000] },
                                        //     { label: "set", values: [260000, 245000] },
                                        //     { label: "out", values: [225000, 245000] },
                                        //     { label: "nov", values: [250000, 245000] },
                                        //     { label: "dez", values: [260000, 245000] },
                                        // ]}
                                        /> :
                                        <Loading size={30} />
                                }
                            />
                        </Column>
                    </Row>
                </PaintCostsContainer>
            </GraphicContainer>

            <GraphicContainer>
                <FontGreyDarkMediumTitle>Produtividade efeito (m²/H. dia)</FontGreyDarkMediumTitle>
                <PaintCostsContainer>
                    <Row>
                        <Column className='full'>
                            <CardGraphic
                                graphic={

                                    !hook.financial.isLoading && hook.financial.metricsData.executionEfficiencyAndEffectiveProductivityMetrics?.barYears ?


                                        <GraphicBars
                                            spaceWidth={4}

                                            config={[
                                                { color: "#0C8CE9", label: "" },
                                                { color: "#FF4D00", label: "" }
                                            ]}

                                            legend={[
                                                { color: "#0C8CE9", label: "Planejado" },
                                                { color: "#FF4D00", label: "Executado" },
                                            ]}

                                            data={
                                                hook.financial.metricsData.executionEfficiencyAndEffectiveProductivityMetrics?.barYears
                                            }

                                        // data={[
                                        //     { label: 2012, values: [14000, 9500] },
                                        //     { label: 2013, values: [13000, 10000] },
                                        //     { label: 2014, values: [14000, 11000] },
                                        //     { label: 2015, values: [15000, 12000] },
                                        //     { label: 2016, values: [10000, 12000] },
                                        //     { label: 2017, values: [12000, 11000] },
                                        //     { label: 2018, values: [12000, 10000] },
                                        //     { label: 2019, values: [12000, 9000] },
                                        //     { label: 2020, values: [10000, 9000] },
                                        //     { label: 2021, values: [12000, 8500] },
                                        //     { label: 2022, values: [12000, 8500] },
                                        // ]}
                                        /> :
                                        <Loading size={30} />}
                            />
                        </Column>
                    </Row>
                </PaintCostsContainer>
            </GraphicContainer>
            <GraphicContainer>
                <Row>
                    <JoinedPlanContainer>
                        <FontGreyDarkMediumTitle>Eficiência de execução - R$ X m²</FontGreyDarkMediumTitle>
                        <Row>
                            <Column className='full'>
                                <CardGraphic
                                    graphic={

                                        !hook.financial.isLoading && hook.financial.metricsData.annualExecutionEfficiencyAndEffectiveProductivityMetrics.efficiencyLineChart ?

                                            <GraphicExpiration
                                                grid={true}
                                                label={'exectionEficiency'}
                                                rows={4}
                                                legend={[
                                                    { color: "#FF4D00", label: "Realizado" },
                                                ]}

                                                config={[
                                                    { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "Realizado" },
                                                ]}

                                                data={hook.financial.metricsData.annualExecutionEfficiencyAndEffectiveProductivityMetrics.efficiencyLineChart}

                                            // data={[
                                            //     { label: 2012, values: [90000] },
                                            //     { label: 2013, values: [87000] },
                                            //     { label: 2014, values: [84000] },
                                            //     { label: 2015, values: [81000] },
                                            //     { label: 2016, values: [78000] },
                                            //     { label: 2017, values: [75000] },
                                            //     { label: 2018, values: [120000] },
                                            //     { label: 2019, values: [139000] },
                                            //     { label: 2020, values: [145000] },
                                            //     { label: 2021, values: [180000] },
                                            //     { label: 2022, values: [190000] },
                                            //     { label: 2023, values: [203000] },
                                            // ]}
                                            /> :
                                            <Loading size={30} />}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column className='full'>
                                <CardGraphic
                                    graphic={

                                        !hook.financial.isLoading && hook.financial.metricsData.annualExecutionEfficiencyAndEffectiveProductivityMetrics.barYears  ?

                                            <GraphicBars

                                                config={[
                                                    { color: "#FF4D00", label: "Realizado" },
                                                ]}

                                                legend={[
                                                    { color: "#FF4D00", label: "Executado" },
                                                ]}

                                                data={hook.financial.metricsData.annualExecutionEfficiencyAndEffectiveProductivityMetrics?.barYears }

                                                // data={[
                                                //     { label: 2012, values: [90000] },
                                                //     { label: 2013, values: [87000] },
                                                //     { label: 2014, values: [84000] },
                                                //     { label: 2015, values: [81000] },
                                                //     { label: 2016, values: [78000] },
                                                //     { label: 2017, values: [75000] },
                                                //     { label: 2018, values: [120000] },
                                                //     { label: 2019, values: [139000] },
                                                //     { label: 2020, values: [145000] },
                                                //     { label: 2021, values: [180000] },
                                                //     { label: 2022, values: [190000] },
                                                // ]}
                                            /> :
                                            <Loading size={30} />}
                                />
                            </Column>
                        </Row>
                    </JoinedPlanContainer>

                    <SCurveContainer>
                        <FontGreyDarkMediumTitle>Produtividade Efetivo - m² X Hora por dia</FontGreyDarkMediumTitle>
                        <Row>
                            <Column className='full'>
                                <CardGraphic
                                    graphic={

                                        !hook.financial.isLoading && hook.financial.metricsData.executionEfficiencyAndEffectiveProductivityMetrics?.lineChart ?

                                            <GraphicExpiration
                                                grid={true}
                                                months={true}
                                                label={'sCurve'}
                                                rows={8}
                                                legend={[
                                                    { color: "#FF4D00", label: "Série 1" },
                                                    { color: "#0C8CE9", label: "Série 2" },

                                                ]}

                                                config={[
                                                    { color: "#FF4D00", width: 1, dialog: true, lineStyle: "solid", label: "serie1" },
                                                    { color: "#0C8CE9", width: 2, dialog: false, lineStyle: "solid", label: "serie2" },
                                                ]}

                                                data={hook.financial.metricsData.executionEfficiencyAndEffectiveProductivityMetrics?.lineChart}

                                                // data={[
                                                //     { label: "jan", values: [10800, 8000] },
                                                //     { label: "fev", values: [8600, 8000] },
                                                //     { label: "mar", values: [11700, 8000] },
                                                //     { label: "abr", values: [12140, 8000] },
                                                //     { label: "mai", values: [11060, 8000] },
                                                //     { label: "jun", values: [11000, 8000] },
                                                //     { label: "jul", values: [8000, 8000] },
                                                //     { label: "ago", values: [7500, 8000] },
                                                //     { label: "set", values: [6000, 8000] },
                                                //     { label: "out", values: [8000, 8000] },
                                                //     { label: "nov", values: [10100, 8000] },
                                                //     { label: "dez", values: [4710, 8000] },
                                                // ]}
                                            /> :
                                            <Loading size={30} />}
                                />
                            </Column>
                        </Row>
                    </SCurveContainer>
                </Row>
            </GraphicContainer>
        </>
    );
}

export default CardReportFinacial;
