import AdmButton from "../../../../components/AdmButton"
import { TabUsersContainer, TabUsersFilters } from "./styled"
import add_icon from '../../../../../assets/icons/add.svg';
import search_icon from '../../../../../assets/icons/search.svg';
import { FontGreyDarkBold } from "../../../../../utils/typografyApp";
import Input from "../../../../components/Input";
import NewUserModal from '../../../../components/Modals/NewUserModal';
import { useTabUsersHook } from "./hook";
import { Loading } from "../../../../components/Loading";
import edit_icon from '../../../../../assets/icons/edit.svg';

export const TabUser = () => {
    const hook = useTabUsersHook();
    return (
        <TabUsersContainer>
            <FontGreyDarkBold>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Empresa</th>
                            <th>Email</th>
                            <th>Senha</th>
                            <th></th>
                        </tr>

                    </thead>

                    <tbody>
                        <tr>
                            <td colSpan={hook.general.colSpan}>
                                <TabUsersFilters>
                                    <Input className={'search'} icon={search_icon} placeholder={'Buscar usuário'} setSearch={hook.general.setSearch}/>
                                    <AdmButton onClick={() => { hook.general.setShowNewUserModal(true) }}>
                                        <img src={add_icon} alt="Iocne de adicionar" />
                                        Novo Usuário
                                    </AdmButton>
                                </TabUsersFilters>
                            </td>
                        </tr>

                        {hook.users.userData?.count === 0 || hook.users.isLoading ?
                            <tr>
                                <td colSpan={hook.general.colSpan} className="empty-table">
                                    {hook.users.isLoading ?
                                        <Loading /> :
                                        <div>Nenhum usuário cadastrado</div>
                                    }
                                </td>
                            </tr> :
                            <>
                                {hook.users.userData?.rows?.map(user => (
                                    <tr className="click">
                                        
                                        <td>{user.name}</td>
                                        <td>{user.client.name}</td>
                                        <td>{user.email}</td>
                                        <td>*****</td>
                                        <td><img src={edit_icon} alt="Icone de editar" onClick={(e)=>hook.users.handleSelectUser(e, user)} /></td>
                                    </tr>
                                ))}

                            </>
                        }

                    </tbody>
                </table>
            </FontGreyDarkBold>

            {hook.general.showNewUserModal &&
                <NewUserModal selectedUser={hook.users.selectedUser} onClose={hook.general.handleCloseModal} />
            }
        </TabUsersContainer>
    )
}