import { useEffect, useState } from "react";
import { metricsQueries, useMetricsService } from "../../../../services/metricsService";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

export function useCardCalendarHook(){
    
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 25;
    const endYear = currentYear + 25;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const metricsService = useMetricsService();
    const {id: fkAssetId} = useParams();
    const dispatch = useDispatch();

    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    
    const {
        data : metricsData,
        isLoading : isLoadingMetrics,
        isRefetching : isRefetchingMetrics,
        refetch : refetchMetrics
    } = useQuery(metricsQueries.CALENDAR, ()=>metricsService.getCalendar({fkAssetId, year: selectedYear}));


    useEffect(()=>{
        refetchMetrics();
    }, [selectedYear]);
    
    return {
        metrics: {
            metricsData,
            isLoading: isLoadingMetrics || isRefetchingMetrics,
            refetchMetrics
        },
        form: {
            years,
            selectedYear,
            setSelectedYear,
        }
    }
}