import { useEffect, useRef, useState } from "react";
import { sectionsQueries, useSectionsService } from "../../../../services/sectionService";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';

import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import Utils from "../../../../utils/utils";
import { baseUrl } from "../../../../service/api";
import { queryClient } from "../../../../utils/query-client";
import { metricsQueries } from "../../../../services/metricsService";
import { priorizationSchema } from "../../../../models/schemas/priorizationSchema";
import { usePrioritizationService } from "../../../../services/prioritizationService";
import { blobToArrayBuffer } from "../../../../utils/functions";
import { getFromIndexedDB, saveToIndexedDB } from "../../../../service/indexedDB";
import axios from "axios";

export function usePriorizationHook({ section, setOpacity, onClose }) {
    const tabs = ['FORM', 'SELECTAREA', 'SUCCESS'];
    const [tab, setTab] = useState(tabs[0]);
    const [refresh, setRefresh] = useState(true);
    const sectionService = useSectionsService();
    const prioritizationService = usePrioritizationService();
    const orbitControls = useRef();
    const [highlightedObject, setHighlightedObject] = useState(null);
    const colors = ['#00ff00', '#ff0000', '#00ff00', '#ff0000'];
    const lineMaterial = new THREE.LineBasicMaterial({ color: '#ceffce', linewidth: 1 });
    const [objects, setObjects] = useState([]);
    const [sphere, setSphere] = useState(null);
    const [progress, setProgress] = useState(0);
    const  [size, setSize] = useState();
    const camera = useRef();
    
    const { mutate, isLoading } = useMutation((payload) => prioritizationService.createPrioritization({ payload, onSuccess }));
    const priorizationForm = useForm({ resolver: zodResolver(priorizationSchema) });

    const watchIsPrioritization = priorizationForm.watch('isPrioritized');
    const watchFkSectionId = priorizationForm.watch('fkSectionsId');
    const watchArea = priorizationForm.watch('area');

    const {
        data: sectionsData,
        isLoading: isLoadingSections,
        isRefetching: isRefetchingSections,
        refetch: refetchSections
    } = useQuery(sectionsQueries.GET_ALL_OPTIONS, () => sectionService.getAllSectionsOptions({ search: '', page: 1 }));

    const {
        data: selectedSection,
        isLoading: isLoadingSelectedSection,
        isRefetching: isRefetchingSelectedSection,
        refetch: refetchSelectedSection
    } = useQuery(sectionsQueries.GET_BY_ID, () => sectionService.getSectionsById({ id: watchFkSectionId || section.id }));


    const sectionOptions = section ? [{ key: section.id, value: section.name }] : sectionsData;

    function onSuccess() {
        setTab(tabs[2]);
        queryClient.refetchQueries(metricsQueries.GET_BY_SECTION_ID);
        queryClient.refetchQueries(sectionsQueries.GET_BY_ID);
        setTimeout(()=>{
            onClose();
        }, 3000);
     }

    function handleSelectIsPriotized() {
        priorizationForm.setValue('isPrioritized', !watchIsPrioritization);
    }

    function validateForm() {
        const requiredFields = ["fkSectionsId", "internalId", "date", "area", "totalBilling", "activityDurationDaysEffective",  "totalArea"];
        const isValid = requiredFields.every(field => priorizationForm.getValues(field));
        if (isValid) {
            setTab(tabs[1]);
            setOpacity(true);
        } else {
            priorizationForm.trigger(requiredFields);
        }
    }

    function onSubmit(payload) {
        mutate(payload);
    }

    function handleObjectClick(event) {
        const intersects = event.intersections;
        if (intersects?.length > 0) {
            const intersect = intersects[0];
            const { point } = intersect;
            if (sphere) {
                sphere.position.copy(point);
                priorizationForm.setValue('position', JSON.stringify(sphere.position));
            } else {
                const objectArea = size.x * size.y; // Área do objeto

                console.log(objectArea)
                                        
                const sphereRadius = 100; // Raio da esfera baseado na área da anomalia
                const scaleFactorRadius = sphereRadius / Math.sqrt(objectArea / Math.PI); // Fator de escala baseado na área do objeto

                const sphereGeometry = new THREE.SphereGeometry(scaleFactorRadius, 32, 32);
                const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff, transparent: true, opacity: 0.5 });
                const newSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                newSphere.position.copy(point);


                setObjects((prev) => {
                    if (prev.find(object => object.section === `sphere${prev.length - 1}`)) {
                        return prev;
                    }
                    setSphere(newSphere);
                    return [...prev, { section: `sphere${prev.length}`, object: newSphere }];
                });
                priorizationForm.setValue('position', JSON.stringify(newSphere.position));
            }
        }
    }

    useEffect(() => {
        if (selectedSection?.reference3d) {

            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (selectedSection?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const cacheKey = `${selectedSection.reference3d?.section3d?.token}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey);

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${selectedSection?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer]));
                        }

                        if (arrayBuffer) {

                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            let size = new THREE.Vector3()
                            setSize(size);
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            tempObjects.push(
                                { section: selectedSection.reference3d.section3d, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${selectedSection?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [selectedSection]);

    useEffect(() => {
        if (watchFkSectionId && tab === tabs[1]) refetchSelectedSection();
    }, [watchFkSectionId, tab]);

    return {
        form: {
            mutate,
            isLoading,
            priorizationForm,
            onSubmit,
            validateForm,
            watchIsPrioritization,
            handleSelectIsPriotized
        },
        general: {
            tab,
            tabs,
            setTab
        },
        selectedSection: {
            selectedSection,
            isLoading: isLoadingSelectedSection || isRefetchingSelectedSection,
            refetchSelectedSection
        },
        sections: {
            refetchSections,
            isLoading: isLoadingSections || isRefetchingSections,
            sectionOptions
        },
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            colors,
            handleObjectClick,
            sphere,
            camera,
            progress,
            isLoading: refresh
            
        }
    };
}
