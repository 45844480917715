import { SwitchButtonContainer } from "./styled"

const SwitchButton = ({toggleSelect, active})=>{
    return(
        <SwitchButtonContainer onClick={toggleSelect} active={active}>

        </SwitchButtonContainer>
    );
}

export default SwitchButton;