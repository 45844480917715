import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
`;

export const NewAssetForm = styled.div`

    form{
        display: flex;
        flex-direction: column;
        gap: 22px;

        .rot{
            width: 50px;
        }

        .map{
            display: flex;
            flex-direction: row;
            gap: 10px;

            img{
                cursor: pointer;
            }
        }

        .row{
            display: flex;
            flex-direction: row;
            gap: 22px;
        }

        .tag{
            width: 150px;
        }

    }
`;