import { FontInputGreyDarkRegular } from "../../../utils/typografyApp";
import { OptionsContainer, SelectContainer, CustomSelect, OptionItem } from "./styled";
import arrow_down_icon from "../../../assets/icons/arrow_down.svg";
import { useSelectHook } from "./hook";

const Select = ({ placeholder, name, className, options, defaultValue }) => {
    const hook = useSelectHook({ name });

    return (
        <SelectContainer error={hook.form.error} className={className}>
            <FontInputGreyDarkRegular>
                <span>{hook.selectedOption?.toString()}</span>
                <CustomSelect onClick={() => hook.general.setShowOptions(!hook.general.showOptions)}>
                    <span>{hook.form.selectedOption?.value || defaultValue || placeholder}</span>
                    <img src={arrow_down_icon} alt="Icone seta" />

                </CustomSelect>
                <input
                    type="hidden"
                    value={hook.form.watchedValue || ''}
                    {...hook.form.register(name)}
                />
                {hook.general.showOptions &&
                    <OptionsContainer>
                        {
                            options?.map(option => (
                                <OptionItem onClick={() => hook.general.toggleOption(option)}>{option?.value}</OptionItem>
                            ))
                        }

                    </OptionsContainer>
                }
            </FontInputGreyDarkRegular>
        </SelectContainer>
    );
}

export default Select;