import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const sectionsQueries = {
    EDIT: "SECTIONS_EDIT",
    CREATE: "SECTIONS_CREATE",
    DELETE: "SECTIONS_DELETE",
    GET_ALL: "SECTIONS_GET_ALL",
    GET_ALL_OPTIONS: "SECTIONS_GET_ALL_OPTIONS",
    GET_BY_ID: "SECTIONS_GET_BY_ID",
}


export function useSectionsService() {

    const handleRequest = useHandleRequest();

    async function createSections({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/sections/${payload.id}`, 'POST');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editSections({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/sections/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteSections({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/sections/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllSections({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/sections/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAllSectionsOptions({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/sections/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data.rows.map(data=>({key: data.id, value: data.name}));
        }
    }

    async function getSectionsById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/sections/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        createSections,
        editSections,
        deleteSections,
        getAllSections,
        getSectionsById,
        getAllSectionsOptions
    }
}
