import { ModalMain } from "../styled";
import { FontGreyDarkFullBold, FontGreyDarkFullRegular, FontGreyDarkFullTitle, FontGreyDarkMediumTitle, FontGreyDarkSemiMedium, FontGreyMediumRegular } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { DeleteSection3DForm } from "./styled";
import Input from "../../Input";
import { useDeleteSection3DItemModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const DeleteSection3DModal = ({ onClose, selectedSection3DItem, selectedAsset3DItem }) => {

    const hook = useDeleteSection3DItemModalHook({ onClose, selectedSection3DItem, selectedAsset3DItem });

    return (
        <ModalMain>
            <FormProvider {...hook.form.asset3DItemForm}>
                <DeleteSection3DForm>
                    
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Deletar 3D da Sessão</FontGreyDarkMediumTitle>
                                </div>
                                <FontGreyDarkFullRegular>Tem certeza que deseja excluir o modelo 3D da sessão <FontGreyDarkFullBold> {selectedSection3DItem.name} - {selectedSection3DItem.tag}</FontGreyDarkFullBold>?</FontGreyDarkFullRegular>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.onSubmit}> {hook.form.isLoading ? 'Excluindo...' : 'Excluir'} </PrimaryButton>
                            </div>
                        </div>
            
                </DeleteSection3DForm>
            </FormProvider>
        </ModalMain>
    );
}

export default DeleteSection3DModal;