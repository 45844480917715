import { styled } from 'styled-components';

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    border: 0.0625rem solid ${props=>props.error ? 'var(--red-color)' : 'var(--grey-light-2-color)'} ;
    background-color: var(--white-color);
    border-radius: 0.5rem;
    padding:  0.75rem 1rem;
    width: 100%;
    gap: 0.625rem;

    .input{
        display: flex;
        gap: 4px;

        span{
            white-space: nowrap;
        }

        input{
            color: #999;
        }
    }

    div{
        width: 100%;
    }
    
    input{
        border: none;
        background-color: var(--grey-white-color);
        width: 100%;
        outline: none;
    }
`;