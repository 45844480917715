import { responseStatus, useHandleRequest } from "../service/api-services";

export function useAuthService(){

    const handleRequest = useHandleRequest();

    async function login({payload, onSuccess}){
        const {data, status} = await handleRequest.handleRequest('/auth/token/request', 'POST', payload);
        if(status === responseStatus.SUCCESS){
            return onSuccess(data);
        }
    }

    async function loginAdm({payload, onSuccess}){
        const {data, status} = await handleRequest.handleRequest('/auth/token/request-admin', 'POST', payload);
        if(status === responseStatus.SUCCESS){
            return onSuccess(data);
        }
    }

    return {
        login,
        loginAdm
    }
}