import { handleRequest, responseStatus } from "../../../../service/api-services";

export class CardReportsController{
    async getAllAssets(setLoading, setAssets){
        setLoading(true);

        // handleRequest({
        //     apiEndpoint : "/assets",
        //     method: 'GET'
        // }).then(response=>{
        //     const {status, data} = response;
        //     if(status === responseStatus.SUCCESS){
        //         setAssets(data);
        //     }else{
        //         alert("Erro ao buscar os ativos");
        //     }
        // }).finally(()=>{
        //     setLoading(false);
        // });
    }

    async getAllConveyors(setLoading, setConveyors){
        setLoading(true);

        // handleRequest({
        //     apiEndpoint : "/conveyors",
        //     method: 'GET'
        // }).then(response=>{
        //     const {status, data} = response;
        //     if(status === responseStatus.SUCCESS){
        //         setConveyors(data);
        //     }else{
        //         alert("Erro ao buscar os transportadores");
        //     }
        // }).finally(()=>{
        //     setLoading(false);
        // });
    }

    async getDueDates(setLoading, setDueDatesData){
        setLoading(true);

        // handleRequest({
        //     apiEndpoint : "/assets-conveyors-metrics/due-dates/by/client/1",
        //     method: 'GET'
        // }).then(response=>{
        //     const {status, data} = response;
        //     if(status === responseStatus.SUCCESS){
        //         setDueDatesData(data);
        //     }else{
        //         alert("Erro ao buscar os dados de vencimento");
        //     }
        // }).finally(()=>{
        //     setLoading(false);
        // });
    }
    
    async getAnualPaintingPlan(setLoading, setAnualPaintingPlan){
        setLoading(true);

        // handleRequest({
        //     apiEndpoint : "/assets-conveyors-metrics/annual-painting-plan/by/client/1",
        //     method: 'GET'
        // }).then(response=>{
        //     const {status, data} = response;
        //     if(status === responseStatus.SUCCESS){
        //         setAnualPaintingPlan(data);
        //     }else{
        //         alert("Erro ao buscar os dados de vencimento");
        //     }
        // }).finally(()=>{
        //     setLoading(false);
        // });
    }

    async getSCurvePlan(setLoading, setSCurvePlan){
        setLoading(true);

        // handleRequest({
        //     apiEndpoint : "/assets-conveyors-metrics/s-curve-plan/by/client/1",
        //     method: 'GET'
        // }).then(response=>{
        //     const {status, data} = response;
        //     if(status === responseStatus.SUCCESS){
        //         setSCurvePlan(data);
        //     }else{
        //         alert("Erro ao buscar os dados de vencimento");
        //     }
        // }).finally(()=>{
        //     setLoading(false);
        // });
    }
    
    async getAdherenceAnualPlan(setLoading, setAdherenceAnualPlan){
        setLoading(true);

        // handleRequest({
        //     apiEndpoint : "/assets-conveyors-metrics/adherence-annual-painting-plan/by/client/1",
        //     method: 'GET'
        // }).then(response=>{
        //     const {status, data} = response;
        //     if(status === responseStatus.SUCCESS){
        //         if(data.lineChart){
        //             const newLineChart = []
        //             data.lineChart.map(line=>{
        //                 line.values = [line.values[0]];
        //                 newLineChart.push(line);
        //             });
        //             data.lineChart = newLineChart;
        //         }

        //         if(data.barYears){
        //             data.barYears = data.barYears.slice(-6);
        //         }

                
        //         setAdherenceAnualPlan(data);
        //     }else{
        //         alert("Erro ao buscar os dados de vencimento");
        //     }
        // }).finally(()=>{
        //     setLoading(false);
        // });
    }


}