import React, { useEffect, useState } from "react";
import LineTo from "react-lineto";
import { GraphicContainer, GraphicPanel, GraphicContent, ItemBackGraphic, GraphicFooter, GraphicItem, GraphicPin, GraphicDialog, GraphicLine, SacaleContainer, FooterLegendContainer, GraphicLegend, LegendItem, BudgetPin, Area } from "./styled";
import Utils from "../../../utils/utils";
import { FontGreyDarkRegular, FontGreyMediumRegular } from "../../../utils/typografyApp";
import GraphicExpirationController from "./controller";
import { Loading } from "../Loading";

const GraphicExpiration = ({ data, isLoading, months = false, rows = 4, legend, config, label, grid = false, isArea = false }) => {
    const scale = 1000;
    const cols = data?.length;

    const [valuesList, setValuesList] = useState([]);
    const [minAndMaxValue, setMinAndMaxValue] = useState();
    const [renderLineTo, setRenderLineTo] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);
    let scrollTimeout;

    const monthsNames = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    useEffect(() => {
        const valuesListAux = [];
        if (data?.length > 0) {
            data[0].values?.map((e, i) => {

                // 
                // Se a configuração de exibição for menor que a quantidade de linhas configuradas para serem exibidas 
                // do gráfico, então será ignorado outras linhas e focará apenas nas que foram configuradas
                // 

                if (i < config.length) {
                    valuesListAux.push([]);
                }
            });

            console.log(valuesListAux, valuesListAux.length, config.length)

            data?.map((item, indexItem) => {
                const list = item.values;
                list?.map((value, indexValue) => {
                   
                    // 
                    // Se a configuração de exibição for menor que a quantidade de linhas configuradas para serem exibidas 
                    // do gráfico, então será ignorado outras linhas e focará apenas nas que foram configuradas
                    // 
                    if (indexValue < config.length) {

                        valuesListAux[indexValue][indexItem] = value;
                    }
                });
            });

            setMinAndMaxValue(Utils.minAndMaxValueOfList(valuesListAux));

            setValuesList(valuesListAux);
        }
    }, [config.length, data]);

    useEffect(() => {
        const handleResize = () => {
            if (!isScrolling) {
                setRenderLineTo(true);
            }
        };

        const handleScroll = () => {
            setIsScrolling(true);
            setRenderLineTo(false);
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                setIsScrolling(false);
                setRenderLineTo(true);
            }, 600);
        };

        window.addEventListener("resize", handleResize);
        document.getElementById("dashboard")?.addEventListener("scroll", handleScroll);
        document.getElementById("reports")?.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.getElementById("dashboard")?.removeEventListener("scroll", handleScroll);
            document.getElementById("reports")?.removeEventListener("scroll", handleScroll);
        };
    }, [isScrolling]);

    return (
        <GraphicContainer>
            {!isLoading ?
                <>
                    <GraphicPanel legend={legend} cols={cols} rows={rows}>
                        <SacaleContainer>
                            <FontGreyMediumRegular>x{scale}</FontGreyMediumRegular>
                        </SacaleContainer>

                        {Array.from({ length: (cols + 1) * rows }).map((_, index) => (
                            <ItemBackGraphic grid={grid ? "true" : "false"} key={`item-${index}`}></ItemBackGraphic>
                        ))}

                        {legend &&
                            <GraphicLegend rows={rows}>
                                {
                                    legend?.map(legendItem => (
                                        <LegendItem color={legendItem.color}>
                                            <div></div>
                                            <FontGreyDarkRegular>{legendItem.label}</FontGreyDarkRegular>
                                        </LegendItem>
                                    ))
                                }
                            </GraphicLegend>
                        }
                    </GraphicPanel>

                    {valuesList?.map((values, indexValues) => (
                        <GraphicContent rows={rows} cols={cols}>
                            {values?.map((value, index) => (
                                <>
                                    <GraphicItem
                                        key={`graphic-expiration-item-${index}`}
                                        cols={cols}
                                        rows={rows}
                                        maxvalue={minAndMaxValue?.max}
                                        minvalue={minAndMaxValue?.min}
                                        value={parseFloat(value).toFixed(2)}
                                        nextvalue={values[index + 1]}
                                        color={config[indexValues].color}
                                    >
                                        {renderLineTo &&
                                            <LineTo
                                                className="line-to"
                                                key={`line-${config[indexValues].label}-${index}`}
                                                from={`pin-${config[indexValues].label}-${index}`}
                                                to={`pin-${config[indexValues].label}-${index + 1}`}
                                                borderWidth={config[indexValues].width}
                                                fromAnchor="middle"
                                                toAnchor="middle"
                                                delay={0.5}
                                                borderStyle={config[indexValues].lineStyle}
                                                borderColor={config[indexValues].color}
                                            />
                                        }
                                        {values.length !== index + 1 && isArea && <div className="area"></div>}
                                        {config[indexValues].dialog && <GraphicDialog position={config[indexValues].position || "top"} color={config[indexValues].color}>{`${parseFloat(Utils.formatNumberWithCommas(value / scale)).toFixed(2)}${config[indexValues].measure || ""}`}</GraphicDialog>}
                                        <GraphicPin className={`pin-${config[indexValues].label}-${index}`} color={config[indexValues].dialog ? config[indexValues].color : 'transparent'} />
                                    </GraphicItem>
                                </>
                            ))}

                        </GraphicContent>
                    ))}


                    <GraphicFooter legend={legend} cols={cols}>
                        {Array.from({ length: cols }).map((_, index) => (
                            <FooterLegendContainer key={`footer-legend-${index}`} cols={cols} rows={rows} legend={legend}>
                                <FontGreyDarkRegular key={`data-${index}`}>{months ? monthsNames[data[index].label] : data[index].label}</FontGreyDarkRegular>
                            </FooterLegendContainer>
                        ))}
                    </GraphicFooter>
                </> :
                <Loading size={24} />
            }
        </GraphicContainer>
    );
}

export default GraphicExpiration;
