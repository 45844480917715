import { FontGreyDarkFullRegular, FontGreyDarkRegular, FontGreyDarkSemiBold, FontPoppinsGreyDarkFullRegular, FontPoppinsGreyDarkSmallRegular } from "../../../../utils/typografyApp";
import { ArrowCard, MessageCard, NotificationMessageContainer, NotificationTimeContainer, NotificationsContainer, NotitificationTitle } from "./styled";
import pin_icon from "../../../../assets/icons/pin.svg";

const CardNotifications = ({showDashboard})=>{
    return(
        <>
            <ArrowCard/>
            <NotificationsContainer showDashboard={showDashboard ? "true" : "false"}>
                
                <FontGreyDarkRegular>Suas Notificações</FontGreyDarkRegular>
                
                <MessageCard read={false}>
                    <NotitificationTitle>
                        <img src={pin_icon} alt="Iocne de pin"/>
                        <FontGreyDarkSemiBold>Portainer I</FontGreyDarkSemiBold>
                    </NotitificationTitle>
                    <NotificationMessageContainer>
                        <FontGreyDarkFullRegular>O subprocesso de Descarregamento possui um ativo em alerta de atenção!</FontGreyDarkFullRegular>
                    </NotificationMessageContainer>
                    <NotificationTimeContainer>
                        <FontPoppinsGreyDarkSmallRegular>6 horas atrás</FontPoppinsGreyDarkSmallRegular>
                    </NotificationTimeContainer>
                </MessageCard>


                <MessageCard read={true}>
                    <NotitificationTitle>
                        <img src={pin_icon} alt="Iocne de pin"/>
                        <FontGreyDarkSemiBold>Portainer IV</FontGreyDarkSemiBold>
                    </NotitificationTitle>
                    <NotificationMessageContainer>
                        <FontGreyDarkFullRegular>O subprocesso de Descarregamento possui um ativo em alerta de atenção!</FontGreyDarkFullRegular>
                    </NotificationMessageContainer>
                    <NotificationTimeContainer>
                        <FontPoppinsGreyDarkSmallRegular>6 horas atrás</FontPoppinsGreyDarkSmallRegular>
                    </NotificationTimeContainer>
                </MessageCard>

                <MessageCard read={true}>
                    <NotitificationTitle>
                        <img src={pin_icon} alt="Iocne de pin"/>
                        <FontGreyDarkSemiBold>Portainer II</FontGreyDarkSemiBold>
                    </NotitificationTitle>
                    <NotificationMessageContainer>
                        <FontGreyDarkFullRegular>O subprocesso de Descarregamento possui um ativo em alerta de atenção!</FontGreyDarkFullRegular>
                    </NotificationMessageContainer>
                    <NotificationTimeContainer>
                        <FontPoppinsGreyDarkSmallRegular>6 horas atrás</FontPoppinsGreyDarkSmallRegular>
                    </NotificationTimeContainer>
                </MessageCard>

            </NotificationsContainer>
        </>
    )
}

export default CardNotifications;