import { useEffect, useState } from "react";
import { FontGreyDarkRegular, FontSmallGreyDarkBold } from "../../../utils/typografyApp";
import { GraphicContainer, GraphicItem, GraphicBar, GraphicMaxBar, Row, GraphicMaxBarContainer, GraphicLegend, LegendItem } from "./styled";
import Utils from "../../../utils/utils";

const GraphicBars = ({data, legend, config, scale  = 1000, spaceWidth = 1})=>{
    const [minAndMaxValue, setMinAndMaxValue] = useState();
    const [valuesList, setValuesList] = useState([]);

    useEffect(() => {
        const valuesListAux = [];
    
        data?.map((dataItem) => {
          valuesListAux.push(dataItem.values);
        });
        
        if(valuesListAux.length > 0){
            setMinAndMaxValue(Utils.minAndMaxValueOfList(valuesListAux));
            setValuesList(valuesListAux);
        }
        else {
            setMinAndMaxValue({min: 0, max: 0});
        }

    }, [data]);


    return(
        <>
            <GraphicContainer spaceWidth={spaceWidth}>
                {
                    valuesList.map((valuesListItem, listIndex) =>(
                        <GraphicItem>
                            <Row>
                                {
                                    valuesListItem.map((item, index)=>(
                                        <GraphicMaxBarContainer>
                                            <FontGreyDarkRegular>{parseFloat(item/scale).toFixed(2)}</FontGreyDarkRegular>
                                            <GraphicMaxBar className="bar-config">
                                                <GraphicBar color={config[index].color} className="bar-config" height={item} total={minAndMaxValue?.max}/>
                                            </GraphicMaxBar>
                                        </GraphicMaxBarContainer>
                                    ))
                                }
                               
                            </Row>
                            
                            <FontSmallGreyDarkBold>{data[listIndex]?.label}</FontSmallGreyDarkBold>
                        </GraphicItem>
                    ))
                }
        
            </GraphicContainer>

            {legend && 
                <GraphicLegend>
                    {
                        legend?.map(legendItem=>(
                            <LegendItem color={legendItem.color}>
                                <div></div>
                                <FontGreyDarkRegular>{legendItem.label}</FontGreyDarkRegular>
                            </LegendItem>
                        ))
                    }
                </GraphicLegend>
            }
        </>
    );
}

export default GraphicBars;