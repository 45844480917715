import { useMutation } from "react-query";
import { useAuthService } from "../../../services/authService";
import { loginSchema } from "../../../models/schemas/authSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { LocalClientToken } from "../../../utils/local-client-token";
import { LocalAdmToken } from "../../../utils/local-adm-token";

export function useLoginHook({isAdmin}){
    const authService = useAuthService();

    const {mutate, isLoading} = useMutation((payload) => isAdmin ? authService.loginAdm({payload, onSuccess: onSuccessAdmin}) : authService.login({payload, onSuccess}));
    const loginForm = useForm({resolver: zodResolver(loginSchema)});
    
    //functions
    function onSubmit(data){
        mutate(data);
    };

    function onSuccess(data){
        LocalClientToken.storeToken(data.access);
        window.location.href = '/';
    }

    function onSuccessAdmin(data){
        LocalAdmToken.storeToken(data.access);
        window.location.href = '/adm';
    }

    return {
        form: {
            loginForm,
            onSubmit,
            isLoading
        }
    };
}