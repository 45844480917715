import { useMutation, useQuery } from "react-query";
import { useEffect, useState } from "react";
import { assets3dItemQueries, useAssets3DItemService } from "../../../../../services/asset3DItemService";
import base64 from 'base64-encode-file';
import { queryClient } from "../../../../../utils/query-client";

export function useTabAssets3DItemHook() {
    const assets3DItemService = useAssets3DItemService();
    const [showNewAsset3DItemModal, setShowNewAsset3DItemModal] = useState(false);
    const [showSTLModal, setShowSTLModal] = useState(false);
    const [showSTLAssetModal, setShowSTLAssetModal] = useState(false);
    const [showNewSection3DItemModal, setShowNewSection3DItemModal] = useState(false);
    const [showBulkSection3DItemModal, setShowBulkSection3DItemModal] = useState(false);
    const [selectedAsset3DItem, setSelectedAsset3DItem] = useState();
    const [selectedSection3DItem, setSelectedSection3DItem] = useState();
    const [viewAsset3DItem, setViewAsset3DItem] = useState();
    const [showDeleteSection3DItemModal, setShowDeleteSection3DItemModal] = useState();
    const [showDeleteAsset3DItemModal, setShowDeleteAsset3DItemModal] = useState();
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(0);

    const [search, setSearch] = useState('');

    const { mutate, isLoading } = useMutation((payload) => assets3DItemService.editAssets3DItem({payload, onSuccess}));

    const {
        data: asset3DItemData,
        isLoading: isLoadingAsset3DItem,
        isRefetching: isRefetchingAsset3DItem,
        refetch: refetchAsset3DItem
        //TODO: trocar a paginacao
    } = useQuery(assets3dItemQueries.GET_ALL, () => assets3DItemService.getAllAssets3DItem({ page: 1, name: search }));

    function handleSelectAsset3DItem(asset3DItem) {
        setSelectedAsset3DItem(asset3DItem);
        setShowNewAsset3DItemModal(true);
    }

    function handleViewAsset3DItem(asset3DItem) {
        if (viewAsset3DItem === asset3DItem) {
            setViewAsset3DItem(null);
        } else {
            setViewAsset3DItem(asset3DItem);
        }
    }

    function handleSelectAsset3DItem(e, asset3DItem) {
        e.stopPropagation();
        setSelectedAsset3DItem(asset3DItem);
        setShowNewAsset3DItemModal(true);
    }

    function handleSelectSTL(section) {
        setSelectedSection3DItem(section);
        if(section.location){
            setShowSTLModal(true);
        }
    }

    function handleSelectAssetSTL(e, asset) {
        e.stopPropagation();
        setSelectedAsset3DItem(asset);
        setShowSTLAssetModal(true);
    }

    function handleSelectSection3DItem(section3DItem) {
        setSelectedSection3DItem(section3DItem);
        setShowNewSection3DItemModal(true);
    }

    function handleCloseNewAsset3DItemModal() {
        setSelectedAsset3DItem(null);
        setShowNewAsset3DItemModal(false);
        setShowBulkSection3DItemModal(false);
        setShowDeleteAsset3DItemModal(false);
        setShowDeleteSection3DItemModal(false);
    }

    function handleCloseNewSection3DItemModal() {
        setSelectedSection3DItem(null);
        setShowNewSection3DItemModal(false);
        setShowDeleteSection3DItemModal(false);
        setShowSTLModal(false);
        setShowSTLAssetModal(false);
    }

    function onSuccess() {
        queryClient.refetchQueries(assets3dItemQueries.GET_ALL);
    }

    async function handleChangeSTL(e) {
        const file = e.target.files[0];
        if (file) {
            const base64STL = await base64(file);
            const payload = {...viewAsset3DItem};
            payload.sections.map(section=>{
                if(section.id === selectedSection3DItem.id){
                    section.location = base64STL.toString().replace(':application/', ':@file/');
                }
            });

            mutate(payload);
        }
    }

    async function handleDeleteSection3DItem(section){
        setSelectedSection3DItem(section);
        setShowDeleteSection3DItemModal(true);
    }

    async function handleDeleteAsset3DItem(e, asset){
        e.stopPropagation();
        setSelectedAsset3DItem(asset);
        setShowDeleteAsset3DItemModal(true);
    }


    useEffect(() => {
        refetchAsset3DItem();
    }, [search]);

    return {
        asset3DItem: {
            asset3DItemData,
            isLoading: isLoadingAsset3DItem || isRefetchingAsset3DItem,
            refetchAsset3DItem,
            selectedAsset3DItem,
            handleSelectAsset3DItem,
        },
        section3DItem: {
            setSelectedSection3DItem,
            selectedSection3DItem,
            handleChangeSTL,
            isLoading,
            handleSelectSTL,
            handleSelectAssetSTL,
            showSTLModal,
            setShowSTLModal,
            showSTLAssetModal,
            setShowSTLAssetModal
        },
        general: {
            showNewAsset3DItemModal,
            setShowNewAsset3DItemModal,
            showNewSection3DItemModal,
            setShowNewSection3DItemModal,
            colSpan: 3,
            search,
            setSearch,
            viewAsset3DItem,
            handleViewAsset3DItem,
            handleCloseNewAsset3DItemModal,
            handleCloseNewSection3DItemModal,
            handleSelectSection3DItem,
            showDeleteSection3DItemModal,
            setShowDeleteSection3DItemModal,
            handleDeleteSection3DItem,
            showDeleteAsset3DItemModal,
            setShowDeleteAsset3DItemModal,
            handleDeleteAsset3DItem,
            showBulkSection3DItemModal,
            setShowBulkSection3DItemModal,
            downloadProgress,
            setDownloadProgress,
            isDownloading,
            setIsDownloading

        }
    }
}