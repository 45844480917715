import { useEffect, useState } from "react";
import { metricsQueries, useMetricsService } from "../../../services/metricsService";
import { useQuery } from "react-query";

export function useMenuHook({asset, section}){
    const [showDashboardCard, setShowDashboardCard] = useState(true);
    const metricsService = useMetricsService();

    const {
        data : metricsData,
        isLoading : isLoadingMetrics,
        isRefetching : isRefetchingMetrics,
        refetch : refetchMetrics
    } = useQuery(metricsQueries.GET_BY_CLIENT, ()=>metricsService.getAllByClient({clientId: !asset && !section ? 1 : undefined}));

    const {
        data : metricsAssetData,
        isLoading : isLoadingMetricsAsset,
        isRefetching : isRefetchingMetricsAsset,
        refetch : refetchMetricsAsset
    } = useQuery(section ? metricsQueries.GET_BY_SECTION_ID : metricsQueries.GET_BY_ID, ()=>metricsService.getMetricsById({ id: section?.fkAssetId || asset?.id, fkSectionId: section?.id}));

    const dashboardData = !asset && !section ? metricsData : metricsAssetData;

    useEffect(()=>{
        refetchMetricsAsset();
    }, [asset, section]);

    return{
        chats: {
            dashboardData,
            isLoading: 
                ((!asset && !section) && (isLoadingMetrics || isRefetchingMetrics)) || 
                ((asset || section) && (isLoadingMetricsAsset || isRefetchingMetricsAsset)),
            refetchMetrics,
            refetchMetricsAsset
        },
        general: {
            showDashboardCard,
            setShowDashboardCard
        }
    }

    

}