import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
    

    img{
        width: 100px;
        height: 100px;
        background-color: #E6E4E0;
        border-radius: 8px;
    }
`;