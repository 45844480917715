import styled from "styled-components";

export const AssetInfoWindowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    align-items: start;

    .img-asset{
        height: 8rem;
        width: 13rem;
        object-fit: cover;
    }

`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;