import { ModalMain } from "../styled";
// import upload_input_icon from "../../../../assets/icons/upload-input.svg";
import { FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { NewUserForm } from "./styled";
import { useNewUserModalHook } from "./hook";
import { FormProvider } from "react-hook-form";
import Input from "../../Input";

const NewUserModal = ({ onClose, selectedUser }) => {

    const hook = useNewUserModalHook({ onClose, selectedUser });

    return (
        <ModalMain>
            <FormProvider {...hook.form.userForm}>

                <NewUserForm>
                    <form onSubmit={hook.form.userForm.handleSubmit(hook.form.onSubmit)}>
                        <div className={"menu-content"}>

                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Cadastro de usuário</FontGreyDarkMediumTitle>
                                </div>

                                {/* 
                                    <label className="profilePic" htmlFor="profilePic">
                                        <img src={profilePic || upload_input_icon} alt="Icone de Upload"/>
                                        <input 
                                            className="hidden-content" id="profilePic" type="file" 
                                            onChange={async (e)=> setProfilePic(await controller.onChangeFile(e))}
                                        />
                                    </label> 
                                */}
                                <div className="form-input">
                                    <Input type="text" placeholder="Nome do usuário" name="name" />
                                    <Input type="cpf" placeholder="CPF do usuário" name="cpf" />
                                    <Input type="email" placeholder="Email do usuário" name="email" />
                                    <Input type="text" placeholder={`Senha ${selectedUser && '(Opcional)'}`} name="password" />
                                </div>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} type={'submit'}> {hook.form.isLoading ? 'Salvando...' : selectedUser ? 'Editar' : 'Criar'} </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </NewUserForm>
            </FormProvider>
        </ModalMain>
    );
}

export default NewUserModal;