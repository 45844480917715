import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const degradationDegreeQueries = {
    EDIT: "DEGRADATIONDEGREE_EDIT",
    CREATE: "DEGRADATIONDEGREE_CREATE",
    DELETE: "DEGRADATIONDEGREE_DELETE",
    GET_ALL: "DEGRADATIONDEGREE_GET_ALL",
    GET_ALL_OPTIONS: "DEGRADATIONDEGREE_GET_ALL_OPTIONS",
    GET_BY_ID: "DEGRADATIONDEGREE_GET_BY_ID",
}


export function useDegradationDegreeService() {

    const handleRequest = useHandleRequest();

    async function createDegradationDegree({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/degradation-degree/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editDegradationDegree({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/degradation-degree/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteDegradationDegree({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/degradation-degree/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllDegradationDegree({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/degradation-degree/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAllDegradationDegreeOptions({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/degradation-degree/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data.rows.map(data=>({key: data.id, value: data.name}));
        }
    }

    async function getDegradationDegreeById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/degradation-degree/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        createDegradationDegree,
        editDegradationDegree,
        deleteDegradationDegree,
        getAllDegradationDegree,
        getDegradationDegreeById,
        getAllDegradationDegreeOptions
    }
}
