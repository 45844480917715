import { styled } from 'styled-components';

export const SelectContainer = styled.div`
    position: relative;
    border: 0.0625rem solid ${props=>props.error ? 'var(--red-color)' : 'var(--grey-light-2-color)'};
    background-color: var(--white-color);
    border-radius: 0.5rem;
    padding:  0.75rem 1rem;
    width: 100%;
    gap: 0.625rem;
    cursor: pointer;
    font-family: "Ubuntu";
    color: var(--grey-medium-color);
`;

export const CustomSelect = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
        white-space: nowrap;
    }

    input{
        width:100% !important;
        border: none !important;
        outline: none !important;    
        pointer-events: none !important;
        padding: 0 !important;
    }
`;

export const OptionsContainer = styled.div`
    position: absolute;
    top: 3rem;
    width: 100%;
    left: -0.1rem;
    background-color: var(--white-color);
    border: 1px solid var(--grey-light-2-color);
    max-height: 10rem;
    min-height: 2rem;
    z-index: 3000;
    overflow-y: auto;
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);

    display: flex;
    flex-direction: column;
`;

export const OptionItem = styled.span`
    padding: 1rem;

    &:hover{
        background-color: var(--primary-light-color);
    }
    
`;