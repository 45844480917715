import { responseStatus, useHandleRequest } from "../service/api-services";
import {getDefaultQuery } from "../utils/query-client";

export const usersQueries = {
    EDIT: "USERS_EDIT",
    CREATE: "USERS_CREATE",
    DELETE: "USERS_DELETE",
    GET_ALL: "USERS_GET_ALL",
    GET_BY_ID: "USERS_GET_BY_ID",
}


export function useUsersService() {

    const handleRequest = useHandleRequest();

    async function createUsers({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/users`, 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editUsers({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/users/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteUsers({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/users/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllUsers({ search, name, page }){
        const filters = [{search}, {name}];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/users/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getUsersById({ id }){
        const { status, data } = await handleRequest.handleRequest(`/users/${id}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    return {
        createUsers,
        editUsers,
        deleteUsers,
        getAllUsers,
        getUsersById,
    }
}
