import styled from "styled-components";

export const PinAnomaliesContainer = styled.div`
    position: absolute;
    top: ${props=>props.position.y}%;
    left: ${props=>props.position.x}%;
    cursor: pointer;
    z-index: 3000;

    .circle-anomalies{
        width: 0.35rem;
        height: 0.35rem;
        background-color: ${props=>props.color};
        border-radius: 1rem;
    }

    .border-anomalies{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.875rem;
        height: 0.875rem;
        background-color: var(--white-color);
        border-radius: 1rem;
        margin-top: 0.15rem;
    }

    img{
        width: 0.75rem !important;
        height: 0.75rem !important;
        padding: 0.15rem;
        background-color: var(--red-color);
        border-radius: 1rem;
        margin-top: 0.25rem;
    }
`;  