import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
`;

export const NewSection3DForm = styled.div`

    form{
        display: flex;
        flex-direction: row;
        gap: 22px;

        .tag{
            width: 180px;
        }

        .form-input{
            display: flex;
            gap: 12px;
        }
    }
`;