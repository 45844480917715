import { FontGreyDarkRegular, FontGreyDarkSemiMedium } from "../../../utils/typografyApp";
import { GraphicContainer, GraphicItem, GraphicBar, GraphicMaxBar } from "./styled";
import { Loading } from '../Loading';

const GraphicDegradation = ({data, isLoading})=>{
    
    return(
        <GraphicContainer>
            {
                !isLoading ?
                data?.ris.map((ri, index)=>(
                    <GraphicItem key={`graphic-item-${index}`}>
                        <FontGreyDarkRegular>{ri.totalArea ? parseFloat(ri.totalArea/1000).toFixed(2) : 0}mi</FontGreyDarkRegular>
                        <GraphicMaxBar className="bar-config">
                            <GraphicBar className={`bar-config ${ri.label?.toLowerCase()}`} height={ri.totalArea} total={data?.total}/>
                        </GraphicMaxBar>
                        <FontGreyDarkSemiMedium>{ri.label}</FontGreyDarkSemiMedium>
                    </GraphicItem>
                )) :
                <Loading size={24}/>
            }
            
            
        </GraphicContainer>
    );
}

export default GraphicDegradation;