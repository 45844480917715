import { z } from "zod";

import base64 from "base64-encode-file";

export const generalMessageError = "Campo obrigatório.";

export const fkSchema = z.union([
    z.string().min(1, {message: generalMessageError}),
    z.number().min(1, {message: generalMessageError}),
]);

export const mediasSchema = z.instanceof(FileList).transform(async (files) =>{
    const medias = [];
    for(const file of Array.from(files)){
        const media = await base64(file);
        medias.push({path : media});
    }
    return medias;
});