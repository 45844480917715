import styled from 'styled-components';

export const MenuContainer = styled.div`
    position: absolute;
    left: -55px;
    display: flex;
    height: 12rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5rem;
`;