import { FontGreyDarkRegular, FontPrimaryMegaTitle } from "../../../utils/typografyApp";
import { Graphic, GraphicContainer, GraphicContent, Text } from "./styled";
import grafic_image from "../../../assets/icons/simbolic_grafic.svg";

const GraphicQuantitative = ({title, subtitle})=>{
    return(
        <GraphicContainer>
            <GraphicContent>
                <Text color={title.color}>
                    <FontPrimaryMegaTitle>{title.value}</FontPrimaryMegaTitle>
                    <FontGreyDarkRegular>{title.description}</FontGreyDarkRegular>
                </Text>
                <Text color={subtitle.color}>
                    <FontPrimaryMegaTitle>{parseFloat(subtitle.value).toFixed(0)}</FontPrimaryMegaTitle>
                    <FontGreyDarkRegular>{subtitle.description}</FontGreyDarkRegular>
                </Text>
            </GraphicContent>
            <Graphic>
                <img src={grafic_image} alt="Grafico simbólico"/>
            </Graphic>
        </GraphicContainer>
    );
}

export default GraphicQuantitative;