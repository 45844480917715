import AdmButton from "../../../../components/AdmButton"
import { Tab3DContainer, Tab3DFilters, Tab3DSectionItem, Tab3DSections } from "./styled"
import add_icon from '../../../../../assets/icons/add.svg';
import arrow_down_icon from '../../../../../assets/icons/arrow_down.svg';
import search_icon from '../../../../../assets/icons/search.svg';
import upload_icon from '../../../../../assets/icons/upload.svg';
import download_icon from '../../../../../assets/icons/download.svg';
import upload_grey_icon from '../../../../../assets/icons/upload_grey.svg';
import tag_grey_icon from '../../../../../assets/icons/tag_grey.svg';
import check_icon from '../../../../../assets/icons/check.svg';
import edit_icon from '../../../../../assets/icons/edit.svg';
import remove_icon from '../../../../../assets/icons/remove.svg';
import { FontGreyDarkBold, FontGreyDarkMedium } from "../../../../../utils/typografyApp";
import Input from "../../../../components/Input";
import NewAsset3DModal from "../../../../components/Modals/NewAsset3DModal";
import NewSection3DModal from "../../../../components/Modals/NewSection3DModal";
import { useTabAssets3DItemHook } from "./hook";
import { Loading } from "../../../../components/Loading";
import ViewSTLModal from "../../../../components/Modals/ViewSTLModal";
import DeleteSection3DModal from "../../../../components/Modals/DeleteSection3DModal";
import BulkSection3DModal from "../../../../components/Modals/BulkSection3DModal";
import DeleteAsset3DModal from "../../../../components/Modals/DeleteAsset3DModal";
import ViewSTLAssetModal from "../../../../components/Modals/ViewSTLAssetModal";
import { downloadAndExtractZip } from "../../../../../service/indexedDB";
import { checkCache } from "../../../../../utils/functions";


export const Tab3D = () => {
    const hook = useTabAssets3DItemHook();

    return (
        <Tab3DContainer>
            <FontGreyDarkBold>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nome do ativo</th>
                                <th>Tag do 3D</th>
                                <th></th>
                            </tr>

                        </thead>

                        <tbody>
                            <tr>
                                <td colSpan={hook.general.colSpan}>
                                    <Tab3DFilters>
                                        <Input className={'search'} icon={search_icon} placeholder={'Buscar modelo'} setSearch={hook.general.setSearch}/>
                                        
                                            <AdmButton onClick={() => { hook.general.setShowNewAsset3DItemModal(true) }}>
                                                <img src={add_icon} alt="Iocne de adicionar" />
                                                Modelo de Ativo
                                            </AdmButton>

                                            <AdmButton onClick={() => { hook.general.setShowBulkSection3DItemModal(true) }}>
                                                <img src={upload_grey_icon} alt="Icone de upload" />
                                                Modelo de Ativo em Massa
                                            </AdmButton>
                                        
                                    </Tab3DFilters>
                                </td>
                            </tr>
                            {hook.asset3DItem.asset3DItemData?.count === 0 || hook.asset3DItem.isLoading ?
                                <tr>
                                    <td colSpan={hook.general.colSpan} className="empty-table">
                                        {hook.asset3DItem.isLoading ?
                                            <Loading /> :
                                            <div>Nenhum modelo 3D cadastrado</div>
                                        }
                                    </td>
                                </tr> :
                                <>
                                    {hook.asset3DItem.asset3DItemData?.rows?.map(asset3DItem => (
                                        <>
                                            <tr onClick={() => hook.general.handleViewAsset3DItem(asset3DItem)} className="click">

                                                <td><img className={`arrow ${hook.general.viewAsset3DItem === asset3DItem && 'rotate'}`} src={arrow_down_icon} alt="Icone de seta" /></td>
                                                <td>{asset3DItem.name}</td>
                                                <td>
                                                    <div className="tag">
                                                        <img src={tag_grey_icon} alt="Icone de seta" />
                                                        {asset3DItem.tag}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="actions">
                                                        {asset3DItem.id === hook.general.isDownloading ? (
                                                            <div className="progress">
                                                                Baixando: {hook.general.downloadProgress}%
                                                            </div>
                                                        ) : checkCache(asset3DItem) ? (
                                                            <img src={check_icon} alt="Icone de check" onClick={(e) => hook.section3DItem.handleSelectAssetSTL(e, asset3DItem)} />
                                                        ) : (
                                                            <img src={download_icon} alt="Icone de Download" onClick={(e) => downloadAndExtractZip(e, asset3DItem, hook.general.setDownloadProgress, hook.general.setIsDownloading, hook.asset3DItem.refetchAsset3DItem)} />
                                                        )}
                                                        <img src={edit_icon} alt="Icone de editar" onClick={(e) => { hook.asset3DItem.handleSelectAsset3DItem(e, asset3DItem) }} />
                                                        <img src={remove_icon} alt="Icone de Remover" onClick={(e) => hook.general.handleDeleteAsset3DItem(e, asset3DItem)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            {hook.general.viewAsset3DItem === asset3DItem &&
                                                <tr>
                                                    <td colSpan={4}>
                                                        <Tab3DSections>
                                                            <FontGreyDarkMedium>Seções</FontGreyDarkMedium>
                                                            <div className="sections">
                                                                {asset3DItem.sections.length === 0 || hook.section3DItem.isLoading ?
                                                                    <div className="empty-table">
                                                                        {hook.section3DItem.isLoading ?
                                                                            <Loading /> :
                                                                            <div>Nenhum 3D de seção cadastrado para {asset3DItem.name}</div>
                                                                        }
                                                                    </div> :
                                                                    <>
                                                                        {asset3DItem.sections?.map(section => (
                                                                            <Tab3DSectionItem>
                                                                                <span className="name"> {section.name} - {section.tag} </span>
                                                                                <div onClick={() => hook.section3DItem.handleSelectSTL(section)}>
                                                                                    {
                                                                                        section.location ?
                                                                                            <img src={check_icon} alt="Icone de check" /> :
                                                                                            <label htmlFor="stl-input" className="stl">
                                                                                                <img src={upload_icon} alt="Icone de upload" />
                                                                                                Importar STL
                                                                                            </label>
                                                                                    }
                                                                                </div>

                                                                                <input hidden id="stl-input" type="file" accept=".stl" onChange={hook.section3DItem.handleChangeSTL} />
                                                                                <img src={edit_icon} alt="Icone de editar" onClick={() => { hook.general.handleSelectSection3DItem(section) }} />
                                                                                <img src={remove_icon} alt="Icone de Remover" onClick={() => hook.general.handleDeleteSection3DItem(section)} />
                                                                            </Tab3DSectionItem>
                                                                        ))}
                                                                    </>
                                                                }


                                                            </div>
                                                            <AdmButton onClick={() => { hook.general.setShowNewSection3DItemModal(true) }}>
                                                                <img src={add_icon} alt="Icone de adicionar" />
                                                                Modelo de Seção
                                                            </AdmButton>


                                                        </Tab3DSections>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    ))}
                                </>
                            }

                        </tbody>
                    </table>
                </div>
            </FontGreyDarkBold>

            {hook.general.showNewAsset3DItemModal &&
                <NewAsset3DModal
                    selectedAsset3DItem={hook.asset3DItem.selectedAsset3DItem}
                    onClose={hook.general.handleCloseNewAsset3DItemModal}
                />
            }

            {hook.general.showBulkSection3DItemModal &&
                <BulkSection3DModal
                    selectedAsset3DItem={hook.asset3DItem.selectedAsset3DItem}
                    onClose={hook.general.handleCloseNewAsset3DItemModal}
                />
            }


            {hook.general.showNewSection3DItemModal &&
                <NewSection3DModal
                    asset3DItem={hook.general.viewAsset3DItem}
                    selectedSection3DItem={hook.section3DItem.selectedSection3DItem}
                    onClose={hook.general.handleCloseNewSection3DItemModal}
                />
            }


            {hook.section3DItem.showSTLModal &&
                <ViewSTLModal
                    section={hook.section3DItem.selectedSection3DItem}
                    onClose={hook.general.handleCloseNewSection3DItemModal}
                />
            }

            {hook.section3DItem.showSTLAssetModal &&
                <ViewSTLAssetModal
                    asset={hook.asset3DItem.selectedAsset3DItem}
                    onClose={hook.general.handleCloseNewSection3DItemModal}
                />
            }

            {hook.general.showDeleteSection3DItemModal &&
                <DeleteSection3DModal
                    selectedSection3DItem={hook.section3DItem.selectedSection3DItem}
                    selectedAsset3DItem={hook.general.viewAsset3DItem}
                    onClose={hook.general.handleCloseNewSection3DItemModal}
                />
            }

            {hook.general.showDeleteAsset3DItemModal &&
                <DeleteAsset3DModal
                    selectedAsset3DItem={hook.asset3DItem.selectedAsset3DItem}
                    onClose={hook.general.handleCloseNewAsset3DItemModal}
                />
            }
        </Tab3DContainer>
    )
}