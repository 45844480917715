import { styled } from 'styled-components';

export const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: var(--grey-light-color) !important;

    
`;

export const SectionContainer = styled.div`
    position: relative;
    height: 100vh;
    overflow: scroll;

    img{
        position: absolute;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
    
`;

export const SVGContainer = styled.div`
    width: 74vw;
    height: 85vh;
    margin: 3%;
    position: relative;
    
    .newLoud{
        z-index: 3000;
    }

    ._3d{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2999;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .progress-bar{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 14px;
        height: 100vh;
        justify-content: center;
        div{
            height: 80px;
        }
    }
`;


export const PinStatus = styled.div`
    position: absolute;
    top: ${props=>props.position.y-50}px;
    left: ${props=>props.position.x+50}px;
    cursor: pointer;
    z-index: 3000;

    .circle-status{
        width: 0.35rem;
        height: 0.35rem;
        background-color: ${props=>props.color};
        border-radius: 1rem;
        border: 0.15rem solid var(--grey-dark-color);
    }

    .border-status{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        background-color: var(--grey-medium-color);
        border-radius: 1rem;
        margin-top: 0.15rem;
    }
`;  

export const ButtonContainer = styled.div`
    position: absolute;
    bottom: 2rem;
    left: 2rem;
`;