export const StatusColor = {
    "EXPIRED" : "#FF0000",
    "ACTIVE" : "#48DF45",
    "UPCOMING" : "#FFAB01"
} 

export const RIsColor = {
    "Ri1" : "#FF7A00",
    "Ri2" : "#FF4D00",
    "Ri3" : "#FF0000",
    "Ri4" : "#BF0000",
    "Ri5" : "#770101"
} 

export function getSectionColor(status){
    if(status?.currentExpiredArea > 0)
        return "#ff0000";
    if(status?.currentUpComingArea > 0)
        return "#ffab01"
    if(status?.currentActiveArea > 0)
        return "#48df45"
    return "#cccccc"
}