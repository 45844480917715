import styled from "styled-components";

export const TabAssetContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 94px;

    .arrow{
        width: 16px;
        cursor: pointer;
    }

    .tag{
        display: flex;
        gap:8px;

    }

    table{
        width: 1080px;
        border-collapse: collapse;
        th{
            border-bottom: 1px solid #A8A8A8;
            height: 40px;
            text-align: start;
        }
        tbody{
            font-weight: 500;
        }

        .check{
            text-align: start;
        }

        td{
            height: 32px;
            margin-bottom: 16px;
        }

        .actions{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;

            img{
                width: 24px;
                height: 24px;
            }
        }

    }
`;

export const TabAssetFilters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    gap: 24px;

    .search{
        width: 190px;
        height: 24.222px;
        background-color: #E6E4E0;
        padding: 4px 8px;
    }
`;

export const TabAssetSections = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 8px 0;

    .sections{
        display: flex;
        width: 100%;
        gap: 16px;
        
    flex-wrap: wrap;
    }
`;

export const TabAssetSectionItem = styled.div`
    display: flex;
    gap: 4px;
    width: 100%;
    .name{
        display: flex;
        width: 100%;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #F2F2F2;

        color: var(--Base-Grey-dark, #292929);
    }

    .stl{
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        color: var(--primary-color);
    }

    img{
        cursor: pointer;
        width: 24px;
    }
`;