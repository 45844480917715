import { styled } from 'styled-components';

export const GraphicContainer = styled.div`
    width: calc(100% - 0.5rem);
    display: flex;
    align-items: center;
    padding: 0.25rem !important;
    gap: ${props=>props.spaceWidth}%;
    border-radius: 0.25rem;
    border: 0.125rem solid var(--grey-light-2-color);
    height: 5rem;
    overflow-x: scroll;

`;

export const Row = styled.div`
    display: flex;
    width: 4rem;
    gap: 0.25rem;
    align-items: center;
    justify-content: space-around;

`;

export const GraphicItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 0.5rem;
    height: 100%;
   
`

export const GraphicMaxBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
`;

export const GraphicMaxBar = styled.div`
    position: relative;
    width: 100%;
    background-color: grey;
    height: 3rem;
    background-color: var(--white-color);
    border: 0.125rem solid var(--grey-light-2-color); 
    border-radius: 0.25rem;

    .blue{
        background-color: var(--primary-color);
    }

    .yellow{
        background-color: var(--yellow-color);
    }

    .red{
        background-color: var(--ri2-color);
    }
`;
 
export const GraphicBar = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: ${props=>props.color};
    border-radius: 0.25rem;
    height: ${(props) => {
        const maxPx = 48;
        const calculatedHeightPx = Math.round((maxPx * props.height) / props.total);

        return `${calculatedHeightPx}px`;
    }};
`;

 
export const GraphicLegend = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const LegendItem = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    div{
        width: 0.5rem !important;
        height: 0.5rem !important;
        background-color: ${props=>props.color};
        border-radius: 0.125rem;
    }
`;
