import { FontGreyDarkRegular, FontPrimaryMegaTitle } from "../../../utils/typografyApp";
import { Graphic, GraphicContainer, GraphicContent, Text } from "./styled";
import grafic_image from "../../../assets/icons/simbolic_grafic.svg";

const GraphicQuantitativeSmall = ({title, subtitle, icon})=>{
    return(
        <GraphicContainer>
            <GraphicContent>
                <Text direction={"row"} color={title.color}>
                    <img src={icon} alt={"Icone"}/>
                    <FontGreyDarkRegular>{title.description}</FontGreyDarkRegular>
                </Text>
                <Graphic>
                    <img src={grafic_image} alt="Grafico simbólico"/>
                </Graphic>
            </GraphicContent>
            <Text direction={"column"} color={subtitle.color}>
                <FontPrimaryMegaTitle>{subtitle.value}</FontPrimaryMegaTitle>
                <FontGreyDarkRegular>{subtitle.description}</FontGreyDarkRegular>
            </Text>
        </GraphicContainer>
    );
}

export default GraphicQuantitativeSmall;